import styled, { css } from 'styled-components'
import { Link, LinkProps } from 'react-router-dom'

export const Container = styled.div`
  background: #fcfcfc;
  display: flex;
  width: 100%;
  min-height: 100vh;
`
export const Header = styled.div`
  background: #fff;
  padding: 20px 40px;

  h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 100%;

    display: flex;
    align-items: flex-end;
    letter-spacing: -0.02em;
    text-transform: uppercase;

    color: #000000;
  }
`

export const Content = styled.section`
  background-color: #ededed;
  width: calc(100% - 440px);
  min-height: 100vh;
`

export const AsideContainer = styled.section`
  background-color: #fcfcfc;
  border: 1px solid #dbdbdb;
  width: 440px;

  display: flex;
 // align-items: center;

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #707070;

    &
  }
`

interface ButtonProps extends LinkProps {
  edit?: boolean
}

export const Button = styled(Link) <ButtonProps>`
  background: #8ffe81;
  ${({ edit }) =>
    edit &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    `}
  text-transform: uppercase;

  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Rubik';

  text-decoration: none;
  color: #000;

  //min-width: 171px;
  padding: 8px 24px 8px 24px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

export const TableCpntainer = styled.div`
  padding: 20px 40px;
`

export const AsideContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 120px;
  overflow-y: scroll;

  > div {
    > svg {
      margin: 25px 27px;
    }
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: left;

    color: #171717;

    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 100%;

    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: #171717;
    margin-top: 24px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 8px;
  }

  > span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #171717;
    margin-left: 20px;
    margin-right: 20px;

    &:first-of-type {
      margin-left: 20px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #000000;

      background-color: #8ffe81;
      padding: 4px 16px;
      border-radius: 16px;
    }
  }
`

export const EditorialContainer = styled.div`
  border-top: 1px solid #dbdbdb;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
`

export const OutcomesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 20px;

  h4 {
    width: 128px;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: #171717;
    text-transform: initial;
  }

  ul {
    width: 264px;
    li {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;

      color: #171717;
      margin-left: 8px;
    }
  }
`
export const EditoriasContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 20px;

  h4 {
    width: 128px;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: #171717;
    text-transform: initial;
  }

  ul {
    width: 264px;
    list-style: none;

    display: flex;
    gap: 8px;
    li {
      list-style: none;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;

      color: #171717;
      margin-left: 8px;

      background-color: #dcf8d8;
      padding: 4px 16px;
      border-radius: 16px;

      margin: 0;
    }
  }
`

export const TopicsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 20px;

  h4 {
    width: 128px;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: #171717;
    text-transform: initial;
  }

  ul {
    width: 264px;
    list-style: none;

    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    li {
      list-style: none;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;

      color: #171717;
      margin-left: 8px;

      background-color: #dcf8d8;
      padding: 4px 16px;
      border-radius: 16px;
      margin: 0;
    }
  }
`

export const VisibilityContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 20px;

  h4 {
    width: 128px;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: #171717;
    text-transform: initial;
  }

  ul {
    width: 264px;
    list-style: none;

    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    li {
      list-style: none;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;

      color: #171717;
      margin-left: 8px;
      margin: 0;
    }
  }
`

export const FooterAside = styled.div`
  border-top: 1px solid #dbdbdb;
  background-color: #fcfcfc;

  position: fixed;
  bottom: 0;
  height: 80px;
  width: 440px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;

  button {
    padding: 10px 24px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;

    height: 48px;
    margin-bottom: 4px;
    text-transform: uppercase;

    background-color: #8ffe81;

    color: #000;

    display: flex;
    align-items: center;
    gap: 10px;

    &:first-child {
      background-color: #000;
      color: #ffffff;
    }

    > svg {
      margin: 0;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: #00000060
    }
  }
`

export const NextScheduledMeetings = styled.div`
  border-top: 1px solid #dbdbdb;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 20px;
  box-sizing: border-box;

  > h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 100%;

    letter-spacing: -0.01em;
    text-transform: uppercase;

    color: #171717;
    margin: 0;
    margin-bottom: 20px;
  }
`

export const BoxContainer = styled.section`
  width: 100%;
`

export const Box = styled.div`
  height: 90px;
  background: #ededed;

  padding: 20px;

  display: flex;

  h4 {
    margin: 0;
  }
`

export const DateContainer = styled.div`
  padding-right: 30px;
  border-right: 1px solid #dbdbdb;

  h4 {
    font-family: 'Inter';
    color: #171717;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-transform: lowercase;

    &:first-of-type {
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 120%;
    }
  }
`

export const InfoContainer = styled.div`
  padding-left: 20px;

  display: flex;
`
interface NavbarProps {
  showEdit: boolean
}

export const Navbar = styled.nav<NavbarProps>`
  display: flex;
  background: #f7f7f7;
  padding: 0px 40px 0px 40px;
  height: 38px;

  ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    width: 100%;

    section {
      display: flex;

      :nth-of-type(2) {
        gap: 8px;
        align-items: center;
      }
    }
  }
`
export const ListItem = styled.li<ListItemActiveProps>`
  text-decoration: none;
  padding-bottom: ${({ active }) => (active ? '2px' : '4px')};
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${({ active }) => (active ? 900 : 400)};
  font-size: 14px;
  line-height: 16px;

  text-transform: uppercase;

  ${({ datasheetActive }) =>
    datasheetActive &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    `}

  ${({ contentActive }) =>
    contentActive &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    `}
  /* cursor: ${(props) => (props.showEdit ? 'not-allowed;' : 'pointer')}; */

  color: #000000;

  border-bottom: ${({ active }) => (active ? '2px #171717 solid' : 'none')};
  cursor: pointer;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  &:first-child {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 20px;
  }
`

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  > p {
    width: 360px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #707070;
  }
`

export const AsideEdit = styled.div`
  width: 100%;

  > div {
    width: calc(100%-40px);
    margin-top: 0;

    margin: 0 20px;
  }
`

export const ToggleSwitchContainer = styled.div`
  display: flex;
  margin-bottom: 20px;

  > h3 {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 0.75rem;
    letter-spacing: 0.01rem;
    text-transform: uppercase;
    color: #000;
    width: 128px;
  }

  > div {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    > label {
      margin-top: 0;
    }

    > span {
      font-size: 0.875rem;
      line-height: 1rem;
      font-weight: 400;
    }
  }
`

export const Label = styled.label`
  font-family: 'Rubik';
  color: '#000';
  display: block;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 0.75rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  letter-spacing: 0.01;
  margin-top: 16px;
  margin-right: 16px;
`

export const SelectsContainer = styled.section`
  margin: 0 20px;
  > div {
    display: flex;
    > &:first-child {
      background-color: green;
    }
  }
`

export const DeleteContainer = styled.div`
  border-top: 1px solid #dbdbdb;
  padding-top: 20px;
  display: flex;
  gap: 10px;
  cursor: pointer;

  > span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: #171717;
    width: 128px;
  }
`

export const SelectContainer = styled.div`
  display: flex;
  margin-bottom: 20px;

  > div {
    display: flex;

    > div:first-child {
      width: 128px;
    }
  }
`

export const MultiSelectContainer = styled.div`
  display: flex;
  margin-bottom: 20px;

  > div:first-child {
    width: 128px;
  }

  > div:last-child {
    width: 264px;
  }
`

export const TypeContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;

  > label {
    width: 128px;
  }

  > div {
    width: 264px;
  }
`

export const Tag = styled.div`
  background-color: #dcf8d8;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 8px;
  gap: 8px;
  padding: 4px 16px;
  flex-wrap: wrap;
  flex-direction: collumn;
  margin-right: 8px;
  border-radius: 16px;
  margin-top: 8px;

  p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #0d4d05;
    width: 100%;
  }
`

export const ExpertsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;

  > div {
    width: 80%;
  }

  > button {
    background: transparent;
    border: 0;
    margin: 0 0 0.6rem 0.6rem;
  }
`
export const ZoomContainer = styled.div`
  display: flex;

  h4 {
    width: 128px;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: #171717;
    text-transform: initial;
  }

  span {
    width: 264px;
    word-break: break-word;
  }

  ul {
    width: 264px;
    list-style: none;

    display: flex;
    gap: 8px;
    li {
      list-style: none;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;

      color: #171717;
      margin-left: 8px;

      background-color: #dcf8d8;
      padding: 4px 16px;
      border-radius: 16px;

      margin: 0;
    }
  }
`

export const HoursContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  > label {
    margin-top: 20px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: #171717;
  }
  > section {
    width: 50%;
  }
`

export const AutosizeContainer = styled.div`
  margin: 0 20px 0 20px;
`

export const ZoomInputContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-end;

  > section {
    width: 290px;
    > input {
      background: green;
    }
  }
`