import React, { ReactNode } from 'react'

import { AuthProvider } from './useAuth'
import { ToastProvider } from './useToast'
import { NetworkProvider } from '../../modules/network/hooks/useNetwork'
import { PortfolioProvider } from 'modules/network/hooks/usePortfolio'
import { MediaProvider } from 'modules/media/hooks/useMedia'
import { BuildProvider } from 'modules/buildingblock/hooks/useBuildingBlock'
import { ContentProvider } from 'modules/contents/hooks/useContent'
import { GreenModalProvider } from 'core/hooks/useGreenModal'
import { CourseProvider } from 'modules/courses/hooks/useCourse'

interface AppProviderProps {
  children: ReactNode
}

export function AppProvider({ children }: AppProviderProps) {
  return (
    <AuthProvider>
      <GreenModalProvider>
        <CourseProvider>
          <NetworkProvider>
            <PortfolioProvider>
              <ContentProvider>
                <MediaProvider>
                  <BuildProvider>
                    <ToastProvider>{children}</ToastProvider>
                  </BuildProvider>
                </MediaProvider>
              </ContentProvider>
            </PortfolioProvider>
          </NetworkProvider>
        </CourseProvider>
      </GreenModalProvider>
    </AuthProvider>
  )
}
