import { collectionGroup, getDocs, query, where } from 'firebase/firestore'
import { ExpertBlockPortfolio, ExpertPortfolioItemStatus, GenericConverter } from '@somostera/tera-models-ts'

import { db } from 'core/config/firebase'

import { getPersonById } from 'core/services/people'

const EXPERT_BLOCKS_PORTFOLIO_COLLECTION_NAME = 'expert_blocks_portfolio'

type ExpertSummaryData = {
  id: string
  name: string
}

export const getValidExpertsForClass = async (blockId: string): Promise<ExpertSummaryData[]> => {
  const expertBlocksPortfolioCollection = collectionGroup(db, EXPERT_BLOCKS_PORTFOLIO_COLLECTION_NAME).withConverter(
    new GenericConverter<ExpertBlockPortfolio>()
  )

  if (!blockId) return []

  const expertsQuery = query(
    expertBlocksPortfolioCollection,
    where('blockId', '==', blockId),
    where('status', 'in', [ExpertPortfolioItemStatus.VALID, ExpertPortfolioItemStatus.IN_VALIDATION])
  ).withConverter(new GenericConverter<ExpertBlockPortfolio>())

  const expertsQuerySnapshot = await getDocs(expertsQuery)

  return await Promise.all(
    expertsQuerySnapshot.docs.map(async (doc) => {
      const { name } = await getPersonById(doc.data().peopleId)

      return {
        id: doc.data().peopleId,
        name
      }
    })
  )
}
