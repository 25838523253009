import React, { createRef, useCallback, useEffect, useState } from 'react'
import { XCircle, X, PenEditSquare } from '@somostera/tera-icons'

import { Container, Description, Tag, InputInformationContainer, Error } from './styles'

export type TagInputType = 'default' | 'tag'
interface TagsInputProps {
  name: string
  label?: string
  placeholder?: string
  errors?: string
  containerStyle?: object
  value: string[]
  onTagsChange: (tags: string[]) => void
  maxLength?: number
  description?: string
  type?: TagInputType
}

export const TagsInput = ({
  name,
  label,
  placeholder,
  errors,
  containerStyle,
  value,
  onTagsChange,
  maxLength,
  description,
  type = 'default'
}: TagsInputProps) => {
  const [newTag, setNewTag] = useState('')
  const [tags, setTags] = useState<string[]>(value)
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = createRef<HTMLInputElement>()

  const removeAllTags = useCallback(() => {
    onTagsChange([])
    setTags([])
  }, [onTagsChange])

  // updates Tags when value update
  useEffect(() => {
    setTags(value)
  }, [value])

  const removeTag = useCallback(
    (index: number) => {
      const newTags = [...tags]
      newTags.splice(index, 1)
      setTags(newTags)
      onTagsChange(newTags)
    },

    [onTagsChange, tags]
  )

  const editTag = (index: number) => {
    setNewTag(tags[index])
    removeTag(index)
  }

  const handleInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      switch (event.key) {
        case 'Backspace':
          if (!newTag) {
            removeTag(tags.length - 1)
          }
          break
        case 'Enter':
        case 'Tab':
          event.preventDefault()
          if (newTag) {
            // value already exists
            if (tags.find((tag) => tag.toLowerCase() === newTag.toLowerCase())) {
              return
            }

            setTags([...tags, newTag])
            setNewTag('')

            onTagsChange([...tags, newTag])
          }

          break
        default:
          break
      }
    },
    [newTag, onTagsChange, removeTag, tags]
  )

  const handleClickContainer = useCallback(() => {
    inputRef.current?.focus()
    setIsFocused(true)
  }, [inputRef])

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  return (
    <div>
      <InputInformationContainer>
        {!!label && <label htmlFor={name}>{label}</label>}
        {!!maxLength && <p>{`${tags.length}/${maxLength}`}</p>}
      </InputInformationContainer>

      {description && <Description>{description}</Description>}

      <Container onClick={handleClickContainer} isFocused={isFocused} isInvalid={!!errors} {...containerStyle}>
        <ul>
          {!!tags &&
            tags.map((tag, index) => (
              <Tag key={tag} tagKey={index + 1} type={type}>
                {tag}

                <button onClick={() => editTag(index)}>
                  <PenEditSquare color="var(--gray-60)" size={18} />
                </button>

                <button onClick={() => removeTag(index)}>
                  <X color="var(--gray-60)" size={18} />
                </button>
              </Tag>
            ))}
          <li>
            <input
              ref={inputRef}
              name={name}
              placeholder={!!tags && !tags.length ? placeholder : ''}
              type="text"
              onKeyDown={handleInputKeyDown}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              value={newTag}
              onChange={(event) => setNewTag(event.target.value)}
              disabled={!!tags && tags.length === maxLength}
            />
          </li>
        </ul>
        {type !== 'tag' && (
          <button type="button" onClick={removeAllTags}>
            <XCircle color="var(--gray-40)" size={24} />
          </button>
        )}
      </Container>
      {!!errors && <Error>{errors}</Error>}
    </div>
  )
}
