import React, { useCallback, useState } from 'react'

import { TableColumn } from 'react-data-table-component'

import { LibraryMaterials } from '@somostera/tera-database'
import { PenEditSquare, Trash } from '@somostera/tera-icons'

import { AutosizeInput } from 'core/components/AutosizeInput'
import { useForm } from 'core/hooks/useForm'
import { NoMaterialsPanel } from 'core/components/NoMaterialsPanel'
import { UploadMaterials } from 'core/components/UploadMaterials'
import { DataTableBase } from 'core/components/DataTableBase'
import { ConfirmationModal as RemoveMediaConfirmationModal } from 'core/components/ConfirmationModal'

import { Container, EditMediaButton, Label, RemoveMediaButton } from './styles'
import { BuildingBlockFormValues } from 'modules/buildingblock/@types/buildings'
import { MediaData, useBuildingBlock } from 'modules/buildingblock/hooks/useBuildingBlock'
import { AddMediaModalBuildingBlock } from 'modules/buildingblock/components/AddMediaModalBuildingBlock'
import { TextBeforeClass } from '@somostera/tera-database/dist/BuildBlock/Domain/types/TextBeforeClass'

interface LibraryMaterialsWithId extends LibraryMaterials {
  id: string
}

export const ContentBuildingBlock = () => {
  const [isAddMediaModalOpen, setIsAddMediaModalOpen] = useState(false)
  const [isConfirmRemoveMediaModalOpen, setIsConfirmRemoveMediaModalOpen] = useState(false)

  const [fileToBeRemoved, setFileToBeRemoved] = useState('')
  const [uploadStatus, setUploadStatus] = useState('')
  const [currentLibraryMaterial, setCurrentLibraryMaterial] = useState({} as LibraryMaterialsWithId)
  const [tableToBeRemoved, setTableToBeRemoved] = useState('')

  const {
    contentDescription,
    setContentDescription,
    files,
    filesUpload,
    filesClass,
    filesComplementary,
    filesOldContent,
    contentAfterClass,
    setFiles,
    setFilesUpload,
    setFilesClass,
    setFilesComplementary,
    setFilesOldContent,
    setCanShowDialogLeavingPage,
    setContentAfterClass
  } = useBuildingBlock()

  const { errors, setValue } = useForm<BuildingBlockFormValues>({
    initialValues: {
      description: ''
    },

    validations: {
      description: { required: { value: true, message: 'Preencha o campo obrigatório.' } }
    }
  })

  const columnsClass: TableColumn<LibraryMaterialsWithId>[] = [
    {
      name: 'Nome',
      selector: ({ name }) => name,
      sortable: true,
      maxWidth: '14rem'
    },
    {
      name: 'Tamanho',
      selector: ({ sizeInMb }) => (sizeInMb === '0' ? '-' : `${sizeInMb} MB`),
      sortable: true,
      width: '8rem'
    },
    {
      name: 'Formato',
      selector: ({ type }) => `${type} `,
      sortable: true,
      width: '8rem'
    },
    {
      cell: (row: LibraryMaterialsWithId) =>
        row.type === 'external link' && (
          <EditMediaButton type="button" onClick={() => handleOpenConfirmEditMediaModal(row, 'class')}>
            <PenEditSquare size={25} color="var(--gray-80)" />
          </EditMediaButton>
        ),
      center: true,
      width: '1rem',
      maxWidth: '1rem'
    },
    {
      cell: (row: LibraryMaterialsWithId) => (
        <RemoveMediaButton type="button" onClick={() => handleOpenConfirmRemoveMediaModal(row.id, 'class')}>
          <Trash size={25} color="var(--gray-80)" />
        </RemoveMediaButton>
      ),
      center: true,
      width: '1rem',
      maxWidth: '1rem'
    }
  ]
  const columnsComplementary: TableColumn<LibraryMaterialsWithId>[] = [
    {
      name: 'Nome',
      selector: ({ name }) => name,
      sortable: true,
      maxWidth: '14rem'
    },
    {
      name: 'Tamanho',
      selector: ({ sizeInMb }) => (sizeInMb === '0' ? '-' : `${sizeInMb} MB`),
      sortable: true,
      width: '8rem'
    },
    {
      name: 'Formato',
      selector: ({ type }) => `${type} `,
      sortable: true,
      width: '8rem'
    },
    {
      cell: (row: LibraryMaterialsWithId) =>
        row.type === 'external link' && (
          <EditMediaButton type="button" onClick={() => handleOpenConfirmEditMediaModal(row, 'complementary')}>
            <PenEditSquare size={25} color="var(--gray-80)" />
          </EditMediaButton>
        ),
      center: true,
      width: '1rem',
      maxWidth: '1rem'
    },
    {
      cell: (row: LibraryMaterialsWithId) => (
        <RemoveMediaButton type="button" onClick={() => handleOpenConfirmRemoveMediaModal(row.id, 'complementary')}>
          <Trash size={25} color="var(--gray-80)" />
        </RemoveMediaButton>
      ),
      center: true,
      width: '1rem',
      maxWidth: '1rem'
    }
  ]
  const columnsUpload: TableColumn<LibraryMaterialsWithId>[] = [
    {
      name: 'Nome',
      selector: ({ name }) => name,
      sortable: true,
      maxWidth: '14rem'
    },
    {
      name: 'Tamanho',
      selector: ({ sizeInMb }) => (sizeInMb === '0' ? '-' : `${sizeInMb} MB`),
      sortable: true,
      width: '8rem'
    },
    {
      name: 'Formato',
      selector: ({ type }) => `${type} `,
      sortable: true,
      width: '8rem'
    },
    {
      cell: (row: LibraryMaterialsWithId) =>
        row.type === 'external link' && (
          <EditMediaButton type="button" onClick={() => handleOpenConfirmEditMediaModal(row, 'upload')}>
            <PenEditSquare size={25} color="var(--gray-80)" />
          </EditMediaButton>
        ),
      center: true,
      width: '1rem',
      maxWidth: '1rem'
    },
    {
      cell: (row: LibraryMaterialsWithId) => (
        <RemoveMediaButton type="button" onClick={() => handleOpenConfirmRemoveMediaModal(row.id, 'upload')}>
          <Trash size={25} color="var(--gray-80)" />
        </RemoveMediaButton>
      ),
      center: true,
      width: '1rem',
      maxWidth: '1rem'
    }
  ]
  const columnsOldContent: TableColumn<TextBeforeClass>[] = [
    {
      name: 'Nome',
      selector: ({ title }) => title,
      sortable: true,
      maxWidth: '14rem'
    },
    {
      name: 'Tamanho',
      selector: ({ expectedMinutes }) => (expectedMinutes === '0' ? '-' : `${expectedMinutes} Min.`),
      sortable: true,
      width: '8rem'
    },
    {
      name: 'Formato',
      selector: ({ type }) => `${type} `,
      sortable: true,
      width: '8rem'
    },
    {
      cell: (row: TextBeforeClass) => (
        <RemoveMediaButton type="button" onClick={() => handleOpenConfirmRemoveMediaModal(row.url, 'oldUpload')}>
          <Trash size={25} color="var(--gray-80)" />
        </RemoveMediaButton>
      ),
      center: true,
      width: '1rem',
      maxWidth: '1rem'
    }
  ]
  const imageColumns: TableColumn<MediaData>[] = [
    {
      name: 'Nome',
      selector: ({ name }) => name,
      sortable: true,
      maxWidth: '14rem'
    },
    {
      name: 'Tamanho',
      selector: ({ size }) => (size === '0' ? '-' : `${size} MB`),
      sortable: true,
      width: '8rem'
    },
    {
      name: 'Formato',
      selector: ({ type }) => `${type} `,
      sortable: true,
      width: '8rem'
    },
    {
      cell: (row: LibraryMaterialsWithId) => (
        <RemoveMediaButton type="button" onClick={() => handleOpenConfirmRemoveMediaModal(row.id, 'files')}>
          <Trash size={25} color="var(--gray-80)" />
        </RemoveMediaButton>
      ),
      center: true,
      width: '1rem',
      maxWidth: '1rem'
    }
  ]
  const handleOpenConfirmEditMediaModal = (row: LibraryMaterialsWithId, status: string) => {
    setUploadStatus(status)
    setCurrentLibraryMaterial(row)
    setIsAddMediaModalOpen(true)
  }

  const handleOpenConfirmRemoveMediaModal = useCallback((id?: string, tableName?: string) => {
    if (id && tableName) {
      setIsConfirmRemoveMediaModalOpen(true)
      setFileToBeRemoved(id)
      setTableToBeRemoved(tableName)
    }
  }, [])
  const handleCloseConfirmRemoveMediaModal = useCallback(async () => {
    setIsConfirmRemoveMediaModalOpen(false)
  }, [])

  const handleRemoveMedia = () => {
    let newFiles = []

    switch (tableToBeRemoved) {
      case 'class':
        newFiles = filesClass.filter((file) => file.id !== fileToBeRemoved)
        setFilesClass(newFiles)
        break
      case 'upload':
        newFiles = filesUpload.filter((file) => file.id !== fileToBeRemoved)
        setFilesUpload(newFiles)
        break
      case 'complementary':
        newFiles = filesComplementary.filter((file) => file.id !== fileToBeRemoved)
        setFilesComplementary(newFiles)
        break
      case 'files':
        newFiles = files.filter((file) => file.id !== fileToBeRemoved)
        setFiles(newFiles)
        break
      case 'oldUpload':
        newFiles = filesOldContent.filter((file) => file.url !== fileToBeRemoved)
        setFilesOldContent(newFiles)
        break
      default:
        break
    }
    setFileToBeRemoved('')
    setIsConfirmRemoveMediaModalOpen(false)
    setTableToBeRemoved('')
  }

  function editFile(editedFile: LibraryMaterialsWithId, currentArray: LibraryMaterialsWithId[]) {
    const newFiles = currentArray.map((item) => {
      if (item.id === editedFile.id) {
        return { ...editedFile }
      } else {
        return { ...item }
      }
    })

    return newFiles
  }

  const handleAddMedia = ({ url, type, sizeInMb, name, id }: LibraryMaterialsWithId) => {
    const newFile: LibraryMaterialsWithId = {
      id: String(id),
      name: name || '',
      url,
      sizeInMb: String(sizeInMb || 0),
      type: type || ''
    }
    const newFileImage: MediaData = {
      id: String(id),
      name: name || '',
      url,
      size: String(sizeInMb || 0),
      type: type || ''
    }

    switch (uploadStatus) {
      case 'class':
        currentLibraryMaterial?.name
          ? setFilesClass(editFile(newFile, filesClass))
          : setFilesClass([...filesClass, newFile])
        break
      case 'upload':
        setFilesUpload(currentLibraryMaterial?.name ? editFile(newFile, filesUpload) : [...filesUpload, newFile])
        currentLibraryMaterial?.name
          ? setFilesUpload(editFile(newFile, filesUpload))
          : setFilesUpload([...filesUpload, newFile])
        break
      case 'complementary':
        setFilesComplementary(
          currentLibraryMaterial?.name ? editFile(newFile, filesComplementary) : [...filesComplementary, newFile]
        )
        break
      case 'files':
        setFiles([...files, newFileImage])
        break
      default:
        break
    }
  }

  const uploadMedia = (status: string) => {
    setUploadStatus(status)
    setIsAddMediaModalOpen(true)
  }

  const handleFormChange = (inputName: any) => (e: any) => {
    setValue(inputName, e.target.value)
    if (e.target.value !== '') setCanShowDialogLeavingPage(true)
    else {
      setCanShowDialogLeavingPage(false)
    }
    if (inputName === 'description') {
      setContentDescription(e.target.value)
    }
    if (inputName === 'contentAfterClass') {
      setContentAfterClass(e.target.value)
    }
  }
  const closeAddMedia = () => {
    setIsAddMediaModalOpen(false)
    setCurrentLibraryMaterial({} as LibraryMaterialsWithId)
  }

  return (
    <Container>
      <AddMediaModalBuildingBlock
        isOpen={isAddMediaModalOpen}
        onRequestClose={closeAddMedia}
        onAddMedia={handleAddMedia}
        hasLink={uploadStatus !== 'files'}
        libraryMaterials={currentLibraryMaterial}
      />
      <RemoveMediaConfirmationModal
        confirmButtonText="Sim, remover"
        confirmButtonColor="var(--red)"
        cancelButtonText="Cancelar"
        messageTitle="Remover arquivo?"
        messageDescription="Essa ação não poderá ser revertida"
        onRequestClose={handleCloseConfirmRemoveMediaModal}
        isOpen={isConfirmRemoveMediaModalOpen}
        onCancel={handleCloseConfirmRemoveMediaModal}
        onConfirm={handleRemoveMedia}
      />

      <section>
        <h1> PRÉ-AULA </h1>
        <Label>Imagem</Label>
        <DataTableBase
          pagination={false}
          columns={imageColumns}
          data={files || []}
          noDataComponent={<NoMaterialsPanel text="Não há materiais cadastrados." />}
        />

        {files.length === 0 && <UploadMaterials text="Material" onRequestUpload={() => uploadMedia('files')} />}

        <AutosizeInput
          type="text"
          name="description"
          label="TEXTO"
          placeholder="Escreva uma breve introdução."
          value={contentDescription}
          onChange={handleFormChange('description')}
          minHeight={'10rem'}
          errors={errors.description}
        />

        <Label>material para upload</Label>
        <DataTableBase
          pagination={false}
          columns={columnsClass}
          data={filesClass || []}
          noDataComponent={<NoMaterialsPanel text="Não há materiais cadastrados." />}
        />

        <UploadMaterials text="Material" onRequestUpload={() => uploadMedia('class')} />

        <hr />
        <h1> MATERIAL COMPLEMENTARES </h1>
        <Label>materiais de aula</Label>

        <DataTableBase
          pagination={false}
          columns={columnsUpload}
          data={filesUpload || []}
          noDataComponent={<NoMaterialsPanel text="Não há materiais cadastrados." />}
        />

        <UploadMaterials text="Material" onRequestUpload={() => uploadMedia('upload')} />

        <Label>materiais de aprofundamento</Label>
        <DataTableBase
          pagination={false}
          columns={columnsComplementary}
          data={filesComplementary || []}
          noDataComponent={<NoMaterialsPanel text="Não há materiais cadastrados." />}
        />

        <UploadMaterials text="Material" onRequestUpload={() => uploadMedia('complementary')} />

        {filesOldContent.length > 0 && (
          <div>
            <br />
            <br />
            <hr />
            <h5> MATERIAL COMPLEMENTARES ANTERIORES </h5>
            <Label>materiais de aula</Label>

            <DataTableBase
              pagination={false}
              columns={columnsOldContent}
              data={filesOldContent || []}
              noDataComponent={<NoMaterialsPanel text="Não há materiais cadastrados." />}
            />
          </div>
        )}

        {contentAfterClass && (
          <div>
            <br />
            <hr />
            <AutosizeInput
              type="input"
              name="contentAfterClass"
              label=" Conteúdo para Aprofundamento "
              value={contentAfterClass}
              onChange={handleFormChange('contentAfterClass')}
            />
          </div>
        )}
      </section>
    </Container>
  )
}
