import styled from 'styled-components'

export const BlueSelectedModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--blue-pastel);
  padding: 0.5rem 0.5rem;

  div {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    color: var(--gray-80);

    > h4 {
      margin-left: 1.5rem;
    }
    > p {
      margin-left: 0.5rem;
    }
  }

  button {
    background: transparent;
    margin-right: 1rem;
  }
`
