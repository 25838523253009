import React, { FormEvent, useRef, useState } from 'react'
import { Button } from 'core/components/Button'

interface PromptBoxProps {
  prompt: string
  sendToAiAssistant: (modifiedPrompt: string) => void
  updateAttributes: (attrs: { prompt: string }) => void
}

export default function PromptBox({ prompt, updateAttributes }: PromptBoxProps) {
  const [modifiedPrompt, setModifiedPrompt] = useState(prompt)
  const contentEditableRef = useRef<HTMLDivElement>(null)

  function getModifiedPromptAndSendToAiAssistant() {
    console.log(modifiedPrompt)
  }

  function handleInputChange(e: FormEvent<HTMLDivElement>) {
    console.log('input')
    setModifiedPrompt(e.currentTarget.textContent || '')
  }

  function handleInputSave() {
    console.log('save')
    updateAttributes({ prompt: modifiedPrompt || '' })
  }

  return (
    <div className="my-12">
      <div>
        <div
          contentEditable
          onInput={handleInputChange}
          onBlur={handleInputSave}
          ref={contentEditableRef}
          suppressContentEditableWarning={true}
          className="border p-2"
        >
          {prompt}
        </div>
      </div>
      <div className="">
        <small className="">
          Edite o conteúdo acima para alterar a pergunta. Para criar uma área de digitação para a estudante, adicione
          [______] no lugar da palavra que deseja. Exemplo: Qual a cor da [laranja]?
        </small>
        <div className="flex gap-2">
          <Button onClick={() => navigator.clipboard.writeText(modifiedPrompt)}>
            Copiar <CopyIcon />
          </Button>
          <Button onClick={() => getModifiedPromptAndSendToAiAssistant()}>
            Enviar p/ a I.A <ArrowRightIcon />
          </Button>
        </div>
      </div>
    </div>
  )
}

function CopyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        d="M1.33301 2.94808C1.33301 2.2117 1.92996 1.61475 2.66634 1.61475H9.33301C10.0694 1.61475 10.6663 2.2117 10.6663 2.94808V5.61475H13.333C14.0694 5.61475 14.6663 6.2117 14.6663 6.94808V13.6147C14.6663 14.3511 14.0694 14.9481 13.333 14.9481H6.66634C5.92996 14.9481 5.33301 14.3511 5.33301 13.6147V10.9481H2.66634C1.92996 10.9481 1.33301 10.3511 1.33301 9.61475V2.94808ZM6.66634 10.9481V13.6147H13.333V6.94808H10.6663V9.61475C10.6663 10.3511 10.0694 10.9481 9.33301 10.9481H6.66634ZM9.33301 9.61475V2.94808L2.66634 2.94808V9.61475H9.33301Z"
        fill="currentColor"
      />
    </svg>
  )
}

function ArrowRightIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        d="M8.19559 3.81001C8.45594 3.54966 8.87805 3.54966 9.1384 3.81001L13.1384 7.81001C13.2634 7.93503 13.3337 8.1046 13.3337 8.28141C13.3337 8.45822 13.2634 8.62779 13.1384 8.75282L9.1384 12.7528C8.87805 13.0132 8.45594 13.0132 8.19559 12.7528C7.93524 12.4925 7.93524 12.0704 8.19559 11.81L11.0575 8.94808L3.33366 8.94808C2.96547 8.94808 2.66699 8.6496 2.66699 8.28141C2.66699 7.91322 2.96547 7.61475 3.33366 7.61475H11.0575L8.19559 4.75282C7.93524 4.49247 7.93524 4.07036 8.19559 3.81001Z"
        fill="currentColor"
      />
    </svg>
  )
}
