import styled from 'styled-components'

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 0.5rem;

  gap: 0.25rem;

  label {
    font-family: 'Rubik';
    color: '#000';
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.01;
  }

  span {
    color: #cc3314;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.75rem;
    margin-top: 0 !important;
  }
`
