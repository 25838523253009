import styled from 'styled-components'
interface LinkContainerProps {
  isLastItem: boolean
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.875rem;
  background-color: #fff;
  padding: 1.25rem 3.75rem;

  position: fixed;
  top: 0;

  z-index: 10;

  > button {
    background: none;
    color: var(--gray-100);
    display: block;
    font-size: 0;
    font-weight: bold;
    text-decoration: underline;

    svg {
    }

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  a {
    text-decoration: none;
  }
`

export const SpanLink = styled.span<LinkContainerProps>`
  span {
    line-height: 1.05rem;

    padding: 0.2rem;
    color: ${(props) => (props.isLastItem ? 'var(--black)' : 'var(--black)')};
    letter-spacing: ${(props) => (props.isLastItem ? '0.01em' : 'normal')};
    font-weight: ${(props) => (props.isLastItem ? '800' : '400')};
    text-transform: uppercase;
    font-family: 'Inter';
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`
