import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled(Link)`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
`
