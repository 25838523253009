import styled from 'styled-components'

export const Form = styled.form``

export const HorizontalPanel = styled.div`
  display: flex;

  > div {
    margin-top: 0.25rem;
  }

  > div + div,
  > label + label {
    margin-left: 1rem;
  }
`

export const Label = styled.label`
  color: var(--gray-60);
  display: block;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.25rem;
  text-transform: uppercase;
  margin: 1.5rem 0 0.25rem;
`

export const AddMoreInfoPanel = styled.div`
  align-items: center;
  display: flex;
  margin-top: 0.75rem;

  button {
    color: var(--gray-100);
    background: transparent;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-left: 0.5rem;
    text-decoration-line: underline;
    text-transform: uppercase;
  }
`

interface NextButtonProps {
  width: string
}
export const SaveButton = styled.button<NextButtonProps>`
  background: var(--black);
  border-radius: 0.25rem;
  color: var(--white);
  font-size: 0.875rem;
  font-weight: bold;
  height: 2rem;
  line-height: 120%;
  margin-top: 1.5rem;
  text-transform: uppercase;
  width: ${(props) => props.width};
`
export const FindZipCodePanel = styled.div`
  align-items: center;
  display: flex;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;

  a {
    color: var(--blue-primary);
    text-decoration-line: underline;
    margin-left: 0.25rem;
  }
`
