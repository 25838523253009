import { FinancialFormValues } from 'modules/network/@types/network'
import { useForm } from 'core/hooks/useForm'
import React, { useState, useEffect } from 'react'
import { Select } from 'core/components/Select'
import { Input } from 'core/components/Input'
import { InformationCircle } from '@somostera/tera-icons'

import { InformationModal as SuccessSaveModal } from 'core/components/InformationModal'
import { InformationModal as ErrorSaveModal } from 'core/components/InformationModal'
import { useToast } from 'core/hooks/useToast'

import { Container, AddClassHour, Form, InfoPanel, EditClassHour, HorizontalPanel } from './styles'

import { useNavigatingAway } from 'core/hooks/useNavigatingAway'
import { DialogLeavingPage } from 'core/components/DialogLeavingPage'
import { useNetwork } from 'modules/network/hooks/useNetwork'

export const PersonFinancial = () => {
  const [showFormHourClass, setShowFormHourClass] = useState(false)
  const [isEdited, setIsEdited] = useState(false)
  const [isBlockedField, setIsBlockedField] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    classHourValueInValidation: '',
    classHourValueValidated: '',
    mentoryHourValue: ''
  })

  const { addToast } = useToast()

  const {
    personFinancialData,
    dataTiers,
    updateKeyNote,
    isSuccessSaveModalOpen,
    isErrorSaveModalOpen,
    setIsSuccessSaveModalOpen,
    setIsErrorSaveModalOpen,
    canShowDialogLeavingPage,
    setCanShowDialogLeavingPage,
    isFinancialUser
  } = useNetwork()

  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] = useNavigatingAway(canShowDialogLeavingPage)

  const isBlockedByPermission = !isFinancialUser

  const saveForm = () => {
    if (isBlockedByPermission) {
      addToast({
        type: 'error',
        title: 'Permissão negada',
        description: 'Você não tem permissão para realizar tarefas do setor financeiro'
      })
      return
    }
    updateKeyNote(data)
    setCanShowDialogLeavingPage(false)
    setIsSuccessSaveModalOpen(true)
    setIsEdited(false)
  }

  const validateForm = () => {
    const dataError = { ...errorMessage }

    dataError.classHourValueInValidation =
      data.classHourValueInValidation === ''
        ? 'Preencha o campo obrigatório'
        : data.classHourValueInValidation === '1' || data.classHourValueInValidation === '0'
        ? 'Este valor não é válido'
        : parseInt(data.classHourValueInValidation) > 10000
        ? 'Valor excede o permitido'
        : /^-?\\d*(\\.\\d+)?$/.test(data.classHourValueInValidation.toString())
        ? 'Uso apenas de números'
        : ''

    dataError.classHourValueValidated =
      data.classHourValueValidated === ''
        ? 'Preencha o campo obrigatório'
        : data.classHourValueValidated === '1' || data.classHourValueValidated === '0'
        ? 'Este valor não é válido'
        : parseInt(data.classHourValueValidated) > 10000
        ? 'Valor excede o permitido'
        : /^-?\\d*(\\.\\d+)?$/.test(data.classHourValueValidated.toString())
        ? 'Uso apenas de números'
        : ''

    // se for keynote o valor de mentoria pode ser 0
    dataError.mentoryHourValue =
      data.mentoryHourValue === ''
        ? 'Preencha o campo obrigatório'
        : data.mentoryHourValue === '1'
        ? 'Este valor não é válido'
        : parseInt(data.mentoryHourValue) > 10000
        ? 'Valor excede o permitido'
        : /^-?\\d*(\\.\\d+)?$/.test(data.mentoryHourValue.toString())
        ? 'Uso apenas de números'
        : ''

    setErrorMessage(dataError)

    if (
      dataError.classHourValueInValidation === '' &&
      dataError.classHourValueValidated === '' &&
      dataError.mentoryHourValue === ''
    ) {
      saveForm()
    }
  }

  const { data, errors, handleChange, handleSubmit, setValue, setFullData } = useForm<FinancialFormValues>({
    initialValues: {
      type: personFinancialData?.type || 'default',
      totalHours: personFinancialData?.totalHours || '0',
      classHourValueInValidation: personFinancialData?.classHourValueInValidation,
      classHourValueValidated: personFinancialData?.classHourValueValidated,
      mentoryHourValue: personFinancialData?.mentoryHourValue,
      canBeManuallySelected: !isBlockedField || false
    },
    onSubmit: validateForm
  })

  useEffect(() => {
    if (personFinancialData) {
      setFullData({
        type: personFinancialData.type,
        totalHours: personFinancialData?.totalHours || '0',
        classHourValueInValidation: personFinancialData.classHourValueInValidation,
        classHourValueValidated: personFinancialData.classHourValueValidated,
        mentoryHourValue: personFinancialData.mentoryHourValue,
        canBeManuallySelected: !isBlockedField || false
      })
    }

    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personFinancialData])

  useEffect(() => {
    if (data.type !== 'default') {
      setShowFormHourClass(true)
      dataTiers?.forEach((tier) => {
        if (tier.id === data.type) {
          setFullData({
            type: data.type,
            totalHours: personFinancialData?.totalHours || '0',
            classHourValueInValidation: tier.classCreation !== undefined ? tier.classCreation.toString() : '',
            classHourValueValidated: tier.standardClass !== undefined ? tier.standardClass.toString() : '',
            mentoryHourValue: tier.mentoring !== undefined ? tier.mentoring.toString() : '',
            canBeManuallySelected: tier.canBeManuallySelected || false
          })
          setIsBlockedField(!tier.canBeManuallySelected || false)
        }
      })
    }

    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.type])

  const handleFormChange = (inputName: any) => (e: any) => {
    setValue(inputName, e.target.value)
    if (e.target.value !== '') setCanShowDialogLeavingPage(true)
    else {
      setCanShowDialogLeavingPage(false)
      handleChange(inputName)
    }
  }

  const handleAddClassHour = () => {
    if (isBlockedByPermission) {
      addToast({
        type: 'error',
        title: 'Permissão negada',
        description: 'Você não tem permissão para realizar tarefas do setor financeiro'
      })
      return
    }
    setShowFormHourClass(true)
    setIsEdited(true)
  }

  const onCloseSuccessSaveModal = () => {
    setIsSuccessSaveModalOpen(false)
  }

  const onCloseErrorSaveModal = () => {
    setIsErrorSaveModalOpen(false)
  }

  const handleEditClassHour = () => {
    if (isBlockedByPermission) {
      addToast({
        type: 'error',
        title: 'Permissão negada',
        description: 'Você não tem permissão para realizar tarefas do setor financeiro'
      })
      return
    }
    setIsEdited(true)
  }

  const avaliableTiers = dataTiers?.filter((tierValue) => tierValue.canBeManuallySelected)

  const formattedTiers = avaliableTiers?.map((tierValue) => {
    return {
      value: `${tierValue.id}`,
      name: `${tierValue.name}`
    }
  })

  return (
    <Container>
      <HorizontalPanel>
        <h3>Hora Aula</h3>
        {personFinancialData !== undefined && personFinancialData.type !== 'default' && (
          <EditClassHour onClick={() => handleEditClassHour()}>Editar</EditClassHour>
        )}
      </HorizontalPanel>
      {!showFormHourClass && personFinancialData !== undefined && personFinancialData.type === 'default' && (
        <>
          <span>Adicione o tipo de Tier ou os valores da hora aula Keynote</span>
          <AddClassHour onClick={() => handleAddClassHour()}>Adicionar</AddClassHour>
        </>
      )}

      {showFormHourClass && (
        <Form onSubmit={handleSubmit}>
          <Select
            name="gender"
            label="Tipo de negociação *"
            items={formattedTiers}
            placeholder="Escolha o tipo de negociação"
            selectedValue={data.type}
            disabled={!isEdited}
            onChange={handleFormChange('type')}
            selectStyle={{ height: '3rem' }}
            errors={errors.type}
          />
          <InfoPanel>
            <a
              href="https://docs.google.com/spreadsheets/d/1t16iOvJrxQ7yjFzENAjaMfdqxEyv6aoQn750mNj-Mxc/edit#gid=1175771369"
              target="blank"
            >
              Consultar tipos de negociação
            </a>
          </InfoPanel>

          {data.type !== 'default' && (
            <>
              <Input
                name="totalHours"
                type="text"
                label="Horas Totais"
                placeholder="00:00"
                disabled={true}
                readOnly={true}
                value={data.totalHours === '0' ? '00:00' : data.totalHours}
                errors={errors.totalHours}
              />

              <InfoPanel>
                <InformationCircle color="var(--blue-primary)" size={20} />
                <span style={{ marginLeft: '0.25rem' }}>Campo não editável, o cálculo é feito automaticamente.</span>
              </InfoPanel>

              {/* <Input
                name="classHourValueInValidation"
                type="text"
                label="Valor hora aula em validação (R$)*"
                placeholder="Adicionar valor"
                onChange={handleFormChange('classHourValueInValidation')}
                disabled={!isEdited || isBlockedField}
                readOnly={!isEdited || isBlockedField}
                value={data.classHourValueInValidation}
                errors={errorMessage.classHourValueInValidation}
              /> */}

              <Input
                name="classHourValueValidated"
                type="text"
                label="Valor hora aula (R$)*"
                placeholder="Adicionar valor"
                onChange={handleFormChange('classHourValueValidated')}
                disabled
                readOnly
                value={data.classHourValueValidated}
                errors={errorMessage.classHourValueValidated}
              />

              <Input
                name="mentoryHourValue"
                type="text"
                label="Valor hora mentoria (R$)*"
                placeholder="Adicionar valor"
                onChange={handleFormChange('mentoryHourValue')}
                disabled
                readOnly
                value={data.mentoryHourValue}
                errors={errorMessage.mentoryHourValue}
              />
              {isEdited && <button type="submit">Salvar</button>}
            </>
          )}
        </Form>
      )}
      <SuccessSaveModal
        messageType="Confirmation"
        messageTitle="Dados salvos com sucesso!"
        isOpen={isSuccessSaveModalOpen}
        onRequestClose={() => onCloseSuccessSaveModal()}
      />
      <ErrorSaveModal
        messageType="Warning"
        messageTitle="Ocorreu um erro ao salvar os dados!"
        isOpen={isErrorSaveModalOpen}
        onRequestClose={() => onCloseErrorSaveModal()}
      />
      <DialogLeavingPage
        showDialog={showDialogLeavingPage}
        setShowDialog={setCanShowDialogLeavingPage}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
    </Container>
  )
}
