import { BuildingBlock, InitializeJourneyService } from '@somostera/tera-database'

import { db } from 'core/config/firebase'

const journeyService = InitializeJourneyService.initialize(db)

export const updateJourneyBlocksAfterToday = async (blockId: string | undefined, buildingBlock: BuildingBlock) => {
  await journeyService.updateJourneyBlockByBuildingBlock(<string>blockId, buildingBlock)
}

export const findBlocksAfterToday = async (blockId: string | undefined) => {
  return journeyService.searchJourneysBlocksAfterToday(<string>blockId)
}
