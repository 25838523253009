import styled from 'styled-components'

interface StatusContainerProps {
  color: string
}

export const StatusContainer = styled.div<StatusContainerProps>`
  position: absolute;
  width: 120px;
  height: 28px;
  left: 68%;
  top: 85px;

  background-color: ${(props) => props.color};
  border-radius: 16px;
  align-items: center;
  display: flex;
  padding: 8px;

  > p {
    position: absolute;
    width: 90px;
    height: 18px;
    left: 30px;
    top: 6px;
    font-size: 14px !important;
    color: #ffffff !important;
    line-weight: 130% !important;
    display: flex;
    align-items: center;
    font-weight: normal !important;
    text-transform: none !important;
  }
`
