import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

interface ChipProps {
  color: string
}
export const Chip = styled.span<ChipProps>`
  background: ${({ color }) => color};
  border-radius: 3rem;
  border: 2px solid white;
  font-size: 0.875rem;
  line-height: 120%;
  padding: 0.25rem 1rem;

  & + span {
    margin-left: 0.25rem;
  }
`
