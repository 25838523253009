import React, { useState, Dispatch, SetStateAction, ChangeEvent } from 'react'
import { InitializeQuizService } from '@somostera/tera-database'

import { db } from 'core/config/firebase'
import { useToast } from 'core/hooks/useToast'

import { ConfirmationModal as RemoveIncorrectOptionModal } from 'core/components/ConfirmationModal'

import { Container, HintContainer, HintBox, TextAreaContainer, TextAreaInput } from './styles'

import Hint from 'core/components/Icon/Hint'
import { Trash, X } from '@somostera/tera-icons'

interface QuizInputProps {
  label?: string
  hint?: {
    message: string
    obs?: string
  }
  showIncorrectOption?: Dispatch<SetStateAction<boolean>>
  showConfirmRemoveIncorrectOptionModal?: Dispatch<SetStateAction<boolean>>
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
  value: string
  onBlur: () => void
  quizInfo?: {
    quizId?: string
    quizOptionId?: string
  }
}

export function QuizInput({
  label,
  hint,
  showIncorrectOption,
  showConfirmRemoveIncorrectOptionModal,
  onChange,
  value,
  onBlur,
  quizInfo
}: QuizInputProps) {
  const quizService = InitializeQuizService.initialize(db)
  const { addToast } = useToast()

  const [hintEnable, setHintEnable] = useState(false)
  const [showIncorrectOptionModal, setShowIncorrectOptionModal] = useState(false)

  const deleteOption = async () => {
    if (showIncorrectOption && showConfirmRemoveIncorrectOptionModal && quizInfo?.quizId) {
      try {
        const blankTarget = { target: { value: '' } }

        onChange(blankTarget as ChangeEvent<HTMLTextAreaElement>)
        showIncorrectOption(false)
        showConfirmRemoveIncorrectOptionModal(true)

        if (quizInfo.quizOptionId != null) {
          await quizService.deleteOptionQuiz(quizInfo.quizId, quizInfo.quizOptionId)
        }
      } catch (error) {
        console.error(error)
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Não foi possível deletar alternativa'
        })
      }
    }
  }

  return (
    <>
      <RemoveIncorrectOptionModal
        confirmButtonText="DELETAR"
        confirmButtonColor="var(--orange-20)"
        cancelButtonText="CANCELAR"
        messageTitle="DELETAR ALTERNATIVA?"
        messageDescription="Essa ação não poderá ser revertida."
        isOpen={showIncorrectOptionModal}
        onCancel={() => setShowIncorrectOptionModal(false)}
        onConfirm={deleteOption}
      />

      <Container>
        {label && (
          <div>
            <label>{label}</label>

            {hint?.message && (
              <HintContainer>
                <button onClick={() => setHintEnable(!hintEnable)}>
                  <Hint height={21} color={hintEnable ? 'var(--gray-60)' : 'var(--blue-primary)'} />
                </button>
                {hintEnable && (
                  <HintBox>
                    <Hint height={21} />

                    <p>
                      {hint?.message}

                      {hint?.obs && (
                        <>
                          <br />
                          <br />
                          Obs: {hint.obs}
                        </>
                      )}
                    </p>

                    <button onClick={() => setHintEnable(false)}>
                      <X height={12} color="var(--blue-primary)" />
                    </button>
                  </HintBox>
                )}
              </HintContainer>
            )}
          </div>
        )}

        <TextAreaContainer>
          <TextAreaInput name="textarea" onChange={onChange} value={value} onBlur={onBlur} />
          {showIncorrectOption && (
            <button onClick={() => setShowIncorrectOptionModal(true)}>
              <Trash color="var(--blue-primary)" height={20} />
            </button>
          )}
        </TextAreaContainer>
      </Container>
    </>
  )
}
