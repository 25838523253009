import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { TableColumn } from 'react-data-table-component'
import { PeopleCalendarStatus } from '@somostera/tera-models-ts'

import { InitialCastingPageData } from '../../@types/casting'

import { getInitialCastingPageData } from '../../services/casting'

import { DataTableBase } from 'core/components/DataTableBase'
import { FilterTable } from 'core/components/DataTableBase/FilterTable'
import { sortByDate } from 'core/utils/date'

import { Container } from './styles'

export function Casting() {
  const [isLoading, setIsLoading] = useState(true)
  const [initialCastingPageData, setInitialCastingPageData] = useState<InitialCastingPageData[]>([])

  const [filterText, setFilterText] = useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

  const columns: TableColumn<InitialCastingPageData>[] = [
    {
      name: 'Tera Id',
      selector: ({ teraId }: InitialCastingPageData) => teraId,
      cell: (row: InitialCastingPageData) => <Link to={`/casting/${row.journeyId}`}>{row.teraId}</Link>,
      sortable: true,
      minWidth: '12rem'
    },
    {
      name: 'Início',
      selector: ({ startsAt }: InitialCastingPageData) => startsAt,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.startsAt.split('/').reverse().join('-') + ' 00:00:00')
        const dateB = new Date(rowB.startsAt.split('/').reverse().join('-') + ' 00:00:00')

        return sortByDate(dateA, dateB)
      },
      width: '8rem'
    },
    {
      name: 'Término',
      selector: ({ endsAt }: InitialCastingPageData) => endsAt,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.endsAt.split('/').reverse().join('-') + ' 00:00:00')
        const dateB = new Date(rowB.endsAt.split('/').reverse().join('-') + ' 00:00:00')

        return sortByDate(dateA, dateB)
      },
      width: '8rem'
    },
    {
      name: 'Experts Selecionados',
      selector: (row: InitialCastingPageData) => row[PeopleCalendarStatus.SELECTED],
      center: true,
      width: '9rem'
    },
    {
      name: 'Experts Convidados',
      selector: (row: InitialCastingPageData) => row[PeopleCalendarStatus.SEND_INVITE],
      center: true,
      width: '8rem'
    },
    {
      name: 'Experts Confirmados',
      selector: (row: InitialCastingPageData) => row[PeopleCalendarStatus.ACCEPTED],
      center: true,
      width: '9rem'
    }
  ]

  useEffect(() => {
    // eslint-disable-next-line prettier/prettier
    ;(async () => {
      const initialData = await getInitialCastingPageData()
      setInitialCastingPageData(initialData)
      setIsLoading(false)
    })()
  }, [])

  const filteredItems = useMemo(
    () =>
      initialCastingPageData.filter(
        (item) =>
          JSON.stringify({
            teraId: item.teraId,
            startsAt: item.startsAt,
            endsAt: item.endsAt
          })
            .toLowerCase()
            .indexOf(filterText.toLowerCase()) !== -1
      ),
    [filterText, initialCastingPageData]
  )

  const SubHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    return (
      <FilterTable
        placeholder="Busque por Tera Id ou datas de início ou término"
        onFilter={(event) => setFilterText(event.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    )
  }, [filterText, resetPaginationToggle])

  if (isLoading) {
    return <h1>Abrindo...</h1>
  }

  return (
    <Container>
      <h1>Casting</h1>
      <DataTableBase columns={columns} data={filteredItems} subHeader subHeaderComponent={SubHeaderComponent} />
    </Container>
  )
}
