import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { useAuth } from '../hooks/useAuth'

import { RouteElement } from './RouteElement'

import { SignIn } from '../views/SignIn'
import { Home } from '../views/Home'

import { Contents } from 'modules/contents/views/Contents'
import { ContentEditor } from 'modules/contents/views/Contents/ContentEditor'
import { AddContent } from 'modules/contents/views/Contents/AddContent'
import { ContentPreview } from 'modules/contents/views/Contents/ContentPreview'
import { DataSheet } from 'modules/contents/views/Contents/DataSheetPage'

import { Casting } from 'modules/casting/views/Casting'
import { JourneyCasting } from 'modules/casting/views/Casting/JourneyCasting'

import { BuildingBlockPage } from 'modules/buildingblock/views/BuildingBlockPage'
import { AddBuildingBlock } from 'modules/buildingblock/views/AddBuildingBlock'
import { DetailsBuildingBlock } from 'modules/buildingblock/views/DetailsBuildingBlock'
import { ContentBuildingBlock } from 'modules/buildingblock/views/ContentBuildingBlock'
import { SettingsBuildingBlock } from 'modules/buildingblock/views/SettingsBuildingBlock'

import { Person } from 'modules/network/views/Person'
import { Network } from 'modules/network/views/Network'
import { ContactVerification } from 'modules/network/views/ContactVerification'
import { PortfolioClass } from 'modules/network/views/PortfolioClass'
import { PersonProfile } from 'modules/network/views/PersonProfile'
import { PersonPortfolio } from 'modules/network/views/PersonPortfolio'
import { AddPortfolioClass } from 'modules/network/views/AddPortfolioClass'
import { PersonFinancial } from 'modules/network/views/PersonFinancial'

import { MediaPage } from 'modules/media/views/MediaPage'
import { DataSheetEdit } from 'modules/contents/views/Contents/DataSheetEdit'
import { Courses } from 'modules/courses/views/Courses'
import { AddCourse } from 'modules/courses/views/Courses/AddCourse'
import { ViewCourse } from 'modules/courses/views/Courses/ViewCourse'
import { ViewFormation } from 'modules/formations/views/Formations'
import { AddFormation } from 'modules/formations/views/Formations/AddFormation'
import { Lives } from 'modules/lives/views/Lives'
import { AddLive } from 'modules/lives/views/Lives/AddLive'
import { NewScheduleLive } from 'modules/lives/views/Lives/NewScheduleLive'

export default function MainRoutes() {
  const { loading } = useAuth()

  if (loading) {
    return <h1>Abrindo...</h1>
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <RouteElement>
            <SignIn />
          </RouteElement>
        }
      />

      <Route
        path="/home"
        element={
          <RouteElement isPrivate>
            <Home />
          </RouteElement>
        }
      />

      <Route
        path="/contents"
        element={
          <RouteElement isPrivate>
            <Contents />
          </RouteElement>
        }
      />

      <Route
        path="/contents/new/:type"
        element={
          <RouteElement isPrivate>
            <AddContent />
          </RouteElement>
        }
      />

      <Route
        path="/contents/:articleId"
        element={
          <RouteElement isPrivate>
            <ContentEditor />
          </RouteElement>
        }
      />

      <Route
        path="/contents/:articleId/:chapterIndex/preview"
        element={
          <RouteElement isPrivate>
            <ContentPreview />
          </RouteElement>
        }
      />

      <Route
        path="/contents/:articleId/datasheet"
        element={
          <RouteElement isPrivate>
            <DataSheet />
          </RouteElement>
        }
      />

      <Route
        path="/contents/:articleId/datasheet/edit"
        element={
          <RouteElement isPrivate>
            <DataSheetEdit />
          </RouteElement>
        }
      />

      <Route
        path="/media"
        element={
          <RouteElement isPrivate>
            <MediaPage />
          </RouteElement>
        }
      />

      <Route
        path="/casting"
        element={
          <RouteElement isPrivate>
            <Casting />
          </RouteElement>
        }
      />

      <Route
        path="/casting/:journeyId/:teraId"
        element={
          <RouteElement isPrivate>
            <JourneyCasting />
          </RouteElement>
        }
      />

      <Route
        path="/network"
        element={
          <RouteElement isPrivate>
            <Network />
          </RouteElement>
        }
      />

      <Route
        path="/network/person/:personId"
        element={
          <RouteElement isPrivate>
            <Person />
          </RouteElement>
        }
      >
        <Route path="" element={<PersonProfile />} />
        <Route path="portfolio" element={<PersonPortfolio />} />
        <Route path="financial" element={<PersonFinancial />} />
      </Route>

      <Route
        path="/network/person/add/verification"
        element={
          <RouteElement isPrivate>
            <ContactVerification />
          </RouteElement>
        }
      />

      <Route
        path="/network/person/:personId/portfolio/add"
        element={
          <RouteElement isPrivate>
            <AddPortfolioClass />
          </RouteElement>
        }
      />

      <Route
        path="/network/person/:personId/portfolio/:classId"
        element={
          <RouteElement isPrivate>
            <PortfolioClass />
          </RouteElement>
        }
      />

      <Route
        path="/buildingblock"
        element={
          <RouteElement isPrivate>
            <BuildingBlockPage />
          </RouteElement>
        }
      />

      <Route
        path="/buildingblock/add/:blocktype"
        element={
          <RouteElement isPrivate>
            <AddBuildingBlock />
          </RouteElement>
        }
      >
        <Route path="details" element={<DetailsBuildingBlock />} />
        <Route path="content" element={<ContentBuildingBlock />} />
        <Route path="settings" element={<SettingsBuildingBlock />} />
      </Route>

      <Route
        path="/buildingblock/edit/:blocktype/:blockId"
        element={
          <RouteElement isPrivate>
            <AddBuildingBlock />
          </RouteElement>
        }
      >
        <Route path="details" element={<DetailsBuildingBlock />} />
        <Route path="content" element={<ContentBuildingBlock />} />
        <Route path="settings" element={<SettingsBuildingBlock />} />
      </Route>

      <Route
        path="/courses"
        element={
          <RouteElement isPrivate>
            <Courses />
          </RouteElement>
        }
      />
      <Route
        path="/courses/new"
        element={
          <RouteElement isPrivate>
            <AddCourse />
          </RouteElement>
        }
      />

      <Route
        path="/courses/:courseId"
        element={
          <RouteElement isPrivate>
            <ViewCourse />
          </RouteElement>
        }
      />

      <Route
        path="/formations"
        element={
          <RouteElement isPrivate>
            <ViewFormation />
          </RouteElement>
        }
      />

      <Route
        path="/formations/new"
        element={
          <RouteElement isPrivate>
            <AddFormation />
          </RouteElement>
        }
      />

      <Route
        path="/lives"
        element={
          <RouteElement isPrivate>
            <Lives />
          </RouteElement>
        }
      />

      <Route
        path="/lives/new"
        element={
          <RouteElement isPrivate>
            <AddLive />
          </RouteElement>
        }
      />
      <Route
        path="/lives/:liveId/new/schedule"
        element={
          <RouteElement isPrivate>
            <NewScheduleLive />
          </RouteElement>
        }
      />
    </Routes>
  )
}
