import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 32rem;

  > section {
    width: 35rem;
    > h3 {
      color: var(--black);
      font-size: 1rem;
      line-height: 1.25rem;
      margin: 3.2rem 0 0.5rem;
      text-transform: uppercase;
    }
    p {
      color: var(--gray-80);
      font-size: 1rem;
      line-height: 1.5rem;
    }
    > hr {
      background: var(--gray-80);
      height: 0.1rem;
      margin-top: 2.25rem;
      width: 35rem;
    }
  }
`
export const AddFilesPanel = styled.div``

export const RemoveBuildingBlockButton = styled.button`
  background: var(--red);
  border-radius: 0.5rem;
  color: var(--white);
  font-weight: bold;
  font-size: 1rem;
  height: 3rem;
  line-height: 1.25rem;
  margin-top: 1.5rem;
  text-transform: uppercase;
  width: 10.25rem;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
    filter: brightness(0.8);
  }
`

export const UpdateBuildingBlockButton = styled.button`
  background: var(--black);
  border-radius: 0.5rem;
  color: var(--white);
  font-weight: bold;
  font-size: 1rem;
  height: 3rem;
  line-height: 1.25rem;
  margin-top: 1.5rem;
  text-transform: uppercase;
  width: 10.25rem;
`
export const UpdateBuildingBlockConfirmationModal = styled.div``
