import React from 'react'

export default function Pilar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 6.11959e-07C17.1046 6.60242e-07 18 0.895431 18 2V6C18 7.10457 17.1046 8 16 8L2 8C0.895429 8 -4.82823e-08 7.10457 0 6L1.74846e-07 2C2.23128e-07 0.895431 0.89543 -4.82823e-08 2 0L16 6.11959e-07ZM16 6V2L2 2L2 6L16 6Z"
        fill="currentColor"
      />
      <path
        d="M16 10C17.1046 10 18 10.8954 18 12V16C18 17.1046 17.1046 18 16 18H2C0.895429 18 -4.82823e-08 17.1046 0 16L1.74846e-07 12C2.23128e-07 10.8954 0.89543 10 2 10L16 10ZM16 16V12L2 12L2 16H16Z"
        fill="currentColor"
      />
    </svg>
  )
}
