import { ArrowRefresh, CheckCircle } from '@somostera/tera-icons'
import { useContent } from 'modules/contents/hooks/useContent'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { Link, useParams } from 'react-router-dom'
import { useImmerReducer } from 'use-immer'
import Validator from 'validatorjs'

import {
  ArticleDraft,
  ArticleStatus,
  Chapter,
  ContentType,
  InitializeArticleService,
  InitializeQuizService
} from '@somostera/tera-database'

import { FocusClasses } from '@tiptap/extension-focus'
import { Image } from '@tiptap/extension-image'
import { Link as LinkExtension } from '@tiptap/extension-link'
import { Typography } from '@tiptap/extension-typography'
import { Underline as UnderlineExtension } from '@tiptap/extension-underline'
import Youtube from '@tiptap/extension-youtube'
import { Editor, EditorContent, generateJSON, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

import { ChaptersMenu } from 'modules/contents/components/ChaptersMenu'
import { CodeBlockSyntaxHighlight } from 'modules/contents/components/Nodes/CodeBlockSyntaxHighlight'
import { ColouredCardList } from 'modules/contents/components/Nodes/ColouredCardList'
import { FileDownload } from 'modules/contents/components/Nodes/FileDownload'
import { PageBreak } from 'modules/contents/components/Nodes/PageBreak'
import { PromptBoxModule } from 'modules/contents/components/Nodes/PromptBox'
import { Quote } from 'modules/contents/components/Nodes/Quote'
import { Video } from 'modules/contents/components/Nodes/Video'

import { articlesReducer, State } from 'modules/contents/reducers/articles'

import { db } from 'core/config/firebase'
import { useToast } from 'core/hooks/useToast'
import { getDateInformation, monthNames } from 'core/utils/date'

import { Loading } from 'core/components/Animation/Loading'

import { Button } from 'core/components/Button'
import {
  ConfirmationModal as PublishConfirmationModal,
  ConfirmationModal as RemoveChapterConfirmationModal
} from 'core/components/ConfirmationModal'
import {
  InformationModal as FailPublishArticleModal,
  InformationModal as SuccessPublishArticleModal,
  InformationModal as SuccessRemoveChapterModal
} from 'core/components/InformationModal'
import { MainFunctionsMenu } from 'modules/contents/components/MainFunctionsMenu'
import { QuizTemplate } from 'modules/contents/components/QuizTemplate'
import { findArticleById } from 'modules/contents/services/article'

import { DataSheet } from 'modules/contents/components/modals/DataSheet'
import {
  ChaptersMenuAndEditorContainer,
  ChaptersMenuContainer,
  Container,
  EditorContainer,
  Header,
  HeaderAndMenuBarContainer,
  HeaderButtonsContainer,
  LoadingContainer,
  MenuBarContainer,
  SavingStatusText,
  Title
} from './styles'
import {ExpertTipModule} from "../../../components/Nodes/ExpertTip";

export enum SelectableStatus {
  draft = 'Rascunho',
  edited = 'Editado',
  published = 'Publicado'
}

const articleDraftService = InitializeArticleService.initialize(db)
const quizService = InitializeQuizService.initialize(db)

export function ContentEditor() {
  const { articleId } = useParams()
  const { addToast } = useToast()
  const { updateCurrentArticleDraftName } = useContent()

  const [currentSavingStatus, setCurrentSavingStatus] = React.useState('')
  const [savingStatusDebounce, setSavingStatusDebounce] = useState<NodeJS.Timeout>({} as NodeJS.Timeout)

  const [isConfirmPublishModalOpen, setIsConfirmPublishModalOpen] = useState(false)
  const [isConfirmRemoveChapterModalOpen, setIsConfirmRemoveChapterModalOpen] = useState(false)
  const [isSuccessRemoveChapterModalOpen, setIsSuccessRemoveChapterModalOpen] = useState(false)
  const [isSuccessPublishArticleModalOpen, setIsSuccessPublishArticleModalOpen] = useState(false)
  const [isFailPublishArticleModalOpen, setIsFailPublishArticleModalOpen] = useState(false)
  const [isDataSheetOpen, setIsDataSheetOpen] = useState(false)

  const [currentTemplateName, setCurrentTemplateName] = useState<ContentType>(ContentType.CHAPTER)

  const [quizToBeRemoved, setQuizToBeRemoved] = useState('')

  const [currentArticleInfo, setCurrentArticleInfo] = useState<ArticleDraft>({} as ArticleDraft)

  const [isLoading, setIsLoading] = useState(true)

  function isPublished(): boolean {
    if (currentArticleInfo?.status === 'published') {
      return true
    } else {
      return false
    }
  }

  const [
    { currentArticleDraft, currentChapterIndex, chapterToBeRemoved, currentArticleName, currentChapter },
    setState
  ] = useImmerReducer(articlesReducer, {
    isLoading: true,
    currentArticleDraft: {} as ArticleDraft,
    currentArticleName: '',
    currentChapterIndex: 0,
    chapterToBeRemoved: -1,
    currentChapter: {} as Chapter,
    lastStatusAndSavingDate: ''
  } as State)

  const handleEditorUpdate = (editor: Editor) => {
    const content = editor.getJSON()
    setState({ type: 'UPDATE_CURRENT_CHAPTER_CONTENT', payload: { content } })
  }

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        bulletList: { HTMLAttributes: { class: 'editor-bullet-list' } },
        codeBlock: false
      }),
      UnderlineExtension,
      Typography,
      FocusClasses.configure({
        className: 'has-focus',
        mode: 'shallowest'
      }),
      LinkExtension.configure({ HTMLAttributes: { class: 'editor-link' } }),
      PageBreak,
      PromptBoxModule,
      ExpertTipModule,
      Video,
      Image.configure({ HTMLAttributes: { class: 'editor-image' } }),
      FileDownload,
      Quote.configure({ isPreview: false }),
      CodeBlockSyntaxHighlight,
      ColouredCardList,
      Youtube.configure({
        allowFullscreen: true
      })
    ],
    editorProps: {
      attributes: {
        class: 'editor'
      }
    },
    onUpdate({ editor }) {
      handleEditorUpdate(editor as Editor)
    }
  })

  useEffect(() => {
    async function getArticleInfo() {
      if (articleId) {
        const articleInfo = await findArticleById(articleId, true)
        setCurrentArticleInfo(articleInfo)
      }
    }
    getArticleInfo()
  }, [articleId, isSuccessPublishArticleModalOpen])

  useEffect(() => {
    setState({ type: 'ASYNC_CALL' })
    articleDraftService
      .updateChapter(articleId!, currentChapter.index, currentChapter)
      .then(() => setState({ type: 'ASYNC_CALL_SUCCEED' }))
      .catch(() =>
        setState({ type: 'ASYNC_CALL_FAILED', payload: { error: 'something went wrong when updating chapter' } })
      )
    // setIsLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleId!, currentChapter])

  useEffect(() => {
    setState({ type: 'ASYNC_CALL' })
    articleDraftService.findById(articleId!).then((articleDraft) => {
      if (!articleDraft) {
        setState({ type: 'ASYNC_CALL_FAILED', payload: { error: 'Article not found.' } })
        return
      }
      setState({ type: 'FETCH_CURRENT_ARTICLE_DRAFT', payload: { article: articleDraft } })
      setState({ type: 'UPDATE_CURRENT_CHAPTER', payload: { index: 0, editor } })
      setCurrentTemplateName(articleDraft.chapters[0].contentType)
      if (!updateCurrentArticleDraftName) {
        return
      }
      updateCurrentArticleDraftName(articleDraft.name)
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleId!, editor])

  const lastStatusAndSavingDate = useMemo(() => {
    if (currentArticleDraft?.updatedAt) {
      const { currentDay, day, hours, minutes, month, year } = getDateInformation(currentArticleDraft.updatedAt)
      const dateText = (!currentDay ? `em ${day} de ${monthNames[month]} de ${year} ` : '') + `às ${hours}h${minutes}`
      return `${SelectableStatus[currentArticleDraft.status]} - Última edição salva ${dateText}`
    }
  }, [currentArticleDraft])

  const handleCloseConfirmPublishModal = useCallback(() => {
    setIsConfirmPublishModalOpen(false)
  }, [])

  const handleOpenConfirmRemoveChapterModal = useCallback(async (chapterIndex: number, quizId?: string) => {
    setState({ type: 'UPDATE_CURRENT_CHAPTER_TO_BE_REMOVED', payload: { index: chapterIndex } })
    setIsConfirmRemoveChapterModalOpen(true)

    if (quizId) {
      setQuizToBeRemoved(quizId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCloseConfirmRemoveChapterModal = useCallback(() => {
    setIsConfirmRemoveChapterModalOpen(false)
  }, [])

  const updateCurrentArticleName = async (articleId: string, newName: string) => {
    try {
      if (!currentArticleDraft) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('Article not found')
      }
      const newArticle: ArticleDraft = { ...currentArticleDraft, name: newName }
      await saveCurrentArticleDraft(newArticle)
      updateCurrentArticleDraftName(newName)
    } catch (error) {
      setState({ type: 'ASYNC_CALL_FAILED', payload: { error: 'something went wrong' } })
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Artigo não encontrado'
      })
    }
  }

  const handleChangeArticleName = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setState({
      type: 'UPDATE_CURRENT_ARTICLE_DRAFT_NAME',
      payload: {
        newArticleName: event.target.value
      }
    })
    updateCurrentArticleDraftName(event.target.value)
  }

  const handleSelectChapter = (index: number, contentType: ContentType) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setState({ type: 'UPDATE_CURRENT_CHAPTER', payload: { index, editor: editor! } })
    setCurrentTemplateName(contentType)
  }

  const checkIfQuizIsComplete = async () => {
    const chapters = currentArticleDraft.chapters || []

    const quizChapters = chapters.filter((chapter) => chapter.contentType === ContentType.QUIZ)

    const quizRules = {
      title: 'required',
      subtitle: 'required',
      comment: 'required'
    }

    const optionsRules = {
      option: 'required'
    }

    const quizInfoArray = quizChapters.map(async (content) => {
      if (content.quizId) {
        const quizContent = await quizService.findById(content.quizId)

        return quizContent
      }
    })

    async function validateQuizData() {
      const validation = (await Promise.all(quizInfoArray)).some((content) => {
        const quizValidator = new Validator(content, quizRules)

        if (quizValidator.fails()) {
          return quizValidator.fails()
        }

        const optionValidation = content?.options?.some((option) => {
          const optionValidator = new Validator(option, optionsRules)

          return optionValidator.fails()
        })

        return optionValidation
      })

      return validation
    }

    return await validateQuizData()
  }

  const publishCurrentArticleDraft = async (articleId: string) => {
    try {
      if (await checkIfQuizIsComplete()) {
        throw new Error('Algum Quiz está incompleto')
      }
      setState({ type: 'ASYNC_CALL' })
      await articleDraftService.publishArticleDraft(articleId!)
      setState({ type: 'ASYNC_CALL_SUCCEED' })
      setIsSuccessPublishArticleModalOpen(true)
    } catch (error) {
      console.error(error)
      setState({ type: 'ASYNC_CALL_FAILED', payload: { error: 'something went wrong' } })
      addToast({
        type: 'error',
        title: 'Erro de publicação',
        description: 'Houve um problema ao publicar o Rascunho'
      })
      setIsFailPublishArticleModalOpen(true)
    }
  }

  const reorderChapters = (chapters: Chapter[], startIndex: number, endIndex: number) => {
    try {
      if (!chapters) {
        throw new Error('Algo deu errado ao ordenar os capítulos.')
      }

      const newArrayOfChapters = Array.from(chapters)
      const [movedItem] = newArrayOfChapters.splice(startIndex, 1)
      newArrayOfChapters.splice(endIndex, 0, movedItem)

      // remap index of each chapter in the new order
      return newArrayOfChapters.map((item, index) => ({
        ...item,
        index
      }))
    } catch (error) {
      console.error(error)
      setState({ type: 'ASYNC_CALL_FAILED', payload: { error: 'something went wrong' } })
    }
  }

  const handleChangeChaptersPositions = async (result: DropResult) => {
    const { source, destination } = result

    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return
    }

    try {
      const orderedChapters = reorderChapters(currentArticleDraft.chapters, source.index, destination.index)

      if (!orderedChapters) {
        throw new Error('algo deu errado')
      }

      const newArticle = {
        ...currentArticleDraft,
        chapters: orderedChapters
      }

      await saveCurrentArticleDraft(newArticle)
    } catch (error) {
      console.error(error)
      setState({ type: 'ASYNC_CALL_FAILED', payload: { error: 'something went wrong' } })
      addToast({
        type: 'error',
        title: 'Erro ao ordenar capítulos',
        description: 'Aconteceu algo errado ao reordenar os capítulos.'
      })
    }
  }

  const createNewQuiz = async (copyQuizId?: string) => {
    let quiz

    const quizOptionsDefault = [
      {
        index: 1,
        itCorrect: true,
        option: ''
      },
      {
        index: 2,
        itCorrect: false,
        option: ''
      },
      {
        index: 3,
        itCorrect: false,
        option: ''
      }
    ]

    if (copyQuizId) {
      quiz = await quizService.findById(copyQuizId)
    }

    const quizId = await quizService.create({
      title: quiz?.title || '',
      subtitle: quiz?.subtitle || '',
      comment: quiz?.comment || ''
    })

    await quizService.addOptionToQuiz(quizId, quiz?.options || quizOptionsDefault)

    return quizId
  }

  const addChapterToCurrentArticle = async (templateName: ContentType, chapter?: Chapter) => {
    try {
      let newChapter: Chapter
      let quizId
      const chapterIndex = currentArticleDraft.chapters.length

      if (templateName === ContentType.QUIZ) {
        try {
          await createNewQuiz(chapter?.quizId).then((newQuizId) => (quizId = newQuizId))
        } catch (error) {
          console.error(error)
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Não foi possível criar um Quiz'
          })
        }
      }

      switch (templateName) {
        case ContentType.QUIZ:
          newChapter = {
            title: chapter ? `[CÓPIA] ${chapter.title}` : '[QUIZ] Escreva a pergunta',
            index: chapterIndex,
            contentType: ContentType.QUIZ,
            quizId
          }
          break
        case ContentType.CHAPTER:
          newChapter = {
            title: chapter ? `[CÓPIA] ${chapter.title}` : `[NOVO] Capítulo ${chapterIndex + 1}`,
            index: chapterIndex,
            content: chapter ? chapter.content : generateJSON(`<p></p>`, [StarterKit]),
            contentType: ContentType.CHAPTER
          }
          break
        default:
          newChapter = {
            title: chapter ? `[CÓPIA] ${chapter.title}` : `[NOVO] Capítulo ${chapterIndex + 1}`,
            index: chapterIndex,
            content: chapter ? chapter.content : generateJSON(`<p></p>`, [StarterKit]),
            contentType: ContentType.CHAPTER
          }
      }

      const newArticle = {
        ...currentArticleDraft,
        chapters: [...currentArticleDraft.chapters, newChapter]
      }

      await saveCurrentArticleDraft(newArticle)
    } catch (error) {
      console.error(error)
      setState({ type: 'ASYNC_CALL_FAILED', payload: { error: 'something went wrong' } })
      addToast({
        type: 'error',
        title: 'Erro ao criar capítulo',
        description: 'Aconteceu algo errado ao criar capítulos.'
      })
    }
  }

  const updateChapterName = async (chapterIndex: number, newTitle: string) => {
    try {
      const newArrayOfChapters = Array.from(currentArticleDraft.chapters)
      const newChapter: Chapter = { ...currentArticleDraft.chapters[chapterIndex], title: newTitle }

      newArrayOfChapters.splice(chapterIndex, 1, newChapter)

      const newArticle = {
        ...currentArticleDraft,
        chapters: newArrayOfChapters
      }

      await saveCurrentArticleDraft(newArticle)
    } catch (error) {
      console.error(error)
      setState({ type: 'ASYNC_CALL_FAILED', payload: { error: 'something went wrong' } })
      addToast({
        type: 'error',
        title: 'Erro ao alterar capítulo',
        description: 'Aconteceu algo errado na alteração de nome do capítulo.'
      })
    }
  }

  const removeChapterFromCurrentArticle = async (chapterToBeRemovedIndex: number) => {
    try {
      const filteredChapters = currentArticleDraft.chapters.filter(
        (chapter) => chapter.index !== chapterToBeRemovedIndex
      )
      const orderedChapters = reorderChapters(filteredChapters, 0, 0)
      if (!orderedChapters) {
        throw new Error('something went wrong')
      }

      const newArticle = {
        ...currentArticleDraft,
        chapters: orderedChapters
      }

      if (currentChapterIndex === chapterToBeRemoved) {
        setState({
          type: 'UPDATE_CURRENT_CHAPTER',
          payload: {
            index: chapterToBeRemoved === 0 ? 0 : chapterToBeRemoved - 1,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            editor: editor!
          }
        })
      }

      await saveCurrentArticleDraft(newArticle)

      setState({
        type: 'UPDATE_CURRENT_CHAPTER_TO_BE_REMOVED',
        payload: {
          index: -1
        }
      })

      if (quizToBeRemoved !== '') {
        await quizService.delete(quizToBeRemoved)
        setQuizToBeRemoved('')
      }

      setIsSuccessRemoveChapterModalOpen(true)
      handleSelectChapter(0, ContentType.CHAPTER)
    } catch (error) {
      setState({ type: 'ASYNC_CALL_FAILED', payload: { error: 'something went wrong' } })
      addToast({
        type: 'error',
        title: 'Erro ao remover capítulo',
        description: 'Houve um problema ao remover o capítulo'
      })
    }
  }

  const saveCurrentArticleDraft = useCallback(
    async (newArticle: ArticleDraft) => {
      if (currentSavingStatus !== 'saving') {
        setCurrentSavingStatus('saving')
      }

      setState({ type: 'ASYNC_CALL' })
      newArticle.status = ArticleStatus.DRAFT
      await articleDraftService.update(articleId!, newArticle)

      setState({
        type: 'UPDATE_CURRENT_ARTICLE_DRAFT',
        payload: {
          article: newArticle
        }
      })

      setState({ type: 'ASYNC_CALL_SUCCEED' })

      clearTimeout(savingStatusDebounce)

      const debounce = setTimeout(() => {
        clearTimeout(savingStatusDebounce)
        const saveTimeout = setTimeout(() => {
          setCurrentSavingStatus('onHold')
          clearTimeout(saveTimeout)
        }, 1000)
      }, 1000)

      setSavingStatusDebounce(debounce)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [articleId!]
  )

  if (!editor) {
    return <div />
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    )
  }

  return (
    <>
      <PublishConfirmationModal
        confirmButtonText="Publicar"
        confirmButtonColor="var(--blue-to-purple)"
        cancelButtonText="Cancelar"
        messageTitle="Atenção"
        messageDescription="Ao clicar em 'Publicar', este artigo será atualizado na biblioteca de conteúdos. Esta ação não será reversível."
        onRequestClose={handleCloseConfirmPublishModal}
        isOpen={isConfirmPublishModalOpen}
        onCancel={handleCloseConfirmPublishModal}
        onConfirm={() => {
          // noinspection JSIgnoredPromiseFromCall
          publishCurrentArticleDraft(articleId!)
          handleCloseConfirmPublishModal()
        }}
      />

      <RemoveChapterConfirmationModal
        confirmButtonText="Remover"
        confirmButtonColor="var(--orange-20)"
        cancelButtonText="Cancelar"
        messageTitle="Remover Capítulo?"
        messageDescription="Essa ação não poderá ser revertida"
        onRequestClose={handleCloseConfirmRemoveChapterModal}
        isOpen={isConfirmRemoveChapterModalOpen}
        onCancel={handleCloseConfirmRemoveChapterModal}
        onConfirm={() => {
          // noinspection JSIgnoredPromiseFromCall
          removeChapterFromCurrentArticle(chapterToBeRemoved)
          handleCloseConfirmRemoveChapterModal()
        }}
      />

      <SuccessRemoveChapterModal
        messageType="Confirmation"
        messageTitle="Capítulo removido"
        isOpen={isSuccessRemoveChapterModalOpen}
        onRequestClose={() => setIsSuccessRemoveChapterModalOpen(false)}
      />

      <SuccessPublishArticleModal
        messageType="Confirmation"
        messageTitle="Publicado com sucesso"
        isOpen={isSuccessPublishArticleModalOpen}
        onRequestClose={() => setIsSuccessPublishArticleModalOpen(false)}
      />

      <FailPublishArticleModal
        messageType="Warning"
        messageTitle="Ih, não foi possível publicar este módulo"
        messageDescription="Confira se há campos obrigatórios de templates não preenchidos e tente novamente"
        isOpen={isFailPublishArticleModalOpen}
        onRequestClose={() => setIsFailPublishArticleModalOpen(false)}
      />

      <DataSheet isOpen={isDataSheetOpen} onRequestClose={() => setIsDataSheetOpen(false)} />

      <Container>
        <HeaderAndMenuBarContainer>
          <Header>
            <HeaderButtonsContainer>
              <Link
                to={isPublished() ? `/contents/${articleId}/datasheet` : '#'}
                state={{ currentArticleDraft }}
                onClick={
                  !isPublished()
                    ? () =>
                        alert(
                          'Esse conteúdo não tem nenhuma versão publicada, por isso os dados de ficha técnica não podem ser editados. Publique o conteúdo para habilitar essa função.'
                        )
                    : () => {
                        console.log('publicado')
                      }
                }
              >
                Especificações
              </Link>
              <Button
                onClick={() => setIsConfirmPublishModalOpen(true)}
                buttonStyle={{
                  border: '2px solid var(--blue-to-purple)',
                  background: 'var(--blue-to-purple)',
                  color: 'var(--white)',
                  width: 'auto',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  fontSize: '14px'
                }}
              >
                Publicar
              </Button>
            </HeaderButtonsContainer>

            {currentSavingStatus === 'saving' ? (
              <SavingStatusText>
                <ArrowRefresh color="var(--gray-60)" size={12} />
                <span>Salvando</span>
              </SavingStatusText>
            ) : currentSavingStatus === 'saved' ? (
              <SavingStatusText>
                <CheckCircle color="var(--gray-60)" size={12} />
                <span>Salvo!</span>
              </SavingStatusText>
            ) : (
              <SavingStatusText />
            )}
            <Title
              name="articleTitle"
              value={currentArticleName || ''}
              onChange={(event) => handleChangeArticleName(event)}
              onBlur={() => updateCurrentArticleName(articleId!, currentArticleName)}
            />
            {lastStatusAndSavingDate && <p>{lastStatusAndSavingDate}</p>}
          </Header>

          <MenuBarContainer>
            <div>
              <MainFunctionsMenu
                articleId={articleId!}
                isDisabled={currentTemplateName === ContentType.QUIZ}
                editor={editor}
              />
            </div>
          </MenuBarContainer>
        </HeaderAndMenuBarContainer>

        <ChaptersMenuAndEditorContainer>
          <ChaptersMenuContainer>
            <ChaptersMenu
              label="Lista de Capítulos"
              chapters={currentArticleDraft.chapters}
              currentChapterIndex={currentChapterIndex}
              articleType={currentArticleDraft.type}
              onEditChapterTitle={(index, newTitle) => updateChapterName(index, newTitle)}
              onAddChapter={(templateName, chapter) => addChapterToCurrentArticle(templateName, chapter)}
              onRemoveChapter={(index) => handleOpenConfirmRemoveChapterModal(index)}
              onSelectChapter={(index, contentType) => handleSelectChapter(index, contentType)}
              onChangeChaptersPositions={handleChangeChaptersPositions}
            />
          </ChaptersMenuContainer>

          <EditorContainer>
            {{
              chapter: <EditorContent editor={editor} />,
              quiz: <QuizTemplate quizId={currentChapter.quizId} />,
              Other: <EditorContent editor={editor} />
            }[currentTemplateName] || <EditorContent editor={editor} />}
          </EditorContainer>
        </ChaptersMenuAndEditorContainer>
      </Container>
    </>
  )
}
