import { InitializeMediaService, Media } from '@somostera/tera-database'
import { db } from 'core/config/firebase'

const mediaService = InitializeMediaService.initialize(db)

export const addMedia = async (media: Media) => {
  const response = await mediaService.save(media)
  return response
}

export const findMediaById = async (id: string) => {
  const Media = await mediaService.findById(id)
  return Media
}

export const findAllMedias = async () => {
  const Medias = await mediaService.findAll()
  return Medias
}

export const updateMedia = async (media: Media) => {
  await mediaService.update(media)
}
