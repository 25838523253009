import React from 'react'

export default function LeftIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00016 0.333313C3.31826 0.333313 0.333496 3.31808 0.333496 6.99998C0.333496 10.6819 3.31826 13.6666 7.00016 13.6666C10.6821 13.6666 13.6668 10.6819 13.6668 6.99998C13.6668 3.31808 10.6821 0.333313 7.00016 0.333313ZM8.00016 3.99998C8.00016 4.55226 7.55245 4.99998 7.00016 4.99998C6.44788 4.99998 6.00016 4.55226 6.00016 3.99998C6.00016 3.44769 6.44788 2.99998 7.00016 2.99998C7.55245 2.99998 8.00016 3.44769 8.00016 3.99998ZM7.66683 6.33331C7.66683 5.96512 7.36835 5.66665 7.00016 5.66665C6.63197 5.66665 6.3335 5.96512 6.3335 6.33331V10.3333C6.3335 10.7015 6.63197 11 7.00016 11C7.36835 11 7.66683 10.7015 7.66683 10.3333V6.33331Z"
        fill="currentColor"
      />
    </svg>
  )
}
