import styled from 'styled-components'

export const Label = styled.label<StyledRadioProps>`
  align-items: center;
  background: ${(props) => (props.checked ? 'var(--blue-primary)' : 'var(--white)')};
  border: 0.125rem solid ${(props) => (props.checked ? 'var(--blue-primary)' : 'var(--gray-60)')};
  border-radius: 2rem;
  color: ${(props) => (props.checked ? 'var(--white)' : 'var(--gray-60)')};
  cursor: pointer;
  display: flex;
  height: 2rem;
  justify-content: center;
  width: 8.5rem;
`

interface StyledRadioProps {
  checked?: boolean
}

export const HiddenRadio = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  background: aqua;
`
