import styled from 'styled-components'

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  border-bottom: 1px solid var(--gray-20);
  padding-bottom: 2rem;
  margin-bottom: 2rem;

  > div {
    > span {
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      font-size: 0.8rem;
      display: block;
    }
    > h1 {
      text-transform: uppercase;
    }
  }
  > section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`

export const SubHeaderContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  margin-bottom: 3rem;
`
export const FilterTable = styled.div`
  width: 40rem;
  position: absolute;
`

export const BlueWarningModalContainer = styled.div`
  width: 100%;
  margin: 1.5rem 0rem -2.6rem;
`

export const GreenWarningModalContainer = styled.div`
  width: calc(100% - 16rem);
  position: fixed;
  z-index: 4;
`

export const RedWarningModal = styled.div`
  > datalist {
    > option {
      background-color: red;
    }
  }
`

export const AddNewBuildingBlockButton = styled.button`
  align-items: center;
  background: var(--blue-primary);
  border-radius: 0.5rem;
  color: var(--white);
  display: flex;
  font-size: 0.875rem;
  font-weight: bold;
  height: 2.25rem;
  /* justify-content: space-between; */
  line-height: 120%;
  margin: 0 8px;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  width: 12rem;

  svg {
    margin-right: 1rem;
  }
  p {
    margin-right: 0rem;
  }
`

export const Container = styled.div`
  margin: 2rem auto;
  width: 90%;
  height: 130%;
  position: relative;
`

export const MoreFiltersContainer = styled.div`
  background: var(--white);
  border: 1px solid var(--gray-20);
  border-radius: 0.25rem;
  box-shadow: 0.1875rem 0.1875rem 0.5rem rgba(0, 0, 0, 0.16);
  padding: 1rem;
  position: absolute;
  width: 13.125rem;
  height: 35rem;

  h3 {
    color: var(--gray-60);
    font-weight: bold;
    font-size: 1rem;
    line-height: 1rem;
    margin: 2rem 0 1rem;
    text-transform: uppercase;

    &:first-of-type {
      margin-top: 0;
    }
  }

  > div {
    margin: 2rem 0 1rem 0;

    &:first-of-type {
      margin-top: 0;
    }

    label {
      margin: 1rem 0;
    }
  }
`
export const LoadingContainer = styled.div`
  width: 100%;
  height: 90%;
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  z-index: 4;
`
export const MoreFiltersContainerNewBuilding = styled.div`
  background: var(--white);
  border: 1px solid var(--gray-20);
  border-radius: 0.25rem;
  box-shadow: 0.1875rem 0.1875rem 0.5rem rgba(0, 0, 0, 0.16);
  padding: 1rem;
  position: absolute;
  width: 16.125rem;
  height: 12rem;
  margin-top: 15rem;
  margin-left: -5rem;

  label:hover {
    background: var(--gray-);
  }

  h3 {
    color: var(--gray-60);
    font-weight: bold;
    font-size: 1rem;
    line-height: 1rem;
    margin: 2rem 0 1rem;
    text-transform: uppercase;

    &:first-of-type {
      margin-top: 0;
    }
  }

  > div {
    margin: 2rem 0 1rem 0;

    &:first-of-type {
      margin-top: 0;
    }

    label {
      margin: 1rem 0;
    }
  }
`
export const MenuTypeContainer = styled.button`
  z-index: 8;
  position: absolute;
  margin-top: 2.5rem;
`
