import axios from 'axios'

const getUrl = `${process.env.REACT_APP_TERA_API}/peoples`

export abstract class PeopleService {
  static async getAll(token: string, role: string) {
    return await axios.get(`${getUrl}?role=${role}`, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }
}
