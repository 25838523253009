import { initializeApp } from 'firebase/app'
import type { FirebaseOptions } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const authConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || '',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || ''
}

const firebaseApp = initializeApp(authConfig)
const db = getFirestore()

export { authConfig, firebaseApp, db }
