import React, { FC } from 'react'

import { Sidebar } from 'core/components/Sidebar'
import { Header } from 'core/components/Header'
import { useGreenModal } from 'core/hooks/useGreenModal'
import { GreenWarningModalationModal } from 'core/components/GreenWarningModal'

import { PrivateRouteContainer } from './styles'
import { Breadcrumbs } from 'core/components/Breadcrumbs'

export const PrivateRouteComponent: FC = ({ children }) => {
  const { isGreenModalText, closeGreenModal, isConfirmationGreenWarningModalOpen } = useGreenModal()
  return (
    <PrivateRouteContainer>
      <Sidebar />
      <div>
        <Breadcrumbs />
        {isConfirmationGreenWarningModalOpen && (
          <GreenWarningModalationModal text={isGreenModalText} onRequestClose={closeGreenModal} />
        )}
        {children}
      </div>
    </PrivateRouteContainer>
  )
}
