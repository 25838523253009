import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useImmerReducer } from 'use-immer'

import { PeopleNetworkService } from '../services/people'

import { networkReducer, Sections, State } from '../reducers/network'
import {
  AddressFormValues,
  ContactInformationFormValues,
  ContactVerificationFormValues,
  FinancialFormValues,
  NetworkData,
  PersonalDataFormValues,
  PersonData,
  ProfessionalDataFormValues,
  StatusAndAvailabilityFormValues
} from '../@types/network'

import {
  Article,
  ArticleDraft,
  AvailabilityEvent,
  AvailabilityPeriod,
  AvailableDaysOfWeek,
  GenericRole,
  PeopleRoleStatus,
  PeopleRoleTypes,
  Person,
  Segments
} from '@somostera/tera-database'

import { OrderByName } from 'core/utils/object'

import { PersonalDataComponent } from '../views/PersonProfile/PersonalData'
import { ProfessionalDataComponent } from '../views/PersonProfile/ProfessionalData'
import { ContactInformationComponent } from '../views/PersonProfile/ContactInformation'
import { AddressComponent } from '../views/PersonProfile/Address'
import { StatusAndAvailabilityComponent } from '../views/PersonProfile/StatusAndAvailability'
import { Tier } from '@somostera/tera-database/dist/Tiers/Domain'
import { TierService } from '../services/tiers'
// import { Keynote } from '@somostera/tera-database/dist/People/Domain/types/Keynote'
import { useAuth } from 'core/hooks/useAuth'
import { ArticlesService } from '../services/articles'

interface NetworkContextData {
  isLoading: boolean
  isFirstPage: boolean
  isLastPage: boolean
  networkTablePeople: NetworkData[]
  networkPersonSelected: NetworkData
  name: string
  setName: Dispatch<SetStateAction<string>>
  email: string
  setEmail: Dispatch<SetStateAction<string>>
  id: string
  setId: Dispatch<SetStateAction<string>>
  isSuccessSaveModalOpen: boolean
  setIsSuccessSaveModalOpen: Dispatch<SetStateAction<boolean>>
  personProfileSections: Sections
  getPersonByEmail: (email: string) => Promise<NetworkData | undefined>
  getPersonById: (id: string) => Promise<PersonData | Person | undefined>
  personAddress: AddressFormValues
  updatePersonAddress: (data: AddressFormValues) => Promise<void>
  updatePersonPhoto: (photo: string) => Promise<void>
  personContactInformation: ContactInformationFormValues
  updatePersonContactInformation: (data: ContactInformationFormValues) => Promise<void>
  personPersonalData: PersonalDataFormValues
  updatePersonPersonalData: (data: PersonalDataFormValues) => Promise<void>
  personProfessionalData: ProfessionalDataFormValues
  updatePersonProfessionalData: (data: ProfessionalDataFormValues) => Promise<void>
  personStatusAndAvailability: StatusAndAvailabilityFormValues
  updatePersonStatusAndAvailability: (data: StatusAndAvailabilityFormValues) => Promise<void>
  personFinancialData: FinancialFormValues
  setIsLoading: (condition: boolean) => void
  isNewExpert: boolean
  setIsNewExpert: Dispatch<SetStateAction<boolean>>
  canShowDialogLeavingPage: boolean
  setCanShowDialogLeavingPage: Dispatch<SetStateAction<boolean>>
  findAllPeople: () => Promise<void>
  isErrorSaveModalOpen: boolean
  setIsErrorSaveModalOpen: Dispatch<SetStateAction<boolean>>
  clearSectionFilled: () => void
  clearDataAndSection: (data: ContactVerificationFormValues) => void
  accordionSectionOpen: string[]
  setAccordionSectionOpen: Dispatch<SetStateAction<string[]>>
  findAllTiers: () => void
  findAllArticles: (filterText: string) => ArticleDraft[] | any[] | []
  dataTiers: Tier[] | []
  updateKeyNote: (data: FinancialFormValues) => Promise<void>
  isFinancialUser: boolean
}

interface NetworkProviderProps {
  children: ReactNode
}

const NetworkContext = createContext<NetworkContextData>({} as NetworkContextData)

export const NetworkProvider = ({ children }: NetworkProviderProps) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [id, setId] = useState('')
  const [isSuccessSaveModalOpen, setIsSuccessSaveModalOpen] = useState(false)
  const [isErrorSaveModalOpen, setIsErrorSaveModalOpen] = useState(false)
  const [isFinancialUser, setIsFinancialUser] = useState(false)
  const [isNewExpert, setIsNewExpert] = useState(false)
  const [person, setPerson] = useState<Person>()
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false)
  const [accordionSectionOpen, setAccordionSectionOpen] = useState<string[]>([])
  const [dataTiers, setDataTiers] = useState<Tier[]>([])

  const { userFirebase } = useAuth()

  if (typeof userFirebase.getIdTokenResult === 'function') {
    userFirebase.getIdTokenResult().then((idTokenResult) => {
      setIsFinancialUser(!!idTokenResult.claims.financeiro)
    })
  }

  const [
    {
      isLoading,
      isFirstPage,
      isLastPage,
      networkTablePeople,
      networkPersonSelected,
      personData,
      personProfileSections
    },
    setState
  ] = useImmerReducer(networkReducer, {
    personProfileSections: {
      personalData: {
        title: 'Dados pessoais',
        filled: false,
        idSection: '0',
        component: <PersonalDataComponent />
      },
      professionalData: {
        title: 'Dados Profissionais',
        filled: false,
        idSection: '1',
        component: <ProfessionalDataComponent />
      },
      contactInformation: {
        title: 'Informações para contato',
        filled: false,
        idSection: '2',
        component: <ContactInformationComponent />
      },
      address: {
        title: 'Endereço',
        filled: false,
        idSection: '3',
        component: <AddressComponent />
      },
      statusAndAvailability: {
        title: 'Status & Disponibilidade',
        filled: false,
        idSection: '4',
        component: <StatusAndAvailabilityComponent />
      }
    },
    isLoading: true,
    networkTablePeople: [] as NetworkData[],
    networkPersonSelected: {} as NetworkData,
    personData: {} as PersonData,
    currentPage: 0,
    isFirstPage: true,
    isLastPage: false,
    person: {} as Person
  } as State)

  const findAllPeople = async () => {
    setState({ type: 'ASYNC_CALL' })
    const page = OrderByName(await PeopleNetworkService.findAll())

    setState({ type: 'SET_NETWORK_TABLE_PEOPLE', payload: { people: page } })

    setState({ type: 'ASYNC_CALL_SUCCEED' })
  }

  const getPersonByEmail = useCallback(
    async (email: string) => {
      setState({ type: 'ASYNC_CALL' })

      const foundPerson = await PeopleNetworkService.findByEmail(email)

      foundPerson !== undefined ? (foundPerson.email = email) : ''
      setState({ type: 'SET_NETWORK_PERSON_SELECTED', payload: { person: foundPerson } })

      setState({ type: 'ASYNC_CALL_SUCCEED' })
      return foundPerson
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [networkTablePeople]
  )

  const getPersonById = useCallback(
    async (id: string) => {
      setState({ type: 'ASYNC_CALL' })

      const responsePerson = await PeopleNetworkService.findPersonDataById(id)

      const foundPersonData = responsePerson[0] as PersonData
      const foundPerson = responsePerson[1] as Person

      setPerson(foundPerson)

      setState({ type: 'SET_ADDRESS_DATA', payload: { data: foundPersonData.address } })
      setState({ type: 'SET_CONTACT_INFORMATION_DATA', payload: { data: foundPersonData.contactInformation } })
      setState({ type: 'SET_PERSONAL_DATA', payload: { data: foundPersonData.personalData } })
      setState({ type: 'SET_PROFESSIONAL_DATA', payload: { data: foundPersonData.professionalData } })
      setState({ type: 'SET_STATUS_AND_AVAILABILITY_DATA', payload: { data: foundPersonData.statusAndAvailability } })
      setState({ type: 'SET_FINANCIAL_DATA', payload: { data: foundPersonData.financial } })

      // update section state
      setState({
        type: 'UPDATE_PERSON_PROFILE_SECTION_FILLED_STATE',
        payload: {
          section: 'personalData',
          state:
            foundPersonData.personalData.name !== '' &&
            foundPersonData.personalData.gender !== '' &&
            foundPersonData.personalData.ethnicity !== ''
        }
      })
      setState({
        type: 'UPDATE_PERSON_PROFILE_SECTION_FILLED_STATE',
        payload: {
          section: 'professionalData',
          state:
            foundPersonData.professionalData.linkedin !== '' &&
            foundPersonData.professionalData.company !== '' &&
            foundPersonData.professionalData.jobTitle !== '' &&
            foundPersonData.professionalData.segmentOfActivity !== ''
        }
      })
      setState({
        type: 'UPDATE_PERSON_PROFILE_SECTION_FILLED_STATE',
        payload: { section: 'contactInformation', state: foundPersonData.contactInformation.email !== '' }
      })

      setState({
        type: 'UPDATE_PERSON_PROFILE_SECTION_FILLED_STATE',
        payload: { section: 'address', state: foundPersonData.address.zipCode !== '' }
      })

      setState({
        type: 'UPDATE_PERSON_PROFILE_SECTION_FILLED_STATE',
        payload: {
          section: 'statusAndAvailability',
          state:
            foundPersonData.statusAndAvailability.status !== '' &&
            foundPersonData.statusAndAvailability.roles.length !== 0
        }
      })
      setAccordionSectionOpen([])

      setState({ type: 'ASYNC_CALL_SUCCEED' })
      return foundPerson
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  )

  const setIsLoading = (condition: boolean) => {
    if (condition) {
      setState({ type: 'ASYNC_CALL' })
    } else {
      setState({ type: 'ASYNC_CALL_SUCCEED' })
    }
  }

  const clearDataAndSection = (data: ContactVerificationFormValues) => {
    setState({ type: 'SET_ADDRESS_DATA', payload: { data: {} as AddressFormValues } })
    setState({ type: 'SET_CONTACT_INFORMATION_DATA', payload: { data: {} as ContactInformationFormValues } })
    setState({ type: 'SET_PERSONAL_DATA', payload: { data: {} as PersonalDataFormValues } })
    setState({ type: 'SET_PROFESSIONAL_DATA', payload: { data: {} as ProfessionalDataFormValues } })
    setState({ type: 'SET_STATUS_AND_AVAILABILITY_DATA', payload: { data: {} as StatusAndAvailabilityFormValues } })

    setName(data.name)
    setEmail(data.email)

    setPerson(undefined)
    setIsNewExpert(true)
    setAccordionSectionOpen([])
    clearSectionFilled()
  }

  const findAllTiers = async () => {
    setState({ type: 'ASYNC_CALL' })
    const tiers = await TierService.findAll()
    setDataTiers(tiers)

    setState({ type: 'ASYNC_CALL_SUCCEED' })
  }

  const findAllArticles = async (filterText: string) => {
    setState({ type: 'ASYNC_CALL' })

    const token = await userFirebase.getIdToken()
    const response = await ArticlesService.getAll(filterText, token)
    const responseDraft = await ArticlesService.getAllDraft(filterText, token)

    setState({ type: 'ASYNC_CALL_SUCCEED' })

    return responseDraft.data?.hits?.hits?.map((hit: any) => {
      try {
        const article = response.data?.hits?.hits?.find((item: any) => item._source.id === hit._source.id)
        if (article._source.updatedat > hit?._source.updatedat) {
          return article._source as Article
        } else {
          hit._source.participantsids = article._source.participantsids
          return hit?._source as ArticleDraft
        }
      } catch (e) {
        return hit?._source as ArticleDraft
      }
    })
  }

  useEffect(() => {
    if (dataTiers && dataTiers.length > 0) {
      fillFormFinancial()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTiers])

  const fillFormFinancial = async () => {
    const dataFinancial = { ...personData.financial }

    dataTiers?.forEach((tier) => {
      if (tier.id === person?.tierId) {
        dataFinancial.type = tier.id !== undefined ? tier.id : 'default'
        dataFinancial.classHourValueInValidation = tier.classCreation !== undefined ? tier.classCreation.toString() : ''
        dataFinancial.classHourValueValidated = tier.standardClass !== undefined ? tier.standardClass.toString() : ''
        dataFinancial.mentoryHourValue = tier.mentoring !== undefined ? tier.mentoring.toString() : ''
        dataFinancial.canBeManuallySelected = tier?.canBeManuallySelected
      }
    })
    setState({ type: 'SET_FINANCIAL_DATA', payload: { data: dataFinancial } })
  }

  const clearSectionFilled = () => {
    setState({
      type: 'UPDATE_PERSON_PROFILE_SECTION_FILLED_STATE',
      payload: {
        section: 'personalData',
        state: false
      }
    })
    setState({
      type: 'UPDATE_PERSON_PROFILE_SECTION_FILLED_STATE',
      payload: {
        section: 'professionalData',
        state: false
      }
    })
    setState({
      type: 'UPDATE_PERSON_PROFILE_SECTION_FILLED_STATE',
      payload: { section: 'contactInformation', state: false }
    })

    setState({
      type: 'UPDATE_PERSON_PROFILE_SECTION_FILLED_STATE',
      payload: { section: 'address', state: false }
    })

    setState({
      type: 'UPDATE_PERSON_PROFILE_SECTION_FILLED_STATE',
      payload: { section: 'statusAndAvailability', state: false }
    })
  }

  const createExpert = async (data: StatusAndAvailabilityFormValues) => {
    try {
      setState({ type: 'ASYNC_CALL' })

      // update person
      const updatedPerson = { ...person } as Person

      const updatePeopleRoles =
        updatedPerson.peopleRoles !== undefined ? updatedPerson.peopleRoles : ({} as GenericRole)

      const updateAvailability =
        updatedPerson.availability !== undefined ? updatedPerson.availability : ({} as AvailabilityEvent)

      // Header
      updatedPerson.pictureUrl = personData.personalData.profilePicture

      // Personal Data
      updatedPerson.name = personData.personalData.name
      updatedPerson.description = personData.personalData.miniBio
      updatePeopleRoles.birthDate =
        personData.personalData.birthday !== '' && personData.personalData.birthday !== undefined
          ? new Date(
              new Date(personData.personalData.birthday).getTime() +
                new Date(personData.personalData.birthday).getTimezoneOffset() * 60000
            )
          : undefined
      updatePeopleRoles.gender = personData.personalData.gender
      updatePeopleRoles.ethnicity = personData.personalData.ethnicity
      updatePeopleRoles.pcd = personData.personalData.hasDisability === 'yes'

      // Professional Data
      updatedPerson.profileUrl = personData.professionalData.linkedin
      updatedPerson.jobTitle = personData.professionalData.jobTitle
      updatedPerson.company = personData.professionalData.company

      updatePeopleRoles.segment = personData.professionalData.segmentOfActivity as Segments
      updatePeopleRoles.experience = personData.professionalData.hadExperienceAsMentorOrTeacher === 'yes'

      // Contact Information
      updatePeopleRoles.country = personData.contactInformation.country
      updatePeopleRoles.phone = personData.contactInformation.phoneNumber
      updatePeopleRoles.email = personData.contactInformation.email

      // Address
      updatePeopleRoles.zipcode = personData.address.zipCode
      updatePeopleRoles.street = personData.address.street
      updatePeopleRoles.numberHouse = personData.address.number
      updatePeopleRoles.complement = personData.address.complement
      updatePeopleRoles.city = personData.address.city
      updatePeopleRoles.state = personData.address.state

      // Status and Availability
      updateAvailability.status = data.status as PeopleRoleStatus

      const personRoles: PeopleRoleTypes[] = []
      data.roles.forEach((role) => {
        personRoles.push(role as PeopleRoleTypes)
      })
      updatedPerson.roles = personRoles

      const daysWeek: AvailableDaysOfWeek[] = []
      data.daysAvailable.forEach((days) => {
        daysWeek.push(days as AvailableDaysOfWeek)
      })
      updateAvailability.availabilityDaysWeek = daysWeek

      const periodsAvailable: AvailabilityPeriod[] = []
      data.periodsAvailable.forEach((period) => {
        periodsAvailable.push(period as AvailabilityPeriod)
      })

      updateAvailability.availabilityPeriod = periodsAvailable

      updateAvailability.classLimit = data.numberOfClasses
      updateAvailability.observation = data.comments

      // update DB
      const response = await PeopleNetworkService.create(
        email,
        updatePeopleRoles,
        updatedPerson,
        await userFirebase.getIdToken()
      )
      await PeopleNetworkService.createRoles(response.data, updatePeopleRoles)
      await PeopleNetworkService.createAvailability(response.data, updateAvailability)

      // clear data
      setName('')
      setEmail('')
      setState({ type: 'ASYNC_CALL_SUCCEED' })
      setIsSuccessSaveModalOpen(true)
    } catch (error) {
      console.log(error)
      setIsErrorSaveModalOpen(true)
    }
  }

  const updatePersonPhoto = async (photo: string) => {
    try {
      // update form
      // setState({ type: 'SET_NEW_PHOTO', payload: { photo } })

      // update Person
      const updatedPerson = { ...person, pictureUrl: photo } as Person

      await PeopleNetworkService.update(updatedPerson)

      setPerson(updatedPerson)
      setIsSuccessSaveModalOpen(true)
    } catch (error) {
      console.log(error)
      setIsErrorSaveModalOpen(true)
    }
  }

  const updatePersonAddress = async (data: AddressFormValues) => {
    try {
      setState({ type: 'ASYNC_CALL' })
      // update form
      setState({ type: 'SET_ADDRESS_DATA', payload: { data } })
      setState({
        type: 'UPDATE_PERSON_PROFILE_SECTION_FILLED_STATE',
        payload: {
          section: 'address',
          state:
            data.zipCode !== undefined &&
            data.city !== undefined &&
            data.complement !== undefined &&
            data.state !== undefined &&
            data.street !== undefined &&
            data.number !== undefined
        }
      })

      if (isNewExpert) {
        setAccordionSectionOpen(['4'])
        setState({ type: 'ASYNC_CALL_SUCCEED' })
      } else {
        // update Person
        const updatedPerson = { ...person } as Person

        const updatePeopleRoles =
          updatedPerson.peopleRoles !== undefined ? updatedPerson.peopleRoles : ({} as GenericRole)

        updatePeopleRoles.zipcode = data.zipCode
        updatePeopleRoles.street = data.street
        updatePeopleRoles.numberHouse = data.number
        updatePeopleRoles.complement = data.complement
        updatePeopleRoles.city = data.city
        updatePeopleRoles.state = data.state

        // update DB
        if (updatedPerson.id) {
          updatedPerson.peopleRoles !== undefined
            ? await PeopleNetworkService.updateRoles(updatedPerson.id, updatePeopleRoles)
            : PeopleNetworkService.createRoles(updatedPerson.id, updatePeopleRoles)

          await PeopleNetworkService.update(updatedPerson)
        }
        setPerson(updatedPerson)
        setState({ type: 'ASYNC_CALL_SUCCEED' })
        setIsSuccessSaveModalOpen(true)
      }
    } catch (error) {
      console.log(error)
      setIsErrorSaveModalOpen(true)
    }
  }

  const updatePersonContactInformation = async (data: ContactInformationFormValues) => {
    try {
      setState({ type: 'ASYNC_CALL' })

      // update form
      setState({ type: 'SET_CONTACT_INFORMATION_DATA', payload: { data } })
      setState({
        type: 'UPDATE_PERSON_PROFILE_SECTION_FILLED_STATE',
        payload: {
          section: 'contactInformation',
          state: data.country !== '' && data.email !== '' && data.phoneNumber !== ''
        }
      })

      if (isNewExpert) {
        setAccordionSectionOpen(['3'])
        setState({ type: 'ASYNC_CALL_SUCCEED' })
      } else {
        // update person
        const updatedPerson = { ...person } as Person

        const updatePeopleRoles =
          updatedPerson.peopleRoles !== undefined ? updatedPerson.peopleRoles : ({} as GenericRole)

        updatePeopleRoles.country = data.country
        updatePeopleRoles.phone = data.phoneNumber
        updatePeopleRoles.email = data.email

        // update DB
        if (updatedPerson.id) {
          updatedPerson.peopleRoles !== undefined
            ? await PeopleNetworkService.updateRoles(updatedPerson.id, updatePeopleRoles)
            : PeopleNetworkService.createRoles(updatedPerson.id, updatePeopleRoles)

          await PeopleNetworkService.update(updatedPerson)
        }
        setPerson(updatedPerson)
        setState({ type: 'ASYNC_CALL_SUCCEED' })
        setIsSuccessSaveModalOpen(true)
      }
    } catch (error) {
      console.log(error)
      setIsErrorSaveModalOpen(true)
    }
  }

  const updatePersonPersonalData = async (data: PersonalDataFormValues) => {
    try {
      setState({ type: 'ASYNC_CALL' })

      // update form
      setState({ type: 'SET_PERSONAL_DATA', payload: { data } })
      setState({
        type: 'UPDATE_PERSON_PROFILE_SECTION_FILLED_STATE',
        payload: { section: 'personalData', state: true }
      })

      if (isNewExpert) {
        setAccordionSectionOpen(['1'])
        setState({ type: 'ASYNC_CALL_SUCCEED' })
      } else {
        // update person
        const updatedPerson = { ...person } as Person

        const updatePeopleRoles =
          updatedPerson.peopleRoles !== undefined ? updatedPerson.peopleRoles : ({} as GenericRole)

        updatedPerson.name = data.name
        updatedPerson.description = data.miniBio
        updatedPerson.pictureUrl = data.profilePicture
        updatePeopleRoles.birthDate =
          data.birthday !== ''
            ? new Date(new Date(data.birthday).getTime() + new Date(data.birthday).getTimezoneOffset() * 60000)
            : undefined
        updatePeopleRoles.gender = data.gender
        updatePeopleRoles.ethnicity = data.ethnicity
        updatePeopleRoles.pcd = data.hasDisability === 'yes'

        // update DB
        updatedPerson.peopleRoles !== undefined
          ? await PeopleNetworkService.updateRoles(updatedPerson.id, updatePeopleRoles)
          : PeopleNetworkService.createRoles(updatedPerson.id, updatePeopleRoles)

        await PeopleNetworkService.update(updatedPerson)

        setPerson(updatedPerson)

        setState({ type: 'ASYNC_CALL_SUCCEED' })
        setIsSuccessSaveModalOpen(true)
      }
    } catch (error) {
      console.log(error)
      setIsErrorSaveModalOpen(true)
    }
  }

  const updatePersonProfessionalData = async (data: ProfessionalDataFormValues) => {
    try {
      setState({ type: 'ASYNC_CALL' })

      // update form
      setState({ type: 'SET_PROFESSIONAL_DATA', payload: { data } })
      setState({
        type: 'UPDATE_PERSON_PROFILE_SECTION_FILLED_STATE',
        payload: { section: 'professionalData', state: true }
      })

      if (isNewExpert) {
        setAccordionSectionOpen(['2'])
        setState({ type: 'ASYNC_CALL_SUCCEED' })
      } else {
        // update person
        const updatedPerson = { ...person } as Person

        const updatePeopleRoles =
          updatedPerson.peopleRoles !== undefined ? updatedPerson.peopleRoles : ({} as GenericRole)

        updatedPerson.profileUrl = data.linkedin
        updatedPerson.jobTitle = data.jobTitle
        updatedPerson.company = data.company

        updatePeopleRoles.segment = data.segmentOfActivity as Segments
        updatePeopleRoles.experience = data.hadExperienceAsMentorOrTeacher === 'yes'

        // update DB
        updatedPerson.peopleRoles !== undefined
          ? await PeopleNetworkService.updateRoles(updatedPerson.id, updatePeopleRoles)
          : PeopleNetworkService.createRoles(updatedPerson.id, updatePeopleRoles)

        await PeopleNetworkService.update(updatedPerson)
        setPerson(updatedPerson)

        setState({ type: 'ASYNC_CALL_SUCCEED' })
        setIsSuccessSaveModalOpen(true)
      }
    } catch (error) {
      console.log(error)
      setIsErrorSaveModalOpen(true)
    }
  }

  const updatePersonStatusAndAvailability = async (data: StatusAndAvailabilityFormValues) => {
    try {
      setState({ type: 'ASYNC_CALL' })

      // update form
      setState({ type: 'SET_STATUS_AND_AVAILABILITY_DATA', payload: { data } })
      setState({
        type: 'UPDATE_PERSON_PROFILE_SECTION_FILLED_STATE',
        payload: { section: 'statusAndAvailability', state: true }
      })

      if (isNewExpert) {
        setState({ type: 'ASYNC_CALL_SUCCEED' })
        await createExpert(data)
      } else {
        // update person
        const updatedPerson = { ...person } as Person

        const updateAvailability =
          updatedPerson.availability !== undefined ? updatedPerson.availability : ({} as AvailabilityEvent)

        updateAvailability.status = data.status as PeopleRoleStatus

        const personRoles: PeopleRoleTypes[] = []
        data.roles.forEach((role) => {
          personRoles.push(role as PeopleRoleTypes)
        })
        updatedPerson.roles = personRoles

        const daysWeek: AvailableDaysOfWeek[] = []
        data.daysAvailable.forEach((days) => {
          daysWeek.push(days as AvailableDaysOfWeek)
        })
        updateAvailability.availabilityDaysWeek = daysWeek

        const periodsAvailable: AvailabilityPeriod[] = []
        data.periodsAvailable.forEach((period) => {
          periodsAvailable.push(period as AvailabilityPeriod)
        })

        updateAvailability.availabilityPeriod = periodsAvailable
        updateAvailability.classLimit = data.numberOfClasses
        updateAvailability.observation = data.comments

        // update DB
        updatedPerson.availability !== undefined
          ? await PeopleNetworkService.updateAvailability(updatedPerson.id, updateAvailability)
          : PeopleNetworkService.createAvailability(updatedPerson.id, updateAvailability)

        await PeopleNetworkService.update(updatedPerson)
        setPerson(updatedPerson)
        setState({ type: 'ASYNC_CALL_SUCCEED' })
        setIsSuccessSaveModalOpen(true)
      }
    } catch (error) {
      console.log(error)
      setIsErrorSaveModalOpen(true)
    }
  }

  const updateKeyNote = async (data: FinancialFormValues) => {
    try {
      setState({ type: 'ASYNC_CALL' })
      // update form
      setState({ type: 'SET_FINANCIAL_DATA', payload: { data } })

      // const newKeynote: Keynote = {
      //   id: data.type,
      //   classCreation: parseInt(data.totalHours),
      //   standardClass: parseInt(data.classHourValueValidated),
      //   classRecording: parseInt(data.classHourValueInValidation),
      //   mentoring: parseInt(data.mentoryHourValue),
      //   createdAt: new Date(),
      //   updatedAt: new Date()
      // }

      const updatedPersonTier: Person = {
        ...person,
        tierId: data.type
      } as Person

      await PeopleNetworkService.update(updatedPersonTier)
      setPerson(updatedPersonTier)

      // const updatedPersonKeynote: Person = {
      //   ...person,
      //   keynote: newKeynote
      // } as Person

      // const isKeynote = !(data?.canBeManuallySelected === false)

      // if (isKeynote) {
      //   await PeopleNetworkService.updateKeynote(updatedPersonKeynote.id, newKeynote)
      //   setPerson(updatedPersonKeynote)
      // } else {
      //   await PeopleNetworkService.update(updatedPersonTier)
      //   setPerson(updatedPersonTier)
      // }

      setState({ type: 'ASYNC_CALL_SUCCEED' })
      setIsSuccessSaveModalOpen(true)
    } catch (error) {
      console.log(error)
      setState({ type: 'ASYNC_CALL_FAILED', payload: { error: String(error) } })
      setIsErrorSaveModalOpen(true)
    }
  }

  return (
    <NetworkContext.Provider
      value={{
        isLoading,
        isFirstPage,
        isLastPage,
        networkTablePeople,
        networkPersonSelected,
        name,
        setName,
        email,
        setEmail,
        id,
        setId,
        personProfileSections,
        getPersonByEmail,
        getPersonById,
        personAddress: personData.address,
        updatePersonAddress,
        updatePersonPhoto,
        personContactInformation: personData.contactInformation,
        updatePersonContactInformation,
        personPersonalData: personData.personalData,
        updatePersonPersonalData,
        personProfessionalData: personData.professionalData,
        updatePersonProfessionalData,
        personStatusAndAvailability: personData.statusAndAvailability,
        updatePersonStatusAndAvailability,
        personFinancialData: personData.financial,
        setIsLoading,
        isSuccessSaveModalOpen,
        setIsSuccessSaveModalOpen,
        isNewExpert,
        setIsNewExpert,
        canShowDialogLeavingPage,
        setCanShowDialogLeavingPage,
        findAllPeople,
        isErrorSaveModalOpen,
        setIsErrorSaveModalOpen,
        clearSectionFilled,
        clearDataAndSection,
        accordionSectionOpen,
        setAccordionSectionOpen,
        findAllTiers,
        findAllArticles,
        dataTiers,
        updateKeyNote,
        isFinancialUser
      }}
    >
      {children}
    </NetworkContext.Provider>
  )
}

export const useNetwork = (): NetworkContextData => {
  return useContext(NetworkContext)
}
