import React from 'react'
import { ChevronLeft } from '@somostera/tera-icons'
import { useNavigate, useLocation, NavLink, useParams } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { Container, SpanLink } from './styles'
import { usePortfolio } from 'modules/network/hooks/usePortfolio'
import { useNetwork } from 'modules/network/hooks/useNetwork'
import { useContent } from 'modules/contents/hooks/useContent'
import { useCourse } from 'modules/courses/hooks/useCourse'

const CustomPropsBreadcrumb = ({ someProp }: any) => <span>{someProp}</span>

export const Breadcrumbs = () => {
  const { pathname } = useLocation()
  const { personPersonalData } = useNetwork()
  const { currentPortfolioClass } = usePortfolio()
  const { currentArticleName } = useContent()
  const { currentCourseName } = useCourse()

  const { teraId, personId } = useParams()

  const routes = [
    { path: '/casting', breadcrumb: 'Casting' },
    {
      path: '/casting/:journeyId/:teraId',
      breadcrumb: CustomPropsBreadcrumb,
      props: {
        someProp: teraId === undefined ? 'Nome da Turma' : teraId
      }
    },
    { path: '/network', breadcrumb: 'Rede' },
    {
      path: '/network/person/add/verification',
      breadcrumb: 'Cadastrar nova pessoa'
    },
    { path: '/media', breadcrumb: 'Mídias' },
    { path: '/contents', breadcrumb: 'Conteúdo' },
    {
      path: '/contents/new',
      breadcrumb: CustomPropsBreadcrumb,
      props: {
        someProp: personPersonalData === undefined ? 'Adicionar novo artigo' : personPersonalData.name
      }
    },
    {
      path: '/contents/:articleId',
      breadcrumb: CustomPropsBreadcrumb,
      props: {
        someProp: currentArticleName === undefined ? 'Título do Artigo' : currentArticleName
      }
    },
    {
      path: '/contents/:articleId/datasheet',
      breadcrumb: CustomPropsBreadcrumb,
      props: {
        someProp: currentArticleName === undefined ? 'Título do Artigo' : `Ficha técnica`
      }
    },
    {
      path: '/network/person/:personId',
      breadcrumb: CustomPropsBreadcrumb,
      props: {
        someProp:
          personPersonalData === undefined || personId === undefined ? 'Cadastrar nova pessoa' : personPersonalData.name
      }
    },
    {
      path: '/network/person/:personId/portfolio/:id',
      breadcrumb: CustomPropsBreadcrumb,
      props: {
        someProp: currentPortfolioClass === undefined ? 'Nome da aula' : currentPortfolioClass.name
      }
    },
    {
      path: '/network/person/:personId/portfolio/add',
      breadcrumb: 'Adicionar nova aula'
    },
    { path: '/courses', breadcrumb: 'Cursos' },
    {
      path: '/courses/:courseId',
      breadcrumb: CustomPropsBreadcrumb,
      props: {
        someProp: currentCourseName === undefined ? 'Nome do curso' : currentCourseName
      }
    }
  ]
  const navigate = useNavigate()
  const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true })
  const excludePaths = [
    '/home',
    '/casting',
    '/network',
    '/media',
    '/contents',
    '/contents/:articleId/:chapterIndex/preview',
    '/courses',
    '/courses/new',
    '/formations',
    '/lives',
    '/lives/new',
    '/lives/new/schedule',
    '/lives/:liveId/new/schedule'
  ]
  const hasBreadcrumbsExclude = excludePaths.some((route) => route === pathname)
  if (hasBreadcrumbsExclude) {
    return null
  }

  return (
    <>
      <Container>
        <button type="button" onClick={() => navigate(-1)}>
          <ChevronLeft color="#000000" size={20} />
        </button>
        {breadcrumbs.map(({ match, breadcrumb }, i) => (
          <SpanLink key={match.pathname} isLastItem={i === breadcrumbs.length - 1}>
            {i === 0 ? '' : ' | '}
            <NavLink to={match.pathname}>{breadcrumb}</NavLink>
          </SpanLink>
        ))}
      </Container>
    </>
  )
}
