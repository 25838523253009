import React from 'react'
import Modal from 'react-modal'

import { X } from '@somostera/tera-icons'

import { ButtonContainer, BackButton, TextPanel, SaveConfirmationContainer, ConfirmButton } from './styles'

interface CancelConfirmationModalProps {
  isOpen: boolean
  onRequestClose: () => void
  onSubmit: () => void
  onCancel: () => void
}

export function CancelConfirmationModal({ isOpen, onSubmit, onCancel, onRequestClose }: CancelConfirmationModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <SaveConfirmationContainer>
        <button type="button" onClick={onRequestClose} className="react-modal-close">
          <X color="var(--gray-40)" size={12} />
        </button>

        <TextPanel>
          <h1>Cancelar alterações?</h1>
          <p>As modificações feitas na página não serão salvas.</p>
        </TextPanel>

        <ButtonContainer>
          <BackButton onClick={onCancel}>Voltar</BackButton>
          <ConfirmButton onClick={onSubmit}>Sim, cancelar</ConfirmButton>
        </ButtonContainer>
      </SaveConfirmationContainer>
    </Modal>
  )
}
