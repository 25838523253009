import React, { useMemo } from 'react'
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react'
import { Menu, Item, useContextMenu, theme, ItemParams, ItemProps } from 'react-contexify'

import { Container } from './styles'

const MENU_ID = 'coloured-card-list-component'

type CardColors = {
  foreground: string
  background: string
  label: string
}

export const ColouredCardListComponent = ({ updateAttributes, node }: NodeViewProps) => {
  const cardColors: CardColors[] = useMemo(
    () => [
      { foreground: 'black', background: 'white', label: 'Branco' },
      { foreground: 'black', background: 'gray-20', label: 'Cinza' },
      { foreground: 'white', background: 'green-60', label: 'Verde' }
    ],
    []
  )

  const { show } = useContextMenu({
    id: `${MENU_ID}-${node.attrs.backgroundColor}-${node.attrs.foregroundColor}`
  })

  const displayMenu = (event: React.MouseEvent) => {
    event.preventDefault()
    show(event, {
      props: {
        id: event?.currentTarget?.id
      }
    })
  }

  const handleItemClick = ({ event }: ItemParams<ItemProps>) => {
    const backgroundColor = event.currentTarget.id
    const selectedColor = cardColors.find((color) => color.background === backgroundColor)

    if (selectedColor) {
      updateAttributes({ backgroundColor: selectedColor.background, foregroundColor: selectedColor.foreground })
    }
  }

  return (
    <NodeViewWrapper>
      <Menu id={`${MENU_ID}-${node.attrs.backgroundColor}-${node.attrs.foregroundColor}`} theme={theme.dark}>
        {cardColors.map((cardColor) => (
          <Item key={cardColor.background} id={cardColor.background} onClick={handleItemClick}>
            {cardColor.label}
          </Item>
        ))}
      </Menu>

      <Container
        className="coloured-card-list-component"
        background={node.attrs.backgroundColor}
        foreground={node.attrs.foregroundColor}
        onContextMenu={displayMenu}
      >
        <NodeViewContent as="ol" className={node.attrs.backgroundColor === 'white' ? 'white-list' : ''} />
      </Container>
    </NodeViewWrapper>
  )
}
