/* eslint-disable prettier/prettier */
import { useAuth } from 'core/hooks/useAuth'
import { Aside } from 'modules/courses/components/Aside'

import { Card } from 'modules/courses/components/Card'
import { CoursesService } from 'modules/courses/services/courses'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Course } from '..'
import { EditCourse } from '../EditCourse'
import { DataSheet } from './Datasheet'
import { DatasheetEdit } from './Datasheet/DatasheetEdit'
import { getFormatedToLocaleDate } from 'core/utils/date'
import { Header, Navbar, Content, ListItem, HeaderContainer, LoadingContainer } from './styles'

import { useCourse } from 'modules/courses/hooks/useCourse'

import { ConfirmationModal as PublishConfirmationModal } from 'modules/courses/components/ConfirmationModal'

export function ViewCourse() {
  const { courseId } = useParams()
  const { userFirebase } = useAuth()

  const [listItemActive, setListItemActive] = useState('content')
  const [course, setCourse] = useState<Course>()
  const [itemsCourse, setItemsCourse] = useState<Course[]>([])
  const [articles, setArticles] = useState([])
  const [courseItems, setCourseItems] = useState([])
  const [showAside, setShowAside] = useState(false)
  const [articleShowAside, setArticleShowAside] = useState(null)
  const [showEdit, setShowEdit] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const { updateCurrentCourseName } = useCourse()
  const [isConfirmPublishModalOpen, setIsConfirmPublishModalOpen] = useState(false)

  function handleChangeListItem(type: string) {
    setListItemActive(type)
    setShowEdit(null)
  }

  async function getCourse() {
    const token = await userFirebase.getIdToken()

    const responseFull = await CoursesService.getFullCourseById(token, courseId)
    const response = await CoursesService.getCourseById(token, courseId)

    const setData = {
      ...response.data,
      ...responseFull.data,
      career: response.data.career,
      name: response.data.name,
      description: response.data.description
    }
    setCourse(setData)
    updateCurrentCourseName(response.data.name)
    setCourseItems(responseFull.data.courseItems)

    const articleFiltered = await getItemsCourse(courseId!)
    setArticles(articleFiltered)

    setItemsCourse(articleFiltered)
  }

  async function getItemsCourse(id: string) {
    const token = await userFirebase.getIdToken()

    try {
      const response = await CoursesService.getCourseItemById(token, id)

      const orderedResponse = response.data.sort((a, b) => {
        if (a.index > b.index) {
          return 1
        }
        if (a.index < b.index) {
          return -1
        }
        return 0
      })

      const articledMaped = await Promise.all(
        orderedResponse.map(async (item) => {
          if (item.itemType === 'article') {
            const article = await CoursesService.getArticleById(token, item.articleRef)
            return article.data
          }
          return null
        })
      )

      const articleFiltered = articledMaped.filter((article) => article !== null)

      return articleFiltered
    } catch (error) {
      console.log(error)
      return null
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getCourse()
  }, [showEdit])

  function handleShowAside(article: object) {
    setShowAside(!showAside)
    setArticleShowAside(article)
  }

  function handleCancelEdit() {
    setShowEdit(null)
  }

  const handleCloseConfirmPublishModal = useCallback(() => {
    setIsConfirmPublishModalOpen(false)
  }, [])

  async function handlePublishCourseItems() {
    const token = await userFirebase.getIdToken()
    const courseToUpdate = {
      ...course,
      status: 'published'
    }

    delete courseToUpdate.courseItems

    const courseUpdated = await CoursesService.updateCourse(token, courseId!, courseToUpdate)

    setIsConfirmPublishModalOpen(true)
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <span>Carregando...</span>
      </LoadingContainer>
    )
  }
  return (
    <>
      <PublishConfirmationModal
        confirmButtonText="ok, fechar"
        messageType="Confirmation"
        messageTitle="Curso publicado com sucesso"
        messageDescription="O curso foi publicado e está disponível para consumo dos estudantes. "
        onRequestClose={handleCloseConfirmPublishModal}
        isOpen={isConfirmPublishModalOpen}
        onConfirm={() => {
          handleCloseConfirmPublishModal()
        }}
      />

      {showAside && <Aside handleShowAside={handleShowAside} articleShowAside={articleShowAside} />}

      <HeaderContainer>
        <Header>
          <h3>{course?.name}</h3>
          <p>
            Atualizado em {getFormatedToLocaleDate(new Date(course?.updatedAt))} | Publicado em{' '}
            {getFormatedToLocaleDate(new Date(course?.createdAt))}
          </p>
        </Header>
        <Navbar showEdit={!!showEdit}>
          <ul>
            <section>
              <ListItem
                datasheetActive={listItemActive === 'datasheet' && !!showEdit}
                active={listItemActive === 'content'}
                onClick={() => handleChangeListItem('content')}
              >
                Conteúdo
              </ListItem>
              <ListItem
                active={listItemActive === 'datasheet'}
                onClick={() => handleChangeListItem('datasheet')}
                contentActive={listItemActive === 'content' && !!showEdit}
              >
                Especificações
              </ListItem>
            </section>
            <section>
              <a onClick={listItemActive === 'content' ? () => setShowEdit('content') : () => setShowEdit('datasheet')}>
                Editar
              </a>

              <a onClick={handlePublishCourseItems}>Publicar</a>
            </section>
          </ul>
        </Navbar>
      </HeaderContainer>
      <Content>
        {listItemActive === 'content' ? (
          <>
            {!showEdit &&
              (articles
                ? articles.map((article, index) => (
                    <Card key={index} handleShowAside={handleShowAside} article={article} item={courseItems.find(item => item?.itemId === article?.id)} />
                  ))
                : null)}
            {showEdit === 'content' && <EditCourse handleCancelEdit={handleCancelEdit} />}
          </>
        ) : (
          <>
            {!showEdit && <DataSheet course={course} />}
            {showEdit === 'datasheet' && (
              <DatasheetEdit handleCancelEdit={handleCancelEdit} getCourse={getCourse} course={course} />
            )}
          </>
        )}
      </Content>
    </>
  )
}
