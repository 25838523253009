import { Content, Editor } from '@tiptap/react'
import { monthNames, getDateInformation } from 'core/utils/date'
import { SelectableStatus } from '../views/Contents/ContentEditor'
import { ArticleDraft, Chapter } from '@somostera/tera-database'

export interface State {
  isLoading: boolean
  allArticleDrafts?: ArticleDraft[]
  currentArticleDraft: ArticleDraft
  currentArticleName: string
  currentChapterIndex: number
  currentChapter: Chapter
  chapterToBeRemoved: number
  lastStatusAndSavingDate: string
  error?: string
}

export type Action =
  | { type: 'ASYNC_CALL' }
  | { type: 'ASYNC_CALL_SUCCEED' }
  | { type: 'ASYNC_CALL_FAILED'; payload: { error: string } }
  | { type: 'SET_CURRENT_ALL_ARTICLES_DRAFTS'; payload: { articles: ArticleDraft[] } }
  | { type: 'FETCH_CURRENT_ARTICLE_DRAFT'; payload: { article: ArticleDraft } }
  | { type: 'FETCH_LAST_STATUS_AND_CURRENT_DATE' }
  | { type: 'UPDATE_CURRENT_ARTICLE_DRAFT'; payload: { article: ArticleDraft } }
  | { type: 'UPDATE_CURRENT_ARTICLE_DRAFT_NAME'; payload: { newArticleName: string } }
  | { type: 'UPDATE_CURRENT_CHAPTER'; payload: { index: number; editor: Editor | null } }
  | { type: 'UPDATE_CURRENT_CHAPTER_TO_BE_REMOVED'; payload: { index: number } }
  | { type: 'UPDATE_CURRENT_CHAPTER_CONTENT'; payload: { content: Record<string, any> } }
  | {
      type: 'ADD_CHAPTER_TO_CURRENT_ARTICLE_DRAFT'
      payload: {
        index: number
      }
    }

export const articlesReducer = (draft: State, action: Action): State => {
  switch (action.type) {
    case 'ASYNC_CALL':
      draft.isLoading = true
      break
    case 'ASYNC_CALL_SUCCEED':
      draft.isLoading = false
      break
    case 'ASYNC_CALL_FAILED':
      draft.isLoading = false
      draft.error = action.payload.error
      break
    case 'SET_CURRENT_ALL_ARTICLES_DRAFTS':
      draft.isLoading = false
      draft.allArticleDrafts = action.payload.articles
      break
    case 'FETCH_CURRENT_ARTICLE_DRAFT':
      draft.isLoading = false
      draft.currentArticleDraft = action.payload.article
      draft.currentArticleName = action.payload.article.name
      draft.currentChapter = action.payload.article.chapters[0]
      break
    case 'FETCH_LAST_STATUS_AND_CURRENT_DATE':
      if (draft.currentArticleDraft.updatedAt) {
        const { currentDay, day, hours, minutes, month, year } = getDateInformation(draft.currentArticleDraft.updatedAt)
        const dateText = (!currentDay ? `em ${day} de ${monthNames[month]} de ${year} ` : '') + `às ${hours}h${minutes}`
        draft.lastStatusAndSavingDate = `${
          SelectableStatus[draft.currentArticleDraft.status]
        } - Última edição salva ${dateText}`
      }
      break
    case 'UPDATE_CURRENT_ARTICLE_DRAFT':
      draft.currentArticleDraft = action.payload.article
      draft.currentArticleName = action.payload.article.name
      draft.currentChapter = action.payload.article.chapters[draft.currentChapterIndex]
      break
    case 'UPDATE_CURRENT_ARTICLE_DRAFT_NAME':
      draft.currentArticleName = action.payload.newArticleName
      draft.currentArticleDraft = { ...draft.currentArticleDraft, name: action.payload.newArticleName }
      break
    case 'UPDATE_CURRENT_CHAPTER':
      action.payload.editor?.commands.setContent(
        draft.currentArticleDraft.chapters[action.payload.index].content as Content
      )
      draft.currentChapter = draft.currentArticleDraft.chapters[action.payload.index]
      draft.currentChapterIndex = action.payload.index
      break
    case 'UPDATE_CURRENT_CHAPTER_TO_BE_REMOVED':
      draft.chapterToBeRemoved = action.payload.index
      break
    case 'UPDATE_CURRENT_CHAPTER_CONTENT':
      // eslint-disable-next-line no-case-declarations
      const newChapter = { ...draft.currentChapter, content: action.payload.content }
      draft.currentArticleDraft.chapters[newChapter.index] = newChapter
      draft.currentChapter = { ...draft.currentChapter, content: action.payload.content }
      break
    default:
      break
  }
  return draft
}
