import styled from 'styled-components'

export const Container = styled.div`
  background: #fcfcfc;
  height: calc(100vh);
  height: 100%;
  position: fixed;

  width: 600px;

  border: 1px solid #dbdbdb;

  box-shadow: 0px 22px 9px rgba(0, 0, 0, 0.03), 0px 12px 7px rgba(0, 0, 0, 0.1), 0px 5px 5px rgba(0, 0, 0, 0.17),
    0px 1px 3px rgba(0, 0, 0, 0.2), 0px 0px 0px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;

  top: 0;
  right: -18px;
  z-index: 10;
  overflow-y: scroll;
  overflow-x: hidden;

  > section:first-of-type {
    padding: 20px;
    width: auto;

    h4 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      width: fit-content;
      background: #8ffe81;

      color: #000000;

      border-radius: 16px;
      padding: 4px 16px;
      margin-top: 25px;
    }

    h3 {
      margin: 20px 0;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 100%;

      letter-spacing: -0.01em;
      text-transform: uppercase;

      color: #171717;
    }

    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;

      color: #171717;

      :first-child {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;

        color: #171717;
      }
    }

    section {
      border-top: 1px solid #dbdbdb;
      margin-top: 20px;
      padding-top: 20px;

      div {
        display: flex;
        margin-bottom: 20px;

        p {
          width: 128px;
          :last-child {
            width: 424px;
          }
        }

        ul {
          width: 424px;

          li {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #171717;
          }
        }
      }
    }
  }
`
