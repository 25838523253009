import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { TableColumn } from 'react-data-table-component'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useImmerReducer } from 'use-immer'
import { PeopleCalendarStatus } from '@somostera/tera-models-ts'
import { CheckCircleFilled, ArrowRefresh, X } from '@somostera/tera-icons'

import { JourneyCastingData } from '../../../@types/casting'

import { getPersonById } from 'core/services/people'
import { getJourneyCastingPageData } from '../../../services/casting'
import { updateJourneyCasting } from '../../../services/journeys'
import { getExpertsToAllocate } from '../../../services/feelingLucky'
import { useAuth } from 'core/hooks/useAuth'
import { FeelingLuckyStatus, journeyCastingReducer, State } from '../../../reducers/journeyCasting'

import { DataTableBase } from 'core/components/DataTableBase'
import { Select } from 'core/components/Select'
import { Button } from 'core/components/Button'
import { FilterTable } from 'core/components/DataTableBase/FilterTable'
import { SaveConfirmationModal } from '../../../components/SaveConfirmationModal'
import { CancelConfirmationModal } from '../../../components/CancelConfirmationModal'
import { ExpertCell } from '../../../components/ExpertCell'
import { ExpertStatusCell } from '../../../components/ExpertStatusCell'

import {
  ButtonsPanel,
  CancelButton,
  Container,
  FeelingLuckyContainer,
  FeelingLuckySucceedContainer,
  PageHeader,
  SaveButton,
  TitlePanel
} from './styles'

export function JourneyCasting() {
  const statusDictionary = useMemo(
    () => ({
      aceito: 'Convite aceito',
      recusado: 'Convite recusado',
      'enviar-convite': 'Enviar convite',
      selecionado: 'Expert selecionado'
    }),
    []
  )

  const { journeyId } = useParams()
  const navigate = useNavigate()
  const { token } = useAuth()

  const [filterText, setFilterText] = useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

  const [selectedStatusToChange, setSelectedStatusToChange] = useState('')
  const [isSaveConfirmationModalOpen, setIsSaveConfirmationModalOpen] = useState(false)
  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] = useState(false)

  const [{ isLoading, teraId, selectedRows, journeyCastingData, castingDataToUpdate, feelingLuckyStatus }, setState] =
    useImmerReducer(journeyCastingReducer, {
      isLoading: true,
      feelingLuckyStatus: FeelingLuckyStatus.TO_BE_DONE,
      journeyCastingData: [] as JourneyCastingData[],
      castingDataToUpdate: [] as JourneyCastingData[],
      selectedRows: [],
      teraId: ''
    } as State)

  const columns: TableColumn<JourneyCastingData>[] = [
    {
      name: 'Aula',
      selector: ({ name }: JourneyCastingData) => name,
      minWidth: '14rem',
      sortable: true
    },
    {
      name: 'Data',
      selector: ({ date }: JourneyCastingData) => date,
      width: '8rem'
    },
    {
      name: 'Horário',
      selector: ({ time }: JourneyCastingData) => time,
      width: '7.5rem'
    },
    {
      name: 'Experts',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      selector: (row: JourneyCastingData) => row.expertId!,
      cell: (row: JourneyCastingData) => (
        <ExpertCell
          row={row}
          onUpdateCell={(event) => {
            updateCastingData(row.journeyBlockId, 'expertId', event.target.value)
          }}
        />
      ),
      width: '14rem'
    },
    {
      name: 'Status',
      selector: ({ expertStatus }: JourneyCastingData) => expertStatus,
      center: true,
      cell: (row: JourneyCastingData) => (
        <ExpertStatusCell
          row={row}
          onUpdateCell={(event) => updateCastingData(row.journeyBlockId, 'expertStatus', event.target.value)}
        />
      ),
      width: '12rem'
    }
  ]

  // TODO esses nomes estão complexos demais
  const updateCastingDataToUpdateRow = async (journeyBlockId: string, rowToUpdate: JourneyCastingData) => {
    // check if the row was updated before
    const rowIndexToUpdate = castingDataToUpdate.findIndex((row) => row.journeyBlockId === journeyBlockId)

    if (rowIndexToUpdate >= 0) {
      setState({
        type: 'UPDATE_CASTING_DATA_TO_UPDATE',
        payload: {
          rowToUpdate,
          rowIndex: rowIndexToUpdate
        }
      })
    } else {
      setState({
        type: 'ADD_CASTING_DATA_TO_UPDATE',
        payload: {
          rowToUpdate
        }
      })
    }
  }

  const updateCastingData = async (journeyBlockId: string, columnId: string, value: any) => {
    const rowIndexFound = journeyCastingData.findIndex((row) => row.journeyBlockId === journeyBlockId)

    if (rowIndexFound < 0) {
      return
    }

    const expertName =
      columnId === 'expertId' ? (await getPersonById(value)).name : journeyCastingData[rowIndexFound].expertName

    const rowToUpdate = {
      ...journeyCastingData[rowIndexFound],
      [columnId]: value,
      expertName
    }

    setState({
      type: 'UPDATE_JOURNEY_CASTING_DATA_ROW',
      payload: {
        rowIndex: rowIndexFound,
        rowToUpdate
      }
    })

    await updateCastingDataToUpdateRow(journeyCastingData[rowIndexFound].journeyBlockId, rowToUpdate)
  }

  useEffect(() => {
    // eslint-disable-next-line prettier/prettier
    ;(async () => {
      setState({ type: 'ASYNC_CALL' })
      const { teraId: id, journeyCastingData } = await getJourneyCastingPageData(journeyId!)
      if (!journeyCastingData || !id) {
        setState({
          type: 'ASYNC_CALL_FAILED',
          payload: { error: 'Something went wrong when fetching the journey casting data.' }
        })
        return
      }
      setState({
        type: 'FETCH_JOURNEY_CASTING_PAGE_DATA',
        payload: {
          teraId: id,
          journeyCastingData
        }
      })

      // check if there are any experts to fill.
      const foundJourneyWithoutExpert = journeyCastingData.find(
        ({ expertName }) => expertName === '' || expertName === undefined
      )
      // there isn't any unfilled expert
      if (!foundJourneyWithoutExpert) {
        setState({ type: 'UPDATE_FEELING_LUCKY_STATUS', payload: { status: FeelingLuckyStatus.DONE } })
      }

      setState({ type: 'ASYNC_CALL_SUCCEED' })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeyId])

  const handleChangeStatusAction = useCallback(
    async (event: ChangeEvent<HTMLSelectElement>) => {
      const expertStatus = event.target.value as PeopleCalendarStatus
      setSelectedStatusToChange(expertStatus)

      for (const journeyBlockId of selectedRows) {
        await updateCastingData(journeyBlockId, 'expertStatus', expertStatus)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [journeyCastingData, selectedRows]
  )

  const handleCancelCastingData = () => {
    navigate('/casting')
  }

  const handleFeelingLuckyClick = useCallback(async () => {
    setState({ type: 'UPDATE_FEELING_LUCKY_STATUS', payload: { status: FeelingLuckyStatus.LOADING } })

    if (!token) {
      return
    }

    const expertsToAllocate = await getExpertsToAllocate(teraId, token)
    // no experts to be allocated
    if (expertsToAllocate.length === 0) {
      setState({ type: 'UPDATE_FEELING_LUCKY_STATUS', payload: { status: FeelingLuckyStatus.DONE } })
    }

    const expertStatus = PeopleCalendarStatus.SEND_INVITE

    for (const block of expertsToAllocate) {
      const { journeyBlockId, expertIdToAllocate } = block
      const { name: expertName } = await getPersonById(expertIdToAllocate)

      await Promise.all([
        await updateCastingData(journeyBlockId, 'expertStatus', expertStatus),
        await updateCastingData(journeyBlockId, 'expertId', expertIdToAllocate),
        await updateCastingData(journeyBlockId, 'expertName', expertName)
      ])
    }
    setState({ type: 'UPDATE_FEELING_LUCKY_STATUS', payload: { status: FeelingLuckyStatus.SUCCEED } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeyCastingData, teraId, token])

  const filteredItems = useMemo(
    () =>
      journeyCastingData.filter(
        ({ name, expertName, expertStatus }) =>
          JSON.stringify({
            name,
            expertName,
            expertStatus: statusDictionary[expertStatus]
          })
            .toLowerCase()
            .indexOf(filterText.toLowerCase()) !== -1
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterText, journeyCastingData]
  )

  const SubHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    return (
      <FilterTable
        placeholder="Busque por aula, expert ou status"
        onFilter={(event) => setFilterText(event.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    )
  }, [filterText, resetPaginationToggle])

  if (isLoading) {
    return <h1>Abrindo...</h1>
  }

  return (
    <>
      <SaveConfirmationModal
        isOpen={isSaveConfirmationModalOpen}
        onRequestClose={() => setIsSaveConfirmationModalOpen(false)}
        onSubmit={() => setIsSaveConfirmationModalOpen(false)}
      />
      <CancelConfirmationModal
        isOpen={isCancelConfirmationModalOpen}
        onRequestClose={() => setIsCancelConfirmationModalOpen(false)}
        onSubmit={handleCancelCastingData}
        onCancel={() => setIsCancelConfirmationModalOpen(false)}
      />

      <PageHeader>
        <div>
          <TitlePanel>
            <h4>
              <Link to="/casting">Casting</Link>
            </h4>
            <h1>{teraId}</h1>
          </TitlePanel>
          <ButtonsPanel>
            <CancelButton onClick={() => setIsCancelConfirmationModalOpen(true)}>Cancelar</CancelButton>
            <SaveButton
              onClick={() => {
                updateJourneyCasting(journeyId!, castingDataToUpdate)
                setIsSaveConfirmationModalOpen(true)
              }}
            >
              Salvar
            </SaveButton>
          </ButtonsPanel>
        </div>
      </PageHeader>

      <Container>
        {feelingLuckyStatus === 'succeed' ? (
          <FeelingLuckySucceedContainer>
            <button
              onClick={() =>
                setState({ type: 'UPDATE_FEELING_LUCKY_STATUS', payload: { status: FeelingLuckyStatus.DONE } })
              }
            >
              <X height={16} width={16} />
            </button>
            <CheckCircleFilled height={20} width={20} color="var(--green-60)" />
            <h1>Casting preenchido com sucesso!</h1>
          </FeelingLuckySucceedContainer>
        ) : (
          feelingLuckyStatus !== 'done' && (
            <FeelingLuckyContainer>
              <h3>Essa jornada ainda não tem experts alocadas.</h3>
              <p>Clique em ”estou com sorte” para preencher o casting automaticamente.</p>
              <Button onClick={handleFeelingLuckyClick} disabled={isLoading}>
                {feelingLuckyStatus === 'loading' ? (
                  <>
                    Carregando
                    <ArrowRefresh size={10} color="var(--white)" />
                  </>
                ) : (
                  'Estou com sorte'
                )}
              </Button>
            </FeelingLuckyContainer>
          )
        )}

        {selectedRows.length > 0 && (
          <Select
            name="expertStatusOptions"
            items={[
              { value: 'aceito', name: 'Convite aceito' },
              { value: 'recusado', name: 'Convite recusado' },
              { value: 'enviar-convite', name: 'Enviar convite' },
              { value: 'selecionado', name: 'Expert selecionado' }
            ]}
            placeholder="Alterar status"
            onChange={handleChangeStatusAction}
            selectedValue={selectedStatusToChange}
          />
        )}

        <DataTableBase
          columns={columns}
          data={filteredItems}
          selectableRows
          onSelectedRowsChange={({ selectedRows }) =>
            setState({ type: 'UPDATE_SELECTED_ROWS', payload: { selectedRows } })
          }
          subHeader
          subHeaderComponent={SubHeaderComponent}
          paginationResetDefaultPage={resetPaginationToggle}
          persistTableHead
        />
      </Container>
    </>
  )
}
