import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { X } from '@somostera/tera-icons'

import { useForm } from 'core/hooks/useForm'

import { Input } from 'core/components/Input'
import { AutosizeInput } from 'core/components/AutosizeInput'
import { Button } from 'core/components/Button'

import { AddQuoteBlockContainer, ButtonContainer } from './styles'

type QuoteBlockInfo = {
  quote: string
  author: string
}

interface AddQuoteBlockModalProps {
  id?: string
  isOpen: boolean
  initialValues?: QuoteBlockInfo
  onRequestClose: () => void
  onAddBlockQuote: ({ quote, author }: QuoteBlockInfo) => void
}

export function AddQuoteBlockModal({
  id = '',
  isOpen,
  initialValues,
  onRequestClose,
  onAddBlockQuote
}: AddQuoteBlockModalProps) {
  const { data, handleChange, handleSubmit, errors, reset, setValue } = useForm<QuoteBlockInfo>({
    initialValues: {
      author: '',
      quote: ''
    },
    onSubmit: () => {
      const { author, quote } = data
      onAddBlockQuote({ quote, author })
      onRequestClose()
      reset()
    },
    validations: {
      author: {
        required: { value: true, message: 'Preencha o campo obrigatório.' }
      },
      quote: {
        required: { value: true, message: 'Preencha o campo obrigatório.' }
      }
    }
  })

  const [oldId, setOldId] = useState('')

  useEffect(() => {
    if (initialValues && id !== oldId) {
      setValue('quote', initialValues.quote)
      setValue('author', initialValues.author)
    }
    setOldId(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <AddQuoteBlockContainer onSubmit={handleSubmit}>
        <button type="button" onClick={onRequestClose} className="react-modal-close">
          <X color="var(--gray-40)" size={24} />
        </button>

        <AutosizeInput
          type="text"
          name="quote"
          label="Citação"
          placeholder="Adicione a citação"
          onChange={handleChange('quote')}
          value={data.quote}
          errors={errors.quote}
        />
        <Input
          type="text"
          name="author"
          label="Autor(a)"
          placeholder="Adicione o(a) autor(a)"
          onChange={handleChange('author')}
          value={data.author}
          errors={errors.author}
        />

        <ButtonContainer>
          <Button type="submit" disabled={data.author === '' || data.quote === ''}>
            Adicionar Citação
          </Button>
        </ButtonContainer>
      </AddQuoteBlockContainer>
    </Modal>
  )
}
