import React from 'react'
import Modal from 'react-modal'
import { CheckCircleFilled, X } from '@somostera/tera-icons'

import { Button } from 'core/components/Button'

import { ButtonContainer, TextPanel, SaveConfirmationContainer } from './styles'

interface SaveConfirmationModalProps {
  isOpen: boolean
  onRequestClose: () => void
  onSubmit: () => void
}

export function SaveConfirmationModal({ isOpen, onSubmit, onRequestClose }: SaveConfirmationModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <SaveConfirmationContainer>
        <button type="button" onClick={onRequestClose} className="react-modal-close">
          <X color="var(--gray-40)" size={12} />
        </button>

        <TextPanel>
          <CheckCircleFilled size={66} color="var(--blue-primary)" />
          <h1>tudo pronto!</h1>
          <p>As alterações foram salvas.</p>
        </TextPanel>

        <ButtonContainer>
          <Button onClick={onSubmit}>Okay</Button>
        </ButtonContainer>
      </SaveConfirmationContainer>
    </Modal>
  )
}
