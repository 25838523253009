import styled, { css } from 'styled-components'
import { ViewDataType } from '.'

interface ContainerProps {
  type: ViewDataType
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  //align-items: center;
  justify-content: flex-start;
  min-width: 30rem;
  gap: 2rem;
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--gray-20);

  :last-child {
    border-bottom: none;
  }

  > h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.875rem;
    color: var(--gray-50);
    width: 222px;
  }

  > div {
    max-width: 60%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.4rem;
    width: 354px;
  }
`

export const ListItemList = styled.div`
  display: flex;
  flex-direction: column;
`

interface ListItemProps {
  type: ViewDataType
  tagKey?: number
}

const colors = {
  1: 'var(--green-pastel)',
  2: 'var(--blue-pastel)',
  3: 'var(--yellow-pastel)',
  4: 'var(--purple-pastel)'
} as { [key: string]: string }

export const ListItemLink = styled.a`
  color: #0d4d05;
  text-decoration: underline;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
`

export const ListItem = styled.div<ListItemProps>`
  font-size: 0.875rem;
  max-width: 100%;
  word-break: break-all;

  ${(props) =>
    props.type === 'link' &&
    css`
      color: #0d4d05;
      text-decoration: underline;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
    `}

  ${(props) =>
    props.type === 'default' &&
    css`
      max-width: 100%;
      display: block;

      &::after {
        content: ',';
      }

      &:last-child::after {
        content: '';
      }
    `}
  
  ${(props) =>
    props.type === 'tag' &&
    css`
      background: #d0f6cb;
      padding: 0.25rem 1rem;
      border-radius: 9999px;
    `}

      ${(props) =>
    props.type === 'list' &&
    css`
      width: 100%;
      display: flex;
      flex-direction: column;
    `}
`
export const TagContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
  background: #d0f6cb;
  padding: 4px 12px 4px 4px;
  border-radius: 16px;
`
