import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { X } from '@somostera/tera-icons'

import { useForm } from 'core/hooks/useForm'

import { Input } from 'core/components/Input'
import { AutosizeInput } from 'core/components/AutosizeInput'
import { Button } from 'core/components/Button'

import { AddQuoteBlockContainer, ButtonContainer } from './styles'
import { useContent } from '../../../hooks/useContent'
import { findJourneyBlockById } from '../../../services/article'
import { InputSelect } from '../../datasheet/Select'
import {MediaType} from "@somostera/tera-models-ts";
import {FileInput} from "../../../../../core/components/FileInput";

interface ExpertData {
  value?: string
  label?: string
  photo?: string
}

type ExpertTipInfo = {
  tip: string
  author: ExpertData,
  file: string
}

interface AddQuoteBlockModalProps {
  id?: string
  isOpen: boolean
  initialValues?: ExpertTipInfo
  onRequestClose: () => void
  onAddExpertTip: ({ tip, author, file }: ExpertTipInfo) => void
}

export function AddExpertTipModal({
  id = '',
  isOpen,
  initialValues,
  onRequestClose,
  onAddExpertTip
}: AddQuoteBlockModalProps) {
  const [selectableExperts, setSelectableExperts] = useState<ExpertData[]>([])
  const [selectedsExperts, setSelectedsExperts] = useState<ExpertData[]>([{ value: '1', label: 'Selecione o expert' }])

  const [fileUrl, setFileUrl] = useState('')
  const [fileSize, setFileSize] = useState(0)
  const [uploadedFile, setUploadedFile] = useState<File>()

  const { allPeopleData, allValidExperts } = useContent()

  useEffect(() => {
    if (allValidExperts) {
      const selectableExperts = allValidExperts.map((expert) => ({
        value: expert.value,
        label: expert.name
      }))

      setSelectableExperts(selectableExperts)
    }
  }, [allValidExperts])

  const { data, handleChange, handleSubmit, errors, reset, setValue } = useForm<ExpertTipInfo>({
    initialValues: {
      author: {},
      tip: '',
      file: ''
    },
    onSubmit: () => {
      const { author, tip, file } = data
      onAddExpertTip({ tip, author: selectedsExperts[0], file: fileUrl })
      onRequestClose()
      reset()
    },
    validations: {
      tip: {
        required: { value: true, message: 'Preencha o campo obrigatório.' }
      }
    }
  })

  const [oldId, setOldId] = useState('')

  useEffect(() => {
    if (initialValues && id !== oldId) {
      setValue('tip', initialValues.tip)
      setValue('author', initialValues.author)
      setValue('file', initialValues.file)
    }
    setOldId(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleAddExpert(expert: ExpertData, index: number) {
    const expertData = allPeopleData.find((personData) => personData.id === expert.value)

    const newSelectedsExperts = [...selectedsExperts]

    newSelectedsExperts[index] = {
      value: expertData?.id || '1',
      label: expertData?.name || '',
      photo: expertData?.pictureUrl || expertData?.profilePicture || ''
    }

    setSelectedsExperts(newSelectedsExperts)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <AddQuoteBlockContainer onSubmit={handleSubmit}>
        <button type="button" onClick={onRequestClose} className="react-modal-close">
          <X color="var(--gray-40)" size={24} />
        </button>

        <AutosizeInput
          type="text"
          name="quote"
          label="Dica"
          placeholder="Adicione a dica"
          onChange={handleChange('tip')}
          value={data.tip}
          errors={errors.tip}
        />
        <InputSelect
          type="expert"
          index={0}
          label="Expert"
          placeholder="Selecione o expert"
          options={[{ value: '1', label: 'Selecione o expert' }, ...selectableExperts]}
          onChange={handleAddExpert}
          value={selectedsExperts[0]}
        />

        <h3>Arquivo (opcional)</h3>
        <FileInput
          name="fileUrl"
          fileType={MediaType.IMAGE}
          acceptedFilesRegex={[
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.google-apps.presentation',
            'application/vnd.oasis.opendocument.presentation',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.google-apps.document',
            'application/vnd.oasis.opendocument.text',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.google-apps.spreadsheet',
            'application/vnd.oasis.opendocument.spreadsheet',
            'application/pdf',
            'application/json',
            'text/xml',

            'text/csv',
            'text/tab-separated-values',
            'text/tsv',
            '.ipynb',
            'image/jpeg',
            'image/bmp',
            'image/x-ms-bmp',
            'image/png',
            'text/markdown',
            '.tsv'
          ].join(',')}
          onFileUrlChange={(fileUrl: string) => setFileUrl(fileUrl)}
          onFileSizeChange={(size: number) => setFileSize(size)}
          onFileChange={(file: File) => setUploadedFile(file)}
        />

        <ButtonContainer>
          <Button type="submit">Adicionar Dica</Button>
        </ButtonContainer>
      </AddQuoteBlockContainer>
    </Modal>
  )
}
