import React from 'react'

import { NodeContainer } from './styles'

export function PageBreakComponent() {
  return (
    <NodeContainer className="page-break-component">
      <hr />
      <button type="button">Avançar</button>
    </NodeContainer>
  )
}
