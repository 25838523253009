import styled from 'styled-components'

export const Container = styled.div`
  margin: 2rem auto;
  width: 35rem;

  h1 {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  p {
    color: var(--gray-60);
    font-size: 1rem;
    line-height: 1.5rem;
  }

  form {
    display: flex;
    flex-direction: column;

    > hr {
      background: var(--gray-20);
      height: 0.125rem;
      margin: 2.5rem 0 1.5rem;
    }

    > button {
      align-self: flex-end;
      background: var(--black);
      border-radius: 0.5rem;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
      color: var(--white);
      font-size: 1rem;
      font-weight: bold;
      height: 3rem;
      line-height: 1.25rem;
      text-transform: uppercase;
      width: 14rem;

      &:disabled {
        background: var(--gray-20);
        cursor: not-allowed;
      }
    }
  }
`

interface SearchStatusContainerProps {
  status: string
}

export const SearchStatusContainer = styled.div<SearchStatusContainerProps>`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5rem;

  p {
    color: ${({ status }) =>
      status === 'loadingContact'
        ? 'var(--gray-60)'
        : status === 'verifiedContact'
        ? 'var(--green-60)'
        : status === 'alreadyRegistered'
        ? 'var(--yellow)'
        : 'var(--gray-80)'};
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-left: 0.5rem;
  }
`

interface ProfileContainerProps {
  selected: boolean
}

export const ProfileContainer = styled.div<ProfileContainerProps>`
  > h3 {
    font-size: 1rem;
    line-height: 140%;
    margin: 1.5rem 0 0.5rem;
    text-transform: uppercase;
  }

  > div {
    align-items: center;
    border: 1.5px solid ${(props) => (props.selected ? 'var(--blue-primary)' : 'var(--gray-60)')};
    border-radius: 0.5rem;
    display: flex;
    height: 4.75rem;
    justify-content: flex-start;
    margin-bottom: 1rem;
    padding: 1rem;
  }

  > p {
    color: var(--gray-80);
    font-size: 1rem;
    line-height: 1.5rem;

    & + p {
      margin-top: 1.5rem;
    }

    > a {
      color: var(--blue-primary);
    }
  }
`

export const ProfileData = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;

  p {
    color: var(--black);
    font-size: 1rem;
    line-height: 1.5rem;
  }

  span {
    color: var(--gray-60);
    font-size: 0.875rem;
    line-height: 120%;
  }
`

export const ProfilePicture = styled.img`
  border-radius: 50%;
  max-height: 2.75rem;
  max-width: 2.75rem;
`

export const ProfilePictureInput = styled.div`
  align-self: center;
  position: relative;
  margin-left: 0.5rem;

  img {
    border-radius: 50%;
    height: 5rem;
    width: 5rem;
  }

  label {
    align-items: center;
    background: var(--blue-pastel-to-purple-pastel);
    border: 0;
    border-radius: 50%;
    bottom: 0;
    cursor: pointer;
    display: flex;
    height: 1.5rem;
    justify-content: center;
    position: absolute;
    right: 0;
    transition: opacity 0.2s;
    width: 1.5rem;

    &:hover {
      opacity: 0.9;
    }

    input {
      display: none;
    }
  }
`
