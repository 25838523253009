import axios from 'axios'

const getUrl = `${process.env.REACT_APP_TERA_API}/lives`
export abstract class LivesService {
  static async getAll(token: string) {
    return await axios.get(getUrl, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }

  static async getAllSchedules(token: string) {
    return await axios.get(`${getUrl}/schedules`, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }

  static async getHostInvites(token: string, liveId: string, liveScheduleId: string) {
    return await axios.get(`${getUrl}/${liveId}/schedules/${liveScheduleId}/schedule_host_invites`, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }

  static async saveLive(token: string, data) {
    return await axios.post(getUrl, data, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }

  static async schedulesLive(token: string, liveId: string, data: object) {
    return await axios.post(`${getUrl}/${liveId}/schedules`, data, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }

  static async schedulesLiveEdit(token: string, liveId: string, liveScheduleId: string, data: object) {
    return await axios.put(`${getUrl}/${liveId}/schedules/${liveScheduleId}`, data, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }

  static async sendInviteLive(token: string, liveId: string, liveScheduleId: string, data: object) {
    return await axios.post(`${getUrl}/${liveId}/schedules/${liveScheduleId}/schedule_host_invites`, data, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }

  static async deleteLive(token: string, liveId: string) {
    return await axios.delete(`${getUrl}/${liveId}`, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }

  static async editLive(token: string, liveId: string, data: object) {
    return await axios.put(`${getUrl}/${liveId}`, data, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }

  static async newLinkZoom(token: string, liveId: string, liveScheduleId: string, data: object) {
    return await axios.post(`${getUrl}/${liveId}/schedules/${liveScheduleId}/create_link`, data, {
      headers: { __session: token, 'Access-Control-Allow-Origin': '*' }
    })
  }
}
