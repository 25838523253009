import React, { InputHTMLAttributes, useCallback, useState } from 'react'

import { XCircle } from '@somostera/tera-icons'

import { Container, InputInformationContainer, Error } from './styles'

interface AutosizeInputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  name: string
  label?: string
  containerStyle?: object
  characterCount?: boolean
  errors?: string
  erasable?: boolean
  minheight?: string
  onResetValue?: () => void
  required?: boolean
}

export const AutosizeInput = ({
  erasable = false,
  name,
  label,
  containerStyle = {},
  onResetValue,
  maxLength,
  characterCount = false,
  value,
  minheight = '3rem',
  errors,
  onBlur,
  required,
  ...rest
}: AutosizeInputProps) => {
  // it was occurring an error:
  // Warning: Received `false` for a non-boolean attribute `isfocused`.
  // If you want to write it to the DOM, pass a string instead: isfocused="false" or isfocused={value.toString()}.
  const [isFocused, setIsFocused] = useState<'false' | 'true'>('false')

  const handleInputFocus = useCallback(() => {
    setIsFocused('true')
  }, [])

  const handleInputBlur = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) => {
      if (onBlur) {
        onBlur(event)
      }
      setIsFocused('false')
    },
    [onBlur]
  )

  return (
    <>
      <InputInformationContainer>
        {required && !!label ? (
          <>
            <label htmlFor={name}>{label}</label>
            <span>*</span>
          </>
        ) : (
          <label htmlFor={name}>{label}</label>
        )}

        {characterCount && (
          <p>
            {String(value).length}/{maxLength}
          </p>
        )}
      </InputInformationContainer>
      <Container
        {...rest}
        value={value || ''}
        id={name}
        name={name}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        maxLength={maxLength || 2000}
        style={containerStyle}
        isfocused={isFocused}
        minheight={minheight}
      >
        {erasable && onResetValue && (
          <button type="button" onClick={onResetValue}>
            <XCircle color="var(--gray-20)" size={24} />
          </button>
        )}
      </Container>
      {!!errors && <Error>{errors}</Error>}
    </>
  )
}
