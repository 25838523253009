import styled from 'styled-components'

export const Form = styled.form``

export const HorizontalPanel = styled.div`
  display: flex;

  > div {
    margin-top: 0.25rem;
  }

  > div + div,
  > label + label {
    margin-left: 1rem;
  }
`

export const Label = styled.label`
  color: var(--gray-60);
  display: block;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.25rem;
  text-transform: uppercase;
  margin: 1.5rem 0 0.25rem;
  margin-top: 5rem;
`
interface NextButtonProps {
  width: string
}

export const SaveButton = styled.button<NextButtonProps>`
  background: var(--black);
  border-radius: 0.25rem;
  color: var(--white);
  font-size: 0.875rem;
  font-weight: bold;
  height: 2rem;
  line-height: 120%;
  margin-top: 1.5rem;
  text-transform: uppercase;
  width: ${(props) => props.width};
`

export const ContainerAutoSizeInput = styled.div`
  width: 35rem;
`
