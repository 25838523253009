import styled from 'styled-components'

export const FunctionsContainer = styled.div`
  display: flex;
`

export const MenusContainer = styled.div`
  border-left: 1px solid var(--gray-20);
  border-right: 1px solid var(--gray-20);
  display: flex;
  padding: 0;
`
