import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ArticleDraft, CareersType } from '@somostera/tera-database'

import { findArticleById } from 'modules/contents/services/article'
import { useContent } from 'modules/contents/hooks/useContent'

import { getDateInformation, getFormatedToLocaleDate, monthNames } from 'core/utils/date'
import { getReadingTime } from 'core/utils/countHelper'

import { ViewData } from 'modules/contents/components/datasheet/ViewData'

import Pen from 'core/components/Icon/Pen'
import { Loading } from 'core/components/Animation/Loading'

import { HeaderContainer, Button, Content, Box, LoadingContainer } from './styles'
interface ExpertData {
  id: string
  name: string
}
interface FacilitatorData {
  id: string
  label: string
}

const ProductType = {
  CLASS: 'Aula Digital',
  REPLAY: 'Replay',
  BRANDEDCHALLENGE: 'Desafio de Mercado',
  CHALLENGE: 'Desafio Tera',
  TUTORIAL: 'Projeto/tutorial',
  CASE: 'Estudo de Caso',
  ARTICLE: 'Artigo',
  PODCAST: 'Podcast',
  NARRATIVE: 'Narrativa da Jornada',
  INTERNAL: 'Interno',
  DEBATE: 'Debate (ou talk)'
}

const AvailableForSchool = {
  PRODUCT: 'Plataforma',
  SOLAR: 'Solar',
  MBA: 'MBA',
  ACADEMY: 'Academy'
}

const Levels = {
  BEGINNER: 'Iniciante',
  INTERMEDIATE: 'Intermediário',
  ADVANCED: 'Avançado'
}

export function DataSheet() {
  const { articleId } = useParams()
  const { getExpertsNameByParticipantsIds, getFacilitatorNameByFacilitatorIds } = useContent()
  const { updateCurrentArticleDraftName } = useContent()

  const [articleInfo, setArticleInfo] = useState<ArticleDraft>({} as ArticleDraft)
  const [articleDraftInformation, setArticleDraftInformation] = useState<ArticleDraft>({} as ArticleDraft)

  const [currentExperts, setCurrentExperts] = useState<ExpertData[]>([{ id: '1', name: 'Carregando...' }])
  const [currentFacilitator, setCurrentFacilitator] = useState<FacilitatorData[]>([{ id: '1', label: 'Carregando...' }])

  const [isLoading, setIsLoading] = useState(true)
  const [visible, setVisible] = useState(false)

  const [duration, setDuration] = useState(0)
  const [dateToShow, setDateToShow] = useState<Date | null>(null)

  const [articleType, setArticleType] = useState<string | undefined | null>()

  let dateText = ''
  if (dateToShow) {
    const { day, hours, minutes, month, year } = getDateInformation(dateToShow)
    dateText = `ÚLTIMA EDIÇÃO SALVA EM ${day} de ${monthNames[month]} DE ${year} ÀS ${hours}H${minutes}`
  }

  useEffect(() => {
    async function getAutomaticData() {
      if (articleId) {
        const [articleInformation, articleDraftInfo] = await Promise.all([
          findArticleById(articleId, true),
          findArticleById(articleId, false)
        ])

        const expertNames = getExpertsNameByParticipantsIds(articleInformation.participantsIds)
        const facilitatorName = getFacilitatorNameByFacilitatorIds(articleInformation.facilitatorsIds)

        updateCurrentArticleDraftName(articleInformation.name)
        setCurrentExperts(expertNames)
        setCurrentFacilitator(facilitatorName)
        setDateToShow(articleInformation.updatedAt)

        setDuration(getReadingTime(articleInformation.chapters))
        setArticleInfo(articleInformation)
        setArticleDraftInformation(articleDraftInfo)
        setVisible(articleInformation.visibility === 'public')
      }
      setIsLoading(false)
    }
    getAutomaticData()
  }, [])

  useEffect(() => {
    if (articleInfo?.type || articleDraftInformation?.type) {
      const type = articleInfo?.type || articleDraftInformation?.type
      setArticleType(type?.toUpperCase().replaceAll('-', ''))
    }
  }, [articleDraftInformation, articleInfo])

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    )
  }

  return (
    <>
      <HeaderContainer>
        <div>
          <h2>{articleDraftInformation?.name}</h2>
          <h3>{dateText}</h3>
        </div>
        <Button to={`/contents/${articleInfo.id}/datasheet/edit`} state={{ state: articleInfo }}>
          <Pen />
          Editar
        </Button>
      </HeaderContainer>
      <Content>
        <Box>
          <h3>Especificações</h3>
          <ViewData
            title="Experts"
            data={
              currentExperts.length === 0 || currentExperts[0].id === '1'
                ? ['Nenhum']
                : currentExperts?.map((currentExpert) => currentExpert.name)
            }
          />

          <ViewData
            title="Facilitadores"
            data={
              currentFacilitator.length === 0 || currentFacilitator[0].id === '1'
                ? ['Nenhum']
                : currentFacilitator?.map((currentFacilitator) => currentFacilitator.label)
            }
          />

          <ViewData title="Descrição" data={!articleInfo?.description ? ['Nenhum'] : [`${articleInfo?.description}`]} />

          <ViewData
            type="list"
            title="Objetivos de aprendizagem"
            data={!articleInfo?.editorial?.outcomes?.length ? ['Nenhum'] : articleInfo?.editorial?.outcomes}
          />

          <ViewData
            type="tag"
            title="Carreiras"
            data={
              !articleInfo?.editorial?.careers?.length
                ? ['Nenhum']
                : articleInfo?.editorial?.careers?.map((career) => CareersType[career])
            }
          />

          <ViewData
            type="tag"
            title="Escola"
            data={
              !articleInfo?.availableForSchool?.length
                ? ['Nenhum']
                : articleInfo?.availableForSchool?.map((school) => AvailableForSchool[school])
            }
          />

          <ViewData
            type="tag"
            title="Nível"
            data={
              !articleInfo?.level?.length
                ? ['Nenhum']
                : articleInfo?.level?.map((level) => Levels[level])
            }
          />

          <ViewData
            type="tag"
            title="Tópicos"
            data={!articleInfo?.editorial?.keywords?.length ? ['Nenhum'] : articleInfo?.editorial?.keywords}
          />

          <ViewData
            type="tag"
            title="Ferramentas"
            data={!articleInfo?.tools?.length ? ['Nenhuma'] : articleInfo?.tools}
          />

          <ViewData type="tag" title="Tipo de conteúdo" data={articleType ? [ProductType[articleType]] : ['Nenhum']} />
          <ViewData type="tag" title="Visibilidade" data={[`${visible ? 'Público' : 'Privado'}`]} />
        </Box>

        <Box>
          <h3>Dados automáticos</h3>
          <ViewData
            title="Publicado em"
            data={!articleInfo.updatedAt ? ['Nenhum'] : [getFormatedToLocaleDate(articleInfo.updatedAt)]}
          />
          <ViewData
            title="Última edição em"
            data={
              !articleDraftInformation?.updatedAt
                ? ['Nenhum']
                : [getFormatedToLocaleDate(articleDraftInformation?.updatedAt)]
            }
          />
          <ViewData title="Duração" data={[`${duration}m`]} />
          <ViewData title="Visualizações" data={[`${articleInfo.views}`]} />
          <ViewData
            title="Avaliação Média"
            data={[`${Number.isNaN(articleInfo.evaluation) ? 0 : articleInfo.evaluation?.toFixed(2)}`]}
            type="tagWithIcon"
          />
        </Box>

        <Box>
          <h3>Materiais Utilizados</h3>
          <ViewData
            title="Google Drive"
            data={!articleInfo?.editorial?.driveLink ? ['Nenhum'] : [`${articleInfo?.editorial?.driveLink}`]}
            type={!articleInfo?.editorial?.driveLink ? undefined : 'link'}
          />

          <ViewData
            title="Vídeos"
            data={!articleInfo?.editorial?.videoLink ? ['Nenhum'] : [`${articleInfo?.editorial?.videoLink}`]}
            type={!articleInfo?.editorial?.videoLink ? undefined : 'link'}
          />
        </Box>
      </Content>
    </>
  )
}
