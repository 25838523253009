import styled, { css } from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize'

interface ContainerProps {
  isfocused: 'true' | 'false'
  minHeight: string
}

export const Container = styled(TextareaAutosize)<ContainerProps>`
  background: transparent;
  border: 1.5px solid var(--gray-40);
  border-radius: 0.25rem;
  font-size: 1rem;
  height: 100%;
  line-height: 1.25rem;
  min-height: ${(props) => props.minHeight};
  outline: 0;
  overflow-y: hidden;
  padding: 0.75rem 2.75rem 0.75rem 1rem;
  position: relative;
  resize: none;
  width: 100%;

  &::placeholder {
    color: var(--gray-60);
  }

  ${(props) =>
    props.isfocused &&
    props.isfocused === 'true' &&
    css`
      border-color: var(--blue-primary);
    `}

  svg {
    position: absolute;
    right: 0.625rem;
    top: calc(50% - 12px);

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const InputInformationContainer = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;

  label {
    color: var(--gray-60);
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1.25rem;
    text-transform: uppercase;
    margin: 1.5rem 0 0.25rem;
  }

  p {
    color: var(--gray-80);
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.25rem;
  }
`

export const Error = styled.p`
  color: var(--orange);
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
`
