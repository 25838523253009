import React from 'react'
import Tippy, { TippyProps } from '@tippyjs/react/headless'

import { TooltipContainer } from './styles'

interface TooltipProps extends TippyProps {
  title: string
  description?: string
}

export function Tooltip({ title, description, children }: TooltipProps) {
  if (!children) {
    return <div />
  }

  return (
    <Tippy
      placement="bottom"
      delay={500}
      render={(attrs) => (
        <TooltipContainer {...attrs}>
          <p>{title}</p>
          {description && <span>{description}</span>}
        </TooltipContainer>
      )}
    >
      {children}
    </Tippy>
  )
}
