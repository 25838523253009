import styled, { css } from 'styled-components'

interface TagContainerProps {
  type: 'publicado' | 'rascunho' | 'arquivado' | 'deletado'
}
export const TagContainer = styled.div<TagContainerProps>`
  background: var(--success3);
  display: flex;
  align-items: center;
  gap: 0.25rem;

  padding: 4px 12px 4px 4px;
  border-radius: 16px;

  ${(props) =>
    props.type === 'publicado' &&
    css`
      background: var(--success3);
    `}

  ${(props) =>
    props.type === 'rascunho' &&
    css`
      background: var(--neutral3);
    `}

  ${(props) =>
    props.type === 'arquivado' &&
    css`
      background: var(--warning3);
    `}

  ${(props) =>
    props.type === 'deletado' &&
    css`
      background: var(--warning3);
    `}
`
