import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;

  > section {
    width: 35rem;
    flex-direction: column;
    display: flex;
    padding-bottom: 5rem;
  }
`
