import React, { useCallback, useState } from 'react'
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react'
import { Menu, Item, useContextMenu, theme } from 'react-contexify'

import { Container } from './styles'
import { AddQuoteBlockModal } from '../../modals/AddQuoteBlockModal'

const MENU_ID = 'quote-component-edit'

type QuoteBlockInfo = {
  quote: string
  author: string
}

export function QuoteComponent({ node, editor, updateAttributes }: NodeViewProps) {
  const [isAddQuoteBlockModalOpen, setIsAddQuoteBlockModalOpen] = useState(false)
  const { show } = useContextMenu({
    id: `${MENU_ID}-${node.attrs.author}-${node.attrs.quote}`
  })

  const displayMenu = (event: React.MouseEvent) => {
    event.preventDefault()
    show(event, {
      props: {
        id: event?.currentTarget?.id
      }
    })
  }

  const handleItemClick = () => {
    setIsAddQuoteBlockModalOpen(true)
  }

  const handleAddBlockQuote = useCallback(({ quote, author }: QuoteBlockInfo) => {
    const formattedQuote = !quote.startsWith(`"`) && !quote.startsWith(`'`) ? `"${quote}"` : quote

    if (node.attrs.quote && node.attrs.author) {
      updateAttributes({ quote: formattedQuote, author })
    } else if (quote && author) {
      editor.chain().focus().setQuote({ quote: formattedQuote, author, isPreview: false, HTMLAttributes: {} }).run()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <NodeViewWrapper>
      <AddQuoteBlockModal
        id={`${MENU_ID}-${node.attrs.author}-${node.attrs.quote}`}
        isOpen={isAddQuoteBlockModalOpen}
        onRequestClose={() => setIsAddQuoteBlockModalOpen(false)}
        onAddBlockQuote={handleAddBlockQuote}
        initialValues={{ quote: node.attrs.quote || '', author: node.attrs.author || '' }}
      />
      {!node.attrs.isPreview && (
        <Menu id={`${MENU_ID}-${node.attrs.author}-${node.attrs.quote}`} theme={theme.dark}>
          <Item id="edit" onClick={handleItemClick}>
            Editar Citação
          </Item>
        </Menu>
      )}

      <Container className="quote-component" onContextMenu={displayMenu}>
        <blockquote>
          <p>{node.attrs.quote}</p>
        </blockquote>
        <span>{node.attrs.author}</span>
      </Container>
    </NodeViewWrapper>
  )
}
