import styled from 'styled-components'

export const TooltipContainer = styled.div`
  background: var(--gray-100);
  border: 0;
  border-radius: 0.125rem;
  font-family: 'Rubik';
  font-size: 0.75rem;
  line-height: 0.875rem;
  padding: 4px 8px;
  width: 265px;
  text-align: center;
  background-color: #171717;

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #ededed;
  }

  span {
    color: var(--gray-60);
  }
`
export const Arrow = styled.div`
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #171717;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
`
