import React from 'react'

export default function CloseButtonBlack(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333496 7C0.333496 10.6822 3.31794 13.6667 7.00016 13.6667C10.6824 13.6667 13.6668 10.6822 13.6668 7C13.6668 3.31778 10.6824 0.333336 7.00016 0.333336C3.31794 0.333336 0.333496 3.31778 0.333496 7ZM9.48935 4.5107C9.70631 4.72766 9.70631 5.07942 9.48935 5.29638L7.78588 6.99983L9.48935 8.70329C9.70631 8.92025 9.70631 9.27201 9.48935 9.48897C9.2724 9.70593 8.92064 9.70593 8.70368 9.48897L7.0002 7.78551L5.29673 9.48897C5.07977 9.70593 4.72801 9.70593 4.51106 9.48897C4.2941 9.27201 4.2941 8.92025 4.51106 8.70329L6.21453 6.99983L4.51106 5.29638C4.2941 5.07942 4.2941 4.72766 4.51106 4.5107C4.72801 4.29374 5.07977 4.29374 5.29673 4.5107L7.0002 6.21416L8.70368 4.5107C8.92064 4.29374 9.2724 4.29374 9.48935 4.5107Z"
        fill="#171717"
      />
    </svg>
  )
}
