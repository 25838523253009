import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useImmerReducer } from 'use-immer'
import { TableColumn } from 'react-data-table-component'
import { PlusCircle, Trash } from '@somostera/tera-icons'

import { articlesReducer, State } from '../../reducers/articles'

import { getDateInformation } from 'core/utils/date'
import { ConfirmationModal as RemoveArticleConfirmationModal } from 'core/components/ConfirmationModal'
import { DataTableBase } from 'core/components/DataTableBase'
import { FilterTable } from 'core/components/DataTableBase/FilterTable'
import { Loading } from 'core/components/Animation/Loading'
import {
  AddNewContentButton,
  Container,
  ContentsHeader,
  LoadingContainer,
  MenuTypeContainer,
  RemoveButton
} from './styles'
import { OptionMenuButton } from 'core/components/OptionMenuButton'

import { Article, ArticleDraft, ArticleStatus, Chapter, InitializeArticleService } from '@somostera/tera-database'
import { db } from 'core/config/firebase'
import { ContainerCell } from 'core/components/DataTable/ContainerCell'
import { TypeCell } from 'core/components/DataTable/TypeCell'

import { useContent } from 'modules/contents/hooks/useContent'
import { useNetwork } from '../../../network/hooks/useNetwork'

const articleDraftService = InitializeArticleService.initialize(db)

export function Contents() {
  const [isConfirmRemoveArticleModalOpen, setIsConfirmRemoveArticleModalOpen] = useState(false)
  const [articleToBeRemoved, setArticleToBeRemoved] = useState('')
  const [filterText, setFilterText] = useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const [isActive, setActive] = useState(false)

  const { getExpertsNameByParticipantsIds } = useContent()

  const { findAllArticles } = useNetwork()

  const [{ isLoading, allArticleDrafts }, setState] = useImmerReducer(articlesReducer, {
    isLoading: true,
    currentArticleDraft: {} as ArticleDraft,
    currentArticleName: '',
    currentChapterIndex: 0,
    chapterToBeRemoved: -1,
    currentChapter: {} as Chapter,
    allArticleDrafts: [] as ArticleDraft[],
    lastStatusAndSavingDate: ''
  } as State)

  const handleOpenConfirmRemoveArticleModal = useCallback((articleId?: string) => {
    if (articleId) {
      setIsConfirmRemoveArticleModalOpen(true)
      setArticleToBeRemoved(articleId)
    }
  }, [])

  const handleCloseConfirmRemoveArticleModal = useCallback(() => {
    setIsConfirmRemoveArticleModalOpen(false)
  }, [])

  const handleRemoveArticle = useCallback(async () => {
    setState({ type: 'ASYNC_CALL' })
    await articleDraftService.delete(articleToBeRemoved)
    setState({ type: 'ASYNC_CALL_SUCCEED' })
    fetchAllArticles()
    setIsConfirmRemoveArticleModalOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleToBeRemoved])

  const getLastUpdateDate = (updatedAt) => {
    if (!updatedAt) {
      return ''
    }
    const { currentDay, day, hours, minutes, month, year } = getDateInformation(updatedAt)
    return (!currentDay ? `${day}.${month + 1}.${year} - ` : '') + ` ${hours}h${minutes}`
  }

  const fetchAllArticles = async () => {
    setState({ type: 'ASYNC_CALL' })

    if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
      articleDraftService
        .articleMostRecentVersion()
        .then(async (articles: any) => {
          if (!articles) {
            setState({
              type: 'ASYNC_CALL_FAILED',
              payload: { error: 'Something went wrong when fetching the articles.' }
            })
            return
          }
          const articlesDraft: ArticleDraft[] = articles as ArticleDraft[]
          setState({ type: 'SET_CURRENT_ALL_ARTICLES_DRAFTS', payload: { articles: articlesDraft } })
        })
        .catch(() =>
          setState({
            type: 'ASYNC_CALL_FAILED',
            payload: { error: 'Something went wrong when fetching the articles.' }
          })
        )
    } else {
      const articles = await findAllArticles('')

      setState({ type: 'SET_CURRENT_ALL_ARTICLES_DRAFTS', payload: { articles: articles } })
    }
  }

  useEffect(() => {
    fetchAllArticles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ArticleTypesColors = [
    {
      type: 'class',
      name: 'Aula',
      color: 'var(--yellow-pastel)'
    },
    {
      type: 'challenge',
      name: 'Desafio',
      color: 'var(--blue-pastel)'
    },
    {
      type: 'CLASS',
      name: 'Aula',
      color: 'var(--yellow-pastel)'
    },
    {
      type: 'CHALLENGE',
      name: 'Desafio',
      color: 'var(--blue-pastel)'
    },
    {
      type: 'REPLAY',
      name: 'Replay',
      color: 'var(--yellow-pastel)'
    },
    {
      type: 'BRANDED_CHALLENGE',
      name: 'Desafio',
      color: 'var(--blue-pastel)'
    },
    {
      type: 'TUTORIAL',
      name: 'Tutorial',
      color: 'var(--yellow-pastel)'
    },
    {
      type: 'CASE',
      name: 'Caso',
      color: 'var(--blue-pastel)'
    },
    {
      type: 'ARTICLE',
      name: 'Artigo',
      color: 'var(--yellow-pastel)'
    },
    {
      type: 'PODCAST',
      name: 'Podcast',
      color: 'var(--blue-pastel)'
    },
    {
      type: 'NARRATIVE',
      name: 'Narrativa',
      color: 'var(--yellow-pastel)'
    },
    {
      type: 'INTERNAL',
      name: 'Interno',
      color: 'var(--blue-pastel)'
    },
    {
      type: 'DEBATE',
      name: 'Debate (ou talk)',
      color: 'var(--yellow-pastel)'
    }
  ]

  const columns: TableColumn<Article | ArticleDraft | any>[] = [
    {
      name: 'Nome',
      selector: ({ name }: Article | ArticleDraft) => name,
      cell: (article: Article | ArticleDraft) => <Link to={`/contents/${article.id}`}>{article.name}</Link>,
      minWidth: '20rem'
    },
    {
      name: 'Tipo',
      selector: ({ type }) => type,
      sortable: true,
      cell: (article: Article | ArticleDraft) => (
        <ContainerCell url={`/contents/${article.id}`}>
          {' '}
          <TypeCell types={ArticleTypesColors} currentType={article.type || 'class'} />{' '}
        </ContainerCell>
      ),
      width: '8rem'
    },
    {
      name: 'Status',
      selector: ({ status }: Article | ArticleDraft) => status,
      cell: (article: Article | ArticleDraft) => (
        <span>{article.status === ArticleStatus.PUBLISHED ? 'Publicado' : 'Rascunho'}</span>
      ),
      width: '8rem'
    },
    {
      name: 'Experts',
      selector: ({ participantsids, participantsIds }) => JSON.stringify(participantsids, participantsIds),
      cell: (article) => (
        <span>
          {getExpertsNameByParticipantsIds(article?.participantsids || article?.participantsIds)
            .map((expertInfo) => expertInfo.name)
            .join(', ') || 'Nenhum'}
        </span>
      ),
      width: '13rem'
    },
    {
      name: 'Último update',

      cell: (article: Article | ArticleDraft | any) => (
        <span>{getLastUpdateDate(new Date(article.createdat || article.createdAt))}</span>
      ),

      width: '10rem'
    },
    {
      cell: (article: Article | ArticleDraft) => (
        <RemoveButton type="button" onClick={() => handleOpenConfirmRemoveArticleModal(article.id)}>
          <Trash size={16} color="var(--red)" />
        </RemoveButton>
      ),
      center: true,
      width: '5rem'
    }
  ]

  const filteredItems = useMemo(() => {
    if (allArticleDrafts) {
      return allArticleDrafts.filter(
        (item) =>
          JSON.stringify({
            name: item.name
          })
            .toLowerCase()
            .indexOf(filterText.toLowerCase()) !== -1
      )
    } else {
      return []
    }
  }, [filterText, allArticleDrafts])

  const toggleClass = () => {
    setActive(!isActive)
  }

  const SubHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    return (
      <FilterTable
        placeholder="Busque por nome"
        onFilter={(event) => setFilterText(event.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    )
  }, [filterText, resetPaginationToggle])

  const menuOptions = [
    {
      name: 'Aula',
      link: `/contents/new/class`
    },
    {
      name: 'Desafio',
      link: `/contents/new/challenge`
    },
    {
      name: 'Replay',
      link: `/contents/new/replay`
    },
    {
      name: 'Desafio de Mercado',
      link: `/contents/new/branded_challenge`
    },
    {
      name: 'Projeto/tutorial',
      link: `/contents/new/tutorial`
    },
    {
      name: 'Estudo de Caso',
      link: `/contents/new/case`
    },
    {
      name: 'Artigo',
      link: `/contents/new/article`
    },
    {
      name: 'Podcast',
      link: `/contents/new/podcast`
    },
    {
      name: 'Narrativa da Jornada',
      link: `/contents/new/narrative`
    },
    {
      name: 'Interno',
      link: `/contents/new/internal`
    },

    {
      name: 'Debate (ou talk)',
      link: `/contents/new/debate`
    }
  ]

  return (
    <>
      <RemoveArticleConfirmationModal
        confirmButtonText="Remover"
        confirmButtonColor="var(--red)"
        cancelButtonText="Cancelar"
        messageTitle="Remover Artigo?"
        messageDescription="Essa ação não poderá ser revertida"
        onRequestClose={handleCloseConfirmRemoveArticleModal}
        isOpen={isConfirmRemoveArticleModalOpen}
        onCancel={handleCloseConfirmRemoveArticleModal}
        onConfirm={handleRemoveArticle}
      />

      <Container>
        <ContentsHeader>
          <h1>Conteúdos</h1>

          <section>
            <AddNewContentButton onClick={toggleClass}>
              <PlusCircle color="var(--white)" size={20} />
              Conteúdo
            </AddNewContentButton>
            {isActive && (
              <MenuTypeContainer>
                <OptionMenuButton title="Tipos" options={menuOptions} />
              </MenuTypeContainer>
            )}
          </section>
        </ContentsHeader>

        {isLoading && (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        )}
        <DataTableBase
          noDataComponent={`${isLoading ? 'Carregando...' : 'Nenhum dado encontrado'}`}
          columns={columns}
          data={filteredItems || []}
          subHeader
          subHeaderComponent={SubHeaderComponent}
        />
      </Container>
    </>
  )
}
