import React, { FormEvent, useRef, useState } from 'react'
import { Button } from '../../../../core/components/Button'

interface ExpertTipProps {
  message: string
  expertId: string
  expertName: string
  expertPhoto: string
  file: string
  updateAttributes: (attrs: { message?: string }) => void
}

export default function ExpertTip({ message, expertPhoto, file, updateAttributes }: ExpertTipProps) {
  const [modifiedMessage, setModifiedMessage] = useState(message)

  const contentEditableRef = useRef<HTMLDivElement>(null)

  function handleInputChange(e: FormEvent<HTMLDivElement>) {
    setModifiedMessage(e.currentTarget.textContent || '')
  }

  function handleInputSave() {
    updateAttributes({ message: modifiedMessage || '' })
  }

  return (
    <>
      <div className={'my-12 expert-tip-area' + (expertPhoto ? ' place-with-expert' : '')}>
        <div>
          <span>DICA DE EXPERT:</span>
          <div
            contentEditable
            onInput={handleInputChange}
            onBlur={handleInputSave}
            ref={contentEditableRef}
            suppressContentEditableWarning={true}
            className="border p-2"
          >
            {message}
          </div>
        </div>
      </div>
      {expertPhoto && (
        <>
          <div className="arrow-right" />
          <img className="expert-image" src={expertPhoto} alt={expertPhoto} />
        </>
      )}
      {file && (
        <div className="file-area">
          <a href={file} target="_blank" rel="noreferrer" className="file-link">
            download/view arquivo
          </a>
        </div>
      )}
    </>
  )
}
