import React from 'react'

export default function RemoveCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333008 7.00004C0.333008 10.6823 3.31745 13.6667 6.99967 13.6667C10.6819 13.6667 13.6663 10.6823 13.6663 7.00004C13.6663 3.31782 10.6819 0.333374 6.99967 0.333374C3.31745 0.333374 0.333008 3.31782 0.333008 7.00004ZM9.48886 4.51074C9.70582 4.7277 9.70582 5.07946 9.48886 5.29642L7.78539 6.99987L9.48886 8.70333C9.70582 8.92029 9.70582 9.27205 9.48886 9.48901C9.27191 9.70596 8.92015 9.70597 8.70319 9.48901L6.99972 7.78554L5.29624 9.48901C5.07928 9.70597 4.72752 9.70596 4.51057 9.48901C4.29361 9.27205 4.29361 8.92029 4.51057 8.70333L6.21404 6.99987L4.51057 5.29642C4.29361 5.07946 4.29361 4.7277 4.51057 4.51074C4.72752 4.29378 5.07928 4.29378 5.29624 4.51074L6.99972 6.2142L8.70319 4.51074C8.92015 4.29378 9.27191 4.29378 9.48886 4.51074Z"
        fill="#0D4D05"
      />
    </svg>
  )
}
