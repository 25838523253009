import React from 'react'

export default function Drag(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.5 4C3.60457 4 4.5 3.10457 4.5 2C4.5 0.895431 3.60457 0 2.5 0C1.39543 0 0.5 0.895431 0.5 2C0.5 3.10457 1.39543 4 2.5 4Z"
        fill="currentColor"
      />
      <path
        d="M2.5 11C3.60457 11 4.5 10.1046 4.5 9C4.5 7.89543 3.60457 7 2.5 7C1.39543 7 0.5 7.89543 0.5 9C0.5 10.1046 1.39543 11 2.5 11Z"
        fill="currentColor"
      />
      <path
        d="M4.5 16C4.5 17.1046 3.60457 18 2.5 18C1.39543 18 0.5 17.1046 0.5 16C0.5 14.8954 1.39543 14 2.5 14C3.60457 14 4.5 14.8954 4.5 16Z"
        fill="#171717"
      />
      <path
        d="M9.5 4C10.6046 4 11.5 3.10457 11.5 2C11.5 0.895431 10.6046 0 9.5 0C8.39543 0 7.5 0.895431 7.5 2C7.5 3.10457 8.39543 4 9.5 4Z"
        fill="currentColor"
      />
      <path
        d="M11.5 9C11.5 10.1046 10.6046 11 9.5 11C8.39543 11 7.5 10.1046 7.5 9C7.5 7.89543 8.39543 7 9.5 7C10.6046 7 11.5 7.89543 11.5 9Z"
        fill="currentColor"
      />
      <path
        d="M9.5 18C10.6046 18 11.5 17.1046 11.5 16C11.5 14.8954 10.6046 14 9.5 14C8.39543 14 7.5 14.8954 7.5 16C7.5 17.1046 8.39543 18 9.5 18Z"
        fill="currentColor"
      />
    </svg>
  )
}
