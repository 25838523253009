import React, { forwardRef, ButtonHTMLAttributes, Ref, HTMLAttributes } from 'react'
import {
  StyledAccordion,
  StyledItem,
  StyledHeader,
  StyledTrigger,
  StyledContent,
  StyledContentText,
  StyledChevron
} from './styles'

export const Accordion = StyledAccordion
export const AccordionItem = StyledItem

interface AccordionTriggerProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: any
}

export const AccordionTrigger = forwardRef(
  ({ children, ...props }: AccordionTriggerProps, forwardedRef: Ref<HTMLButtonElement>) => {
    return (
      <StyledHeader>
        <StyledTrigger {...props} ref={forwardedRef}>
          {children}
          <StyledChevron className="chevronIcon" aria-hidden />
        </StyledTrigger>
      </StyledHeader>
    )
  }
)

interface AccordionContentProps extends HTMLAttributes<HTMLDivElement> {
  children?: any
}

export const AccordionContent = forwardRef(
  ({ children, ...props }: AccordionContentProps, forwardedRef: Ref<HTMLDivElement>) => {
    return (
      <StyledContent {...props} ref={forwardedRef}>
        <StyledContentText>{children}</StyledContentText>
      </StyledContent>
    )
  }
)
