import styled from 'styled-components'

export const Container = styled.div`
  margin: 2rem auto;
  width: 90%;
  position: relative;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-20);
  margin-bottom: 2rem;

  > div {
    > span {
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      color: var(--gray-60);
      font-size: 0.8rem;
      display: block;
    }
    > h1 {
      margin-bottom: 1rem;
      text-transform: uppercase;
    }
  }
`

export const SubHeaderContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
  align-items: center;
  flex: 1;
`

export const LoadingContainer = styled.div`
  width: 100%;
  height: 90%;
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  z-index: 4;
`

export const SyncMediaContainer = styled.div`
  display: flex;
  margin: 0 6rem 4.6rem 0;
  position: absolute;
  bottom: 0;
  right: 0;
`
