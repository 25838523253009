import React from 'react'

export default function CloudUploadBluePurple(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M23 36.4167V23"
        stroke="url(#paint0_linear_893_5548)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8467 26.8336L23.0001 22.6802L27.1535 26.8336"
        stroke="url(#paint1_linear_893_5548)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.6671 36.4165H35.5737C39.2691 36.4165 42.2821 33.4035 42.2821 29.7081C42.2821 26.0128 39.2691 22.9998 35.5737 22.9998H34.74V21.0831C34.74 14.739 29.5842 9.58313 23.24 9.58313C17.5341 9.58313 12.8037 13.7576 11.9067 19.2105C7.35083 19.4425 3.71875 23.178 3.71875 27.7915C3.71875 32.5544 7.58083 36.4165 12.3437 36.4165H15.3337"
        stroke="url(#paint2_linear_893_5548)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_893_5548"
          x1="23"
          y1="23"
          x2="24.1525"
          y2="23.0131"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5100FF" />
          <stop offset="0.0001" stopColor="#1DEAFB" />
          <stop offset="0.890625" stopColor="#8F00FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_893_5548"
          x1="18.8467"
          y1="22.6802"
          x2="27.6053"
          y2="25.3533"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5100FF" />
          <stop offset="0.0001" stopColor="#1DEAFB" />
          <stop offset="0.890625" stopColor="#8F00FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_893_5548"
          x1="3.71875"
          y1="9.58313"
          x2="46.1272"
          y2="18.8837"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5100FF" />
          <stop offset="0.0001" stopColor="#1DEAFB" />
          <stop offset="0.890625" stopColor="#8F00FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
