import React from 'react'

import { X, CheckCircleFilled } from '@somostera/tera-icons'

import { GreenWarningModal } from './styles'

interface GreenWarningModalationModalProps {
  onRequestClose: () => void
  text: string
}

export function GreenWarningModalationModal({ onRequestClose, text }: GreenWarningModalationModalProps) {
  return (
    <GreenWarningModal>
      <section />
      <div>
        <CheckCircleFilled size={24} color="var(--green-80)" />

        <p>{text}</p>
      </div>

      <button type="button" onClick={onRequestClose}>
        <X color="var(--gray-40)" size={35} />
      </button>
    </GreenWarningModal>
  )
}
