import styled from 'styled-components'

export const GreenWarningModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--green-pastel);
  padding: 0.5rem 1rem;
  width: calc(100% - 16.875rem);
  position: fixed;
  z-index: 10;

  div {
    font-size: 18px;
    display: flex;

    > p {
      margin-left: 1rem;
    }

    > svg {
    }
  }

  button {
    background: transparent;
  }
`
