import styled, { css } from 'styled-components'

export const Header = styled.header`
  padding: 20px 60px;
  padding-top: 0;
  // height: 121px;
  // position: fixed;
  //background-color: red;
  // width: 100%;

  h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 100%;

    letter-spacing: -0.01em;
    text-transform: uppercase;

    color: #000000;
    margin-bottom: 4px;
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #707070;
  }

  div {
    display: flex;
    gap: 8px;
    margin-top: 16px;

    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      background-color: green;
      border-radius: 16px;
      padding: 4px 16px;

      background: #ffffff;
      /* lightTheme/hiContrast */

      border: 1px solid #000000;
      border-radius: 16px;

      &:first-child {
        background-color: #000000;
        color: #fff;
      }
    }
  }
`

interface NavbarProps {
  showEdit: boolean
}

export const Navbar = styled.nav<NavbarProps>`
  display: flex;
  background: var(--neutral2);
  padding: 0px 60px 0px 60px;
  height: 48px;

  ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    width: 100%;

    section {
      display: flex;

      :nth-of-type(2) {
        gap: 8px;
        align-items: center;
      }

      a {
        height: 40px;
        padding: 8px 24px;
        font-family: 'Inter';
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 900;
        line-height: 24px;
        background-color: #8ffe81;
        background-color: ${(props) => (props.showEdit ? '#DCF8D8' : '#8ffe81')};
        cursor: ${(props) => (props.showEdit ? 'not-allowed;' : 'pointer')};

        :first-child {
          background-color: ${(props) => (props.showEdit ? '#858585' : '#000')};
          color: var(--white);
        }
      }
    }
  }
`

export const Content = styled.div`
  min-height: calc(100vh - 215.6px);
  background: #ededed;

  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  box-sizing: border-box;
  margin-top: 209px;
  position: relative;
`
interface ListItemActiveProps {
  active: boolean
  showEdit?: boolean
  datasheetActive?: boolean
  contentActive?: boolean
}

export const ListItem = styled.li<ListItemActiveProps>`
  text-decoration: none;
  padding-bottom: ${({ active }) => (active ? '4px' : '8px')};
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${({ active }) => (active ? 900 : 400)};
  font-size: 14px;
  line-height: 16px;

  text-transform: uppercase;

  ${({ datasheetActive }) =>
    datasheetActive &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    `}

  ${({ contentActive }) =>
    contentActive &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    `}
  /* cursor: ${(props) => (props.showEdit ? 'not-allowed;' : 'pointer')}; */

  color: #000000;

  border-bottom: ${({ active }) => (active ? '4px #8FFE81 solid' : 'none')};
  cursor: pointer;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  &:first-child {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 20px;
  }
`

export const CardContainer = styled.div``

export const HeaderContainer = styled.div`
  position: fixed;
  width: calc(100% - 240px);
  top: 60px;
  background: #fff;
  z-index: 4;
  height: 149px;

  nav {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`
export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;

  h1 {
    color: #000;
    z-index: 3;
  }
`
