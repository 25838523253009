import 'react-contexify/dist/ReactContexify.css'
import React from 'react'
import { NodeViewProps } from '@tiptap/core'
import axios from 'axios'
import { saveAs } from 'file-saver'
import { Menu, Item, useContextMenu, ItemParams, theme } from 'react-contexify'
import { ArrowDownload } from '@somostera/tera-icons'

import { Container } from './styles'

import { NodeViewWrapper } from '@tiptap/react'

type FileProps = {
  fileName: string
  url: string
  mimeType: string
}

const MENU_ID = 'download-file-component-size'

interface ItemProps {
  id: string
}

export function FileDownloadComponent({ node, updateAttributes }: NodeViewProps) {
  const { show } = useContextMenu({
    id: `${MENU_ID}-${node.attrs.fileName}`
  })

  const displayMenu = (event: React.MouseEvent) => {
    event.preventDefault()
    show(event, {
      props: {
        id: event?.currentTarget?.id
      }
    })
  }

  const handleItemClick = ({ event }: ItemParams<ItemProps>) => {
    updateAttributes({ componentSize: event.currentTarget.id })
  }

  const handleDownloadFile = async ({ url, fileName, mimeType }: FileProps) => {
    try {
      axios({
        url,
        responseType: 'blob',
        method: 'GET'
        // onDownloadProgress(progress) {
        //   console.log('download progress', progress)
        // }
      }).then((response) => {
        const blob = new Blob([response.data], {
          type: mimeType
        })
        saveAs(blob, fileName)
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <NodeViewWrapper>
      <Menu id={`${MENU_ID}-${node.attrs.fileName}`} theme={theme.dark}>
        <Item id="small" onClick={handleItemClick}>
          Pequeno
        </Item>
        <Item id="large" onClick={handleItemClick}>
          Grande
        </Item>
      </Menu>
      <Container className="file-download-component" onContextMenu={displayMenu} size={node.attrs.componentSize}>
        <div>
          <p>{node.attrs.fileName}</p>
          <span>{node.attrs.fileSize} MB</span>
        </div>
        <button
          onClick={() =>
            handleDownloadFile({
              fileName: node.attrs.fileName,
              mimeType: node.attrs.mimeType,
              url: node.attrs.url
            })
          }
        >
          <ArrowDownload color="var(--white)" />
        </button>
      </Container>
    </NodeViewWrapper>
  )
}
