import React from 'react'

export default function LeftArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.312951 0.97976C0.508213 0.784497 0.824795 0.784497 1.02006 0.97976L3.68672 3.64643C3.88199 3.84169 3.88199 4.15827 3.68672 4.35353L1.02006 7.0202C0.824795 7.21546 0.508213 7.21546 0.312951 7.0202C0.117688 6.82494 0.117688 6.50836 0.312951 6.31309L2.62606 3.99998L0.312951 1.68687C0.117688 1.4916 0.117688 1.17502 0.312951 0.97976Z"
        fill="black"
      />
    </svg>
  )
}
