import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Container } from './styles'

export function EmptyCard({ index, listIndex, move, data }) {
  const ref = useRef()

  const [{ isDragging }, dragRef] = useDrag({
    type: 'CARD',
    item: {
      index,
      listIndex
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  const [, dropRef] = useDrop({
    accept: 'CARD',
    hover(item: any, monitor) {
      const draggedListIndex = item.listIndex
      const targetListIndex = listIndex

      const draggedIndex = item.index
      const targetIndex = index

      if (draggedIndex === targetIndex && draggedListIndex === targetListIndex) return

      console.log({
        draggedIndex,
        targetIndex,
        draggedListIndex,
        targetListIndex
      })

      const targetSize = ref.current.getBoundingClientRect()
      const targetCenter = (targetSize.bottom - targetSize.top) / 2

      const draggedOffset = monitor.getClientOffset()
      const draggedTop = draggedOffset.y - targetSize.top

      if (draggedIndex < targetIndex && draggedTop < targetCenter) return
      if (draggedIndex > targetIndex && draggedTop > targetCenter) return

      move(draggedListIndex, targetListIndex, draggedIndex, targetIndex)
      item.index = targetIndex
      item.listIndex = targetListIndex
    }
  })

  dragRef(dropRef(ref))

  return (
    <Container ref={ref} isDragging={isDragging}>
      <div>
        <p>
          Ainda não há aulas adicionadas a esse curso. Escolha um conteúdo na biblioteca ao lado e arraste aqui para
          adicionar.
        </p>
      </div>
    </Container>
  )
}
