import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useImmerReducer } from 'use-immer'
import { ChevronLeft } from '@somostera/tera-icons'

import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { Underline as UnderlineExtension } from '@tiptap/extension-underline'
import { Blockquote } from '@tiptap/extension-blockquote'
import { Image } from '@tiptap/extension-image'
import { Link as LinkExtension } from '@tiptap/extension-link'
import { Typography } from '@tiptap/extension-typography'
import { CodeBlockLowlight } from '@tiptap/extension-code-block-lowlight'
import { lowlight } from 'lowlight'

import { ArticleDraft, InitializeArticleService, Chapter } from '@somostera/tera-database'
import { db } from 'core/config/firebase'

import { PageBreak } from 'modules/contents/components/Nodes/PageBreak'
import { PromptBoxModule } from 'modules/contents/components/Nodes/PromptBox'
import { Video } from 'modules/contents/components/Nodes/Video'
import { FileDownload } from 'modules/contents/components/Nodes/FileDownload'
import { Quote } from 'modules/contents/components/Nodes/Quote'
import { ColouredCardList } from 'modules/contents/components/Nodes/ColouredCardList'

import { articlesReducer, State } from 'modules/contents/reducers/articles'

import { Container } from './styles'
import {ExpertTipModule} from "../../../components/Nodes/ExpertTip";

export function ContentPreview() {
  const navigate = useNavigate()
  const { articleId, chapterIndex } = useParams()

  const [, setState] = useImmerReducer(articlesReducer, {
    isLoading: true,
    currentArticleDraft: {} as ArticleDraft,
    currentArticleName: '',
    currentChapterIndex: 0,
    chapterToBeRemoved: -1,
    currentChapter: {} as Chapter,
    lastStatusAndSavingDate: ''
  } as State)

  const articleDraftService = InitializeArticleService.initialize(db)

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        bulletList: { HTMLAttributes: { class: 'editor-preview-bullet-list' } }
      }),
      UnderlineExtension,
      Blockquote,
      LinkExtension.configure({ HTMLAttributes: { class: 'editor-preview-link' } }),
      PageBreak,
      PromptBoxModule,
      ExpertTipModule,
      Video,
      Image.configure({ HTMLAttributes: { class: 'editor-preview-image' } }),
      Typography,
      FileDownload,
      Quote.configure({ isPreview: true }),
      CodeBlockLowlight.configure({
        lowlight
      }),
      ColouredCardList
    ],
    editorProps: {
      attributes: {
        class: 'editor'
      }
    }
  })

  useEffect(() => {
    setState({ type: 'ASYNC_CALL' })
    articleDraftService.findById(articleId!).then((articleDraft) => {
      if (!articleDraft) {
        setState({ type: 'ASYNC_CALL_FAILED', payload: { error: 'Article not found.' } })
        return
      }
      setState({ type: 'FETCH_CURRENT_ARTICLE_DRAFT', payload: { article: articleDraft } })
      setState({ type: 'UPDATE_CURRENT_CHAPTER', payload: { index: Number(chapterIndex!), editor } })
      // editor?.setEditable(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleId!, chapterIndex!, editor])

  if (!editor) {
    return <div />
  }

  return (
    <Container>
      <div>
        <button type="button" onClick={() => navigate(-1)}>
          <ChevronLeft color="var(--gray-100)" size={8} />
          Voltar
        </button>
        <EditorContent className="preview-container" editor={editor} />
      </div>
    </Container>
  )
}
