import React from 'react'

export default function Play(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 2.74105C0 1.19747 1.67443 0.235725 3.00774 1.01349L12.0231 6.27246C13.3461 7.04421 13.3461 8.95582 12.0231 9.72757L3.00774 14.9865C1.67443 15.7643 0 14.8026 0 13.259V2.74105ZM11.0154 8.00001L2 2.74105V13.259L11.0154 8.00001Z"
        fill="currentColor"
      />
    </svg>
  )
}
