import { Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { ExpertTipComponent } from './ExpertTipComponent'

export interface ExpertTipOptions {
  message: string
  expertId: string
  expertName: string
  expertPhoto: string
  file: string
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    addExpertTip: {
      addExpertTip: (options: ExpertTipOptions) => ReturnType
    }
  }
}

export const ExpertTipModule = Node.create<ExpertTipOptions>({
  name: 'custom-expert-tip-box',
  group: 'block',
  atom: true,
  addOptions() {
    return {
      message: '',
      expertId: '',
      expertName: '',
      expertPhoto: '',
      file: ''
    }
  },
  draggable: true,
  addAttributes() {
    return {
      message: {
        default: null
      },
      expertId: {
        default: ''
      },
      expertName: {
        default: ''
      },
      expertPhoto: {
        default: ''
      },
      file: {
        default: ''
      }
    }
  },
  parseHTML() {
    return [
      {
        tag: 'div'
      }
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['div', HTMLAttributes]
  },
  addCommands() {
    return {
      addExpertTip:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options
          })
        }
    }
  },
  addNodeView() {
    return ReactNodeViewRenderer(ExpertTipComponent)
  }
})
