import type { FullMetadata, FirebaseStorage, TaskState, UploadMetadata } from 'firebase/storage'
import { getDownloadURL, getMetadata, getStorage, ref, uploadBytesResumable } from 'firebase/storage'

import { firebaseApp } from './firebase'
import { MediaType } from '@somostera/tera-models-ts'

import * as Sentry from '@sentry/browser'

const imagesStorage = getStorage(firebaseApp, process.env.REACT_APP_FIREBASE_IMAGE_STORAGE)
const videosStorage = getStorage(firebaseApp, process.env.REACT_APP_FIREBASE_VIDEO_STORAGE)
const downloadsStorage = getStorage(firebaseApp, process.env.REACT_APP_FIREBASE_DOWNLOAD_STORAGE)

export interface UploadResult {
  fileDownloadUrl: string
  pathToFile: string
}

export class TeraStorage {
  constructor(storage: FirebaseStorage) {
    this.storage = storage
  }

  storage: FirebaseStorage
  currentUploadProgressPercentage = 0
  currentUploadStatus?: TaskState

  protected async uploadFile(path: string, file: File, fileName: string): Promise<UploadResult> {
    const contentType = file.type

    const pathToFile = path + '/' + fileName

    const fileDownloadUrl = await this.getFileDownloadUrl(pathToFile)

    const fileRef = ref(this.storage, pathToFile)

    if (fileDownloadUrl) {
      // eslint-disable-next-line no-throw-literal
      throw {
        code: '@somostera/firebase-storage/file-already-exists',
        message: `O arquivo ${pathToFile} já existe`,
        customData: { fileDownloadUrl, pathToFile }
      }
    }

    const metadata: UploadMetadata = {
      contentType
    }

    const uploadTask = uploadBytesResumable(fileRef, file, metadata)

    return new Promise((resolve) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(progress)
          localStorage.setItem('storage', String(progress))
          window.dispatchEvent(new Event('storage'))
          this.currentUploadProgressPercentage = progress
          this.currentUploadStatus = snapshot.state
          switch (snapshot.state) {
            case 'paused':
              break
            case 'running':
              break
          }
        },
        (error) => {
          alert(error.message)
          this.currentUploadProgressPercentage = 0
          throw error
          Sentry.captureException(error)
        },
        () => {
          getDownloadURL(fileRef).then((fileDownloadUrl) => {
            this.currentUploadProgressPercentage = 0
            resolve({ fileDownloadUrl, pathToFile })
          })
        }
      )
    })
  }

  async uploadMedia(file: File, fileType: MediaType, fileName: string): Promise<UploadResult> {
    return this.uploadFile(await this.getBucketFolder(fileType), file, fileName)
  }

  private async getBucketFolder(fileType: MediaType): Promise<string> {
    switch (fileType) {
      case MediaType.VIDEO:
        return BucketFolders.publicVideo
      case MediaType.THUMBNAIL_VIDEO:
        return BucketFolders.publicThumbnailVideo
      default:
        return BucketFolders.publicMedias
    }
  }

  async getFileDownloadUrl(path: string): Promise<string | undefined> {
    const fileRef = ref(this.storage, path)

    try {
      return await getDownloadURL(fileRef)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      switch (error.code) {
        case 'storage/object-not-found': {
          return undefined
        }
        default: {
          throw error
        }
      }
    }
  }

  async getFileMetadata(path: string): Promise<FullMetadata> {
    const fileRef = ref(this.storage, path)
    return await getMetadata(fileRef)
  }
}

enum BucketFolders {
  medias = 'medias',
  publicMedias = 'public/media',
  publicVideo = 'public/videos',
  publicThumbnailVideo = 'public/thumbVideos'
}

export class ImageStorage extends TeraStorage {
  constructor() {
    super(imagesStorage)
  }
}

export class VideoStorage extends TeraStorage {
  constructor() {
    super(videosStorage)
  }
}

export class DownloadStorage extends TeraStorage {
  constructor() {
    super(downloadsStorage)
  }
}
