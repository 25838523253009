import styled from 'styled-components'

interface SidebarNavProps {
  isSidebarOpen?: boolean
}

export const SidebarNav = styled.nav<SidebarNavProps>`
  background: var(--black);
  display: flex;
  height: 100vh;
  justify-content: center;
  min-width: ${({ isSidebarOpen }) => (isSidebarOpen ? '15rem' : '5rem')};
  position: fixed;
  top: 0;
  transition: 350ms;
  width: ${({ isSidebarOpen }) => (isSidebarOpen ? '15rem' : '5rem')};
  z-index: 11;
`

export const SidebarWrap = styled.div`
  width: 100%;
`

export const SidebarHeader = styled.div`
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: flex-start;
  padding: 1.125rem 1.125rem 1.5rem 1.5rem;

  p {
    color: var(--gray-100);
    font-weight: bold;
    margin: 0 1rem;
    text-transform: uppercase;
  }

  button {
    align-items: center;
    background: var(--gray-20);
    border-radius: 0.25rem;
    display: flex;
    height: 1.5rem;
    justify-content: center;
    width: 1.5rem;
  }
`

export const SidebarFooter = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;

  border-top: 1px solid #3d3d3d;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 10px;
  width: 100%;
  max-width: 240px;
  height: 80px;
  background: #000000;

  border-top: 1px solid #3d3d3d;

  div:first-of-type {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`
export const ProfileImage = styled.section`
  align-items: center;
  background: var(--blue-primary);
  color: var(--white);
  border-radius: 50%;
  display: flex;
  font-size: 1.25rem;
  font-weight: 700;
  height: 2rem;
  justify-content: center;
  min-height: 2rem;
  min-width: 2rem;
  width: 2rem;

  img {
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
  }
`
export const ProfileData = styled.div`
  width: 8.25rem;
  display: flex;
  flex-direction: column;

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;

    color: #ededed;

    &:last-child {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;

      color: #a1a1a1;
      margin-top: 2px;
    }
  }
`
