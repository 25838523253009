/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react'
import {
  Container,
  BoardAside,
  Content,
  FilterContiner,
  MoreFiltersContainer,
  CheckboxContainer,
  ButtonFilterContainer,
  ButtonFilter,
  ResetFilterContainer,
  ResetButton,
  LoadingContainer
} from './styles'
import { CardDrop } from '../CardDrop'
import { CoursesService } from 'modules/courses/services/courses'
import { useAuth } from 'core/hooks/useAuth'
import { useParams } from 'react-router-dom'
import produce from 'immer'
import { FilterTable } from 'modules/courses/components/DataTableBase/FilterTable'
import Doc from 'core/components/Icon/Doc'
import LeftArrow from 'core/components/Icon/LeftArrow'
import { Checkbox } from 'core/components/Checkbox'
import { EmptyCard } from '../EmptyCard'
import { CareersType } from '@somostera/tera-database'
import CloseButtonBlack from 'core/components/Icon/CloseButtonBlack'
import CloseButtonWhite from 'core/components/Icon/CloseButtonWhite'
import { Aside } from '../Aside'

export interface Course {
  id: string
  name: string
  description: string
  career: string[]
  status: string
  visibility: string
  updatedAt: string
}

interface FilterOption {
  [key: string]: boolean
}

interface Filters {
  [key: string]: FilterOption
}

interface Dictionary {
  [key: string]: string
}

interface BoardProps {
  handleAddOrRemoveItems: (items: []) => void
}

export function Board({ handleAddOrRemoveItems }: BoardProps) {
  const moreFiltersOptionsInitialValue = {
    'Tipo de conteúdo': {
      class: false,
      challenge: false,
      replay: false,
      'branded-challenge': false,
      tutorial: false,
      case: false,
      article: false,
      podcast: false,
      narrative: false,
      'questions-answers': false,
      internal: false,
      debate: false
    }
  }

  const typeDictionary: Dictionary = {
    class: 'Aula',
    challenge: 'Desafio',
    replay: 'Replay',
    'branded-challenge': 'Desafio de mercado',
    tutorial: 'Projeto/tutorial',
    case: 'Estudo de caso',
    article: 'Artigo',
    podcast: 'Podcast',
    narrative: 'Narrativa da jornada',
    'questions-answers': 'Perguntas e respostas',
    internal: 'Interno',
    debate: 'Debate (ou talk)'
  }

  const { userFirebase } = useAuth()
  const { courseId } = useParams()
  const [allArticles, setAllArticles] = useState([])
  const [cardLists, setCardLists] = useState([])
  const [filterText, setFilterText] = useState('')

  const [moreFiltersOptions, setMoreFiltersOptions] = useState<Filters>(moreFiltersOptionsInitialValue)
  const [filtersSelected, setFiltersSelected] = useState<string[]>([])
  const [filterToShow, setFilterToShow] = useState<string[]>([])
  const [articlesFiltered, setArticlesFiltered] = useState<Course[]>([])
  const [popoverChange, setPopoverChange] = useState(false)
  const [indexToShow, setIndexToShow] = useState<number[]>([])
  const [showTagsFilter, setShowTagsFilter] = useState(false)
  const [showAside, setShowAside] = useState(false)
  const [articleShowAside, setArticleShowAside] = useState(null)
  const [courseItems, setCourseItems] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  function move(fromList: number, toList: number, from: number, to: number) {
    setCardLists(
      produce(cardLists, (draft) => {
        const dragged = draft[fromList][from]

        draft[fromList].splice(from, 1)
        draft[toList].splice(to, 0, dragged)
      })
    )
  }

  function swap(json) {
    const ret = {}
    for (const key in json) {
      ret[json[key]] = key
    }
    return ret
  }

  function deleteCard(indexCard: number) {
    setCardLists(
      produce(cardLists, (draft) => {
        const cardToDelete = draft[1][indexCard]
        draft[1].splice(indexCard, 1)
        draft[0].unshift(cardToDelete)
      })
    )
  }

  async function getItemsCourse(id?: string | undefined) {
    if (!id) return []
    const token = await userFirebase.getIdToken()

    try {
      const response = await CoursesService.getCourseItemById(token, id)
      setCourseItems(response.data)

      const orderedResponse = response.data.sort((a, b) => {
        if (a.index > b.index) {
          return 1
        }
        if (a.index < b.index) {
          return -1
        }
        return 0
      })

      const articledMaped = await Promise.all(
        orderedResponse.map(async (item) => {
          if (item.itemType === 'article') {
            const article = await CoursesService.getArticleById(token, item.articleRef)
            return article.data
          }
          return null
        })
      )

      const articleFiltered = articledMaped.filter((article) => article !== null)

      return articleFiltered
    } catch (error) {
      console.log(error)
      return []
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    async function getCards() {
      const token = await userFirebase.getIdToken()
      const allArticles = await CoursesService.getArticles(token)
      const articleFiltered = await getItemsCourse(courseId)

      const filteredArticles = allArticles.data.filter((article) => {
        return !articleFiltered.some((filteredArticle) => filteredArticle.name === article.name)
      })

      setCardLists([filteredArticles, articleFiltered])
      setAllArticles(allArticles.data)
      setArticlesFiltered(articleFiltered)
    }
    getCards()
  }, [])

  const handleClear = () => {
    setShowTagsFilter(false)
    setFilterText('')
    setMoreFiltersOptions(moreFiltersOptionsInitialValue)
    setFiltersSelected([])
    setFilterToShow([])
    setArticlesFiltered([])

    const filteredArticles = allArticles.filter((article) => {
      return !cardLists[1].some((filteredArticle) => filteredArticle.name === article.name)
    })
    setCardLists(
      produce(cardLists, (draft) => {
        draft[0] = filteredArticles
      })
    )
  }

  function addOrRemoveIndexToShow(index: number) {
    if (indexToShow.includes(index)) {
      const newIndexToShow = indexToShow.filter((indexInArray) => index !== indexInArray)
      setIndexToShow(newIndexToShow)
    } else {
      setIndexToShow((prevState) => [...prevState, index])
    }
  }

  function getSelectedOptions(formated = false) {
    const selectedOptions = []

    for (const key in moreFiltersOptions) {
      if (Object.prototype.hasOwnProperty.call(moreFiltersOptions, key)) {
        const subObj = moreFiltersOptions[key]
        for (const subKey in subObj) {
          if (Object.prototype.hasOwnProperty.call(subObj, subKey)) {
            if (subObj[subKey]) {
              selectedOptions.push(subKey)
            }
          }
        }
      }
    }

    const selectedOptionsFormated = selectedOptions.map((option) => {
      if (typeDictionary[option]) return typeDictionary[option]
      return option
    })

    if (formated) return selectedOptionsFormated

    return selectedOptions
  }

  const handleCheckFilter = (newValue: boolean, filter: string, itemLabel: string) => {
    const newFilterBlock = moreFiltersOptions[filter]
    newFilterBlock[itemLabel] = newValue

    if (newValue) {
      setFiltersSelected([...filtersSelected, filter === 'Carreiras' ? itemLabel : itemLabel.toLowerCase()])
    } else {
      const newFilters = filtersSelected.filter(
        (filterSelected) => filterSelected.toLowerCase() !== itemLabel.toLowerCase()
      )
      setFiltersSelected(newFilters)
    }

    setMoreFiltersOptions({
      ...moreFiltersOptions,
      [filter]: newFilterBlock
    })
  }

  const handleApplyFilter = () => {
    setFilterToShow(getSelectedOptions(true))
    setShowTagsFilter(true)
    handleFilterArticle(filtersSelected)
    setPopoverChange(!popoverChange)
  }

  function handleFilterArticle(filtersSelected: string[]) {
    let mapArticle = []
    if (filtersSelected.length > 0) {
      allArticles.forEach((article) => {
        article.editorial?.careers?.some((filter) => {
          if (filtersSelected.includes(CareersType[filter])) {
            mapArticle.push(article)
            return true
          }
          return false
        })
      })
    } else {
      mapArticle = [...allArticles]
    }

    if (mapArticle.length === 0) mapArticle = [...allArticles]

    const typeSelected = filtersSelected.filter((filter) => typeDictionary[filter])

    if (typeSelected.length > 0) {
      const newArticles = mapArticle.filter((article) => typeSelected.includes(article.type))
      if (!newArticles.length) {
        alert('Não foi encontrado cursos para o filtro selecionado')
        handleClear()
        return
      }
      setCardLists(
        produce(cardLists, (draft) => {
          draft[0] = newArticles
        })
      )
    } else {
      setCardLists(
        produce(cardLists, (draft) => {
          draft[0] = mapArticle
        })
      )
    }
  }

  const handleDeleteFilter = (item: string) => {
    const newFilterToshow = filterToShow.filter((filter) => filter !== item)
    if (!newFilterToshow.length) {
      handleClear()
      return
    }

    let label = ''
    const wordArray = item.split(' ')
    wordArray.forEach((palavra) => {
      if (palavra) {
        const temp = palavra[0].toUpperCase() + palavra.substring(1)
        label = label + ' ' + temp
      }
    })

    const newSwapTypeDictionary = swap(typeDictionary)

    const type = newSwapTypeDictionary[item]

    type ? handleCheckFilter(false, 'Tipo de conteúdo', type) : handleCheckFilter(false, 'Carreiras', item)

    setFilterToShow(newFilterToshow)
  }

  function handleShowAside(article: object) {
    setShowAside(!showAside)
    setArticleShowAside(article)
  }

  useEffect(() => {
    if (cardLists.length > 0 && filterText.length > 0) {
      const articlesFilterdByText = cardLists[0].map((articles) => {
        const isMatch = articles.name.toLowerCase().includes(filterText)
        if (isMatch) return articles
        return undefined
      })

      const newArticles = articlesFilterdByText.filter((articles) => articles !== undefined)

      setCardLists(
        produce(cardLists, (draft) => {
          draft[0] = newArticles
        })
      )
    } else if (filterText.length === 0 && cardLists.length > 0 && filtersSelected.length > 0) {
      handleFilterArticle(filtersSelected)
    } else if (filterText.length === 0 && cardLists.length > 0 && filtersSelected.length === 0) {
      setCardLists(
        produce(cardLists, (draft) => {
          draft[0] = allArticles
        })
      )
    }
  }, [filterText])

  useEffect(() => {
    if (cardLists.length > 0) {
      handleAddOrRemoveItems(cardLists[1])
    }
  }, [cardLists, handleAddOrRemoveItems])

  useEffect(() => {
    if (showTagsFilter) {
      handleFilterArticle(filtersSelected)
    }
  }, [filtersSelected])

  if (isLoading) {
    return (
      <LoadingContainer>
        <span>Carregando...</span>
      </LoadingContainer>
    )
  }

  return (
    <>
      {showAside && <Aside handleShowAside={handleShowAside} articleShowAside={articleShowAside} />}
      <Container>
        <BoardAside>
          <FilterContiner>
            <FilterTable
              placeholder="Buscar conteúdo"
              onFilter={(event) => setFilterText(event.target.value)}
              onClear={handleClear}
              showResetButton={false}
              filterText={filterText}
              hasMoreFilters
              onPopoverChange={popoverChange}
              numberOfSelectedFilters={filtersSelected.length}
              MoreFiltersComponent={
                <MoreFiltersContainer>
                  <section>
                    <Doc />
                    Filtrar por
                  </section>
                  {Object.entries(moreFiltersOptions).map(([title, items], index) => (
                    <div key={title}>
                      <h3 key={title} onClick={() => addOrRemoveIndexToShow(index)}>
                        {' '}
                        <LeftArrow />
                        {title}
                      </h3>
                      <CheckboxContainer showFilterActive={indexToShow.includes(index)}>
                        {Object.entries(items).map(([itemLabel, checked]) => (
                          <>
                            {indexToShow.includes(index) && (
                              <Checkbox
                                key={itemLabel}
                                checked={checked}
                                labelText={
                                  typeDictionary[itemLabel]
                                  // title === 'Tipo de conteúdo' ? typeDictionary[itemLabel] : dataDictionary[itemLabel]
                                }
                                onChange={(event) => handleCheckFilter(event.target.checked, title, itemLabel)}
                              />
                            )}
                          </>
                        ))}
                      </CheckboxContainer>
                    </div>
                  ))}
                  <ButtonFilterContainer>
                    <ButtonFilter onClick={handleApplyFilter}>Aplicar filtros</ButtonFilter>
                  </ButtonFilterContainer>
                </MoreFiltersContainer>
              }
            />
          </FilterContiner>

          {showTagsFilter && filterToShow.length > 0 && (
            <ResetFilterContainer>
              {filterToShow.map((item, index) => (
                <p key={index}>
                  {item}
                  <CloseButtonBlack onClick={() => handleDeleteFilter(item)} />
                </p>
              ))}
              <ResetButton>
                Limpar todos os filtros <CloseButtonWhite onClick={handleClear} />
              </ResetButton>
            </ResetFilterContainer>
          )}

          <section>
            {cardLists.length > 0 &&
              cardLists[0].length > 0 &&
              cardLists[0].map((card, index) => (
                <CardDrop
                  key={index}
                  index={index}
                  listIndex={0}
                  move={move}
                  data={card}
                  handleShowAside={handleShowAside}
                  article={card}
                />
              ))}
          </section>
        </BoardAside>
        <Content>
          {cardLists.length > 0 && cardLists[1].length > 0 ? (
            cardLists[1].map((card, index) => (
              <CardDrop
                key={index}
                listIndex={1}
                index={index}
                move={move}
                data={card}
                deleteCard={deleteCard}
                handleShowAside={handleShowAside}
                article={card}
                items={courseItems}
              />
            ))
          ) : (
            <EmptyCard listIndex={1} index={0} move={move} />
          )}
        </Content>
      </Container>
    </>
  )
}
