import styled from 'styled-components'

interface StatusContainerProps {
  color: string
}

export const StatusContainer = styled.div<StatusContainerProps>`
  align-items: center;
  display: flex;

  > p {
    color: ${(props) => props.color};
    margin-left: 0.4rem;
  }
`
