import { doc, getDoc } from 'firebase/firestore'
import { Person, GenericConverter } from '@somostera/tera-models-ts'
import { db } from 'core/config/firebase'

const PEOPLE_COLLECTION_NAME = 'people'

export const getPersonById = async (personId: string): Promise<Person> => {
  const personQuerySnapshot = await getDoc(
    doc(db, PEOPLE_COLLECTION_NAME, personId).withConverter(new GenericConverter<Person>())
  )

  if (!personQuerySnapshot.exists()) {
    throw new Error('Sem pessoa com esse id')
  }

  return personQuerySnapshot.data()
}
