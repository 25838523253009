import React from 'react'

export default function ExclamationMarkTriangleFilled(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0002 11.9999C10.4479 11.9999 10.0002 11.5522 10.0002 10.9999V7.99988C10.0002 7.4476 10.4479 6.99988 11.0002 6.99988C11.5525 6.99988 12.0002 7.4476 12.0002 7.99988V10.9999C12.0002 11.5522 11.5525 11.9999 11.0002 11.9999Z"
        fill="currentColor"
      />
      <path
        d="M9.50017 14.4999C9.50017 13.6715 10.1717 12.9999 11.0002 12.9999C11.8286 12.9999 12.5002 13.6715 12.5002 14.4999C12.5002 15.3283 11.8286 15.9999 11.0002 15.9999C10.1717 15.9999 9.50017 15.3283 9.50017 14.4999Z"
        fill="currentColor"
      />
      <path
        d="M9.23033 1.21548C9.98016 -0.209192 12.0202 -0.209201 12.77 1.21548L21.1136 17.0684C21.8146 18.4002 20.8488 19.9999 19.3438 19.9999H2.65653C1.15151 19.9999 0.185739 18.4002 0.886697 17.0684L9.23033 1.21548ZM19.3438 17.9999L11.0002 2.14697L2.65653 17.9999L19.3438 17.9999Z"
        fill="currentColor"
      />
    </svg>
  )
}
