import CheckCircle from 'core/components/Icon/CheckCircle'
import CheckCircleOrange from 'core/components/Icon/CheckCircleOrange'
import UndoCircle from 'core/components/Icon/UndoCircle'
import { TagContainer } from './styles'
import React from 'react'

interface TagProps {
  type: string
}

export function Tag({ type }: TagProps) {
  return (
    <>
      {type === 'publicado' && (
        <TagContainer type={type}>
          <CheckCircle /> {type}
        </TagContainer>
      )}

      {type === 'rascunho' && (
        <TagContainer type={type}>
          <UndoCircle />
          {type}
        </TagContainer>
      )}

      {type === 'arquivado' && (
        <div>
          <CheckCircleOrange /> {type}
        </div>
      )}

      {type === 'deletado' && (
        <div>
          <CheckCircleOrange /> {type}
        </div>
      )}
    </>
  )
}
