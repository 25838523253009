import styled, { css } from 'styled-components'

export const Container = styled.div`
  background-color: #ededed;
  padding: 12px 20px 12px 14px;
  border-radius: 4px;

  gap: 12px;

  height: 100vh;

  div {
    background: #f2f2f2;
    padding: 12px 20px;
    border: 1px solid #dbdbdb;

    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
    }
  }

  ${(props) =>
    props.isDragging &&
    css`
      border: 2px dashed rgba(0, 0, 0, 0.2);
      padding-top: 31px;
      border-radius: 0;
      background: transparent;
      cursor: grabbing;
      p,
      span,
      img,
      header,
      h2,
      svg {
        opacity: 0;
      }
    `}
`
