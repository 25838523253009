import React from 'react'

export default function DownArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.979943 0.313134C1.1752 0.117871 1.49179 0.117871 1.68705 0.313134L4.00016 2.62625L6.31328 0.313134C6.50854 0.117871 6.82512 0.117871 7.02038 0.313134C7.21564 0.508396 7.21564 0.824978 7.02038 1.02024L4.35372 3.68691C4.15845 3.88217 3.84187 3.88217 3.64661 3.68691L0.979943 1.02024C0.784681 0.824978 0.784681 0.508396 0.979943 0.313134Z"
        fill="currentColor"
      />
    </svg>
  )
}
