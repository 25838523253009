import { useAuth } from 'core/hooks/useAuth'
import React from 'react'
import { Container } from './styles'

export const Home = () => {
  const { userFirebase } = useAuth()
  return (
    <Container>
      <h1>
        Olá, <span>{userFirebase.displayName}</span> 👋
      </h1>
    </Container>
  )
}
