import React from 'react'
import Modal from 'react-modal'
import { X } from '@somostera/tera-icons'

import { useForm } from 'core/hooks/useForm'

import { Input } from 'core/components/Input'
import { Button } from 'core/components/Button'

import { AddLinkContainer, ButtonContainer } from './styles'

interface AddLinkModalProps {
  isOpen: boolean
  onRequestClose: () => void
  onAddLink: (linkAddress: string) => void
}

interface AddLinkModalForm {
  linkAddress: string
}

export function AddLinkModal({ isOpen, onAddLink, onRequestClose }: AddLinkModalProps) {
  const {
    data: { linkAddress },
    errors,
    handleChange,
    handleSubmit,
    setValue
  } = useForm<AddLinkModalForm>({
    initialValues: { linkAddress: '' },
    onSubmit: () => {
      if (!linkAddress) return
      onAddLink(linkAddress)
      onRequestClose()
      setValue('linkAddress', '')
    },
    validations: {
      linkAddress: {
        required: { value: true, message: 'Preencha o campo obrigatório.' }
      }
    }
  })

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <AddLinkContainer onSubmit={handleSubmit}>
        <button type="button" onClick={onRequestClose} className="react-modal-close">
          <X color="var(--gray-40)" size={24} />
        </button>

        <Input
          type="url"
          name="link"
          label="Link"
          erasable={true}
          placeholder="Adicione a URL"
          onChange={handleChange('linkAddress')}
          value={linkAddress}
          errors={errors.linkAddress}
          onResetValue={() => setValue('linkAddress', '')}
        />

        <ButtonContainer>
          <Button type="submit">Adicionar Link</Button>
        </ButtonContainer>
      </AddLinkContainer>
    </Modal>
  )
}
