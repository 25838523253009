import { OrderedList, OrderedListOptions } from '@tiptap/extension-ordered-list'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { ColouredCardListComponent } from './ColouredCardListComponent'

export interface ColouredCardListOptions extends OrderedListOptions {
  backgroundColor: string
  foregroundColor: string
  HTMLAttributes: Record<string, any>
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    colouredCardList: {
      /** Toggle a coloured card list */
      toggleColouredCardList: () => ReturnType
    }
  }
}

export const ColouredCardList = OrderedList.extend<ColouredCardListOptions>({
  defaultOptions: {
    ...OrderedList.options,
    HTMLAttributes: {
      class: 'coloured-card-list'
    },
    backgroundColor: 'white',
    foregroundColor: 'black'
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      backgroundColor: {
        default: 'white'
      },
      foregroundColor: {
        default: 'black'
      }
    }
  },
  addCommands() {
    return {
      toggleColouredCardList:
        () =>
        ({ commands }) =>
          commands.toggleList('orderedList', 'listItem')
    }
  },
  addNodeView() {
    return ReactNodeViewRenderer(ColouredCardListComponent)
  }
})
