import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AutosizeInput } from 'modules/contents/components/datasheet/AutosizeInput'
import { Input } from 'modules/contents/components/datasheet/Input'
import { MultiSelect } from 'modules/contents/components/datasheet/MultiSelect'
import { CareersType } from '@somostera/tera-database'
import ToggleSwitch from 'modules/contents/components/datasheet/ToggleSwitch'

import { CoursesService } from 'modules/courses/services/courses'
import { useAuth } from 'core/hooks/useAuth'

import { Loading } from 'core/components/Animation/Loading'

import { Container, FormContainer, ToggleSwitchContainer, Footer, LoadingContainer } from './styles'
import { Modal } from 'modules/courses/components/Modal'

// const CategoryType = {
//   PRODUCT: 'Product',
//   BUSINESS: 'Business',
//   LEADERSHIP: 'Leadership',
//   DATA: 'Data',
//   DESIGN: 'Design',
//   TECHNOLOGY: 'Technology',
//   MARKETING: 'Marketing',
//   AI: 'AI'
// }

export function AddCourse() {
  const navigate = useNavigate()
  const { userFirebase } = useAuth()
  const [title, setTitle] = useState<string | undefined>('')
  const [description, setDescription] = useState('')
  const [alertCard, setAlertCard] = useState(false)

  // const categoryOptions = Object.keys(CategoryType).map((key) => ({
  //   value: key,
  //   label: CategoryType[key]
  // }))
  // const [category, setCategory] = useState<string[] | undefined>([])

  const [isChecked, setIsChecked] = useState(false)
  const [isVisible, setIsVisible] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const handleCheck = () => {
    setIsChecked(!isChecked)
  }

  useEffect(() => {
    setIsLoading(false)
  }, [])

  useEffect(() => {
    isChecked ? setIsVisible('Público') : setIsVisible('Privado')
  }, [isChecked])

  async function handleSave() {
    setIsLoading(true)

    const data = {
      name: title,
      description,
      // categories: category?.map((career) => career.label),
      visibility: isChecked ? 'public' : 'private'
    }

    try {
      const token = await userFirebase.getIdToken()
      await CoursesService.saveCourse(token, data)
      alert('curso criado com sucesso')
      navigate('/courses')
    } catch (error) {
      alert('Campos obrigatórios precisam ser preenchidos.')
      setAlertCard(true)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleCancel() {
    try {
      navigate(`/courses`)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    )
  }

  return (
    <>
      <Container>
        <section>
          <h2>Novo Curso</h2>

          <FormContainer>
            <h3>Novo Curso</h3>

            <Input
              type="text"
              name="title"
              label="TÍTULO DO CONTEÚDO"
              placeholder="Escreva o título do conteúdo"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              required
            />

            <AutosizeInput
              type="text"
              name="description"
              label="Descrição"
              placeholder="Descrição breve do que é este conteúdo"
              minHeight={'6.75rem'}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              required
            />

            {/* <MultiSelect options={categoryOptions} onChange={setCategory} value={category} label="Categorias" required /> */}

            <ToggleSwitchContainer>
              <h3>Visibilidade</h3>
              <div>
                <ToggleSwitch onClick={handleCheck} />
                <span>{isVisible}</span>
              </div>
            </ToggleSwitchContainer>
          </FormContainer>
        </section>
      </Container>
      <Footer>
        <button onClick={handleCancel}>Cancelar</button>
        <button onClick={handleSave}>Salvar</button>
      </Footer>
    </>
  )
}
