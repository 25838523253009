import styled from 'styled-components'

export const Container = styled.div`
  > button {
    align-items: center;
    background: var(--black);
    border-radius: 0.25rem;
    color: var(--white);
    display: flex;
    font-weight: bold;
    font-size: 0.875rem;
    height: 2rem;
    justify-content: space-between;
    line-height: 120%;
    margin-top: 1.5rem;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    width: 8rem;
  }

  .rdt_Table {
    > div {
      max-width: 35rem;
    }
  }
`
