// import { useCallback, useEffect, useState } from 'react'
// import { useLocation, useNavigate } from 'react-router'
// import { NavigationBlocker } from './NavigationBlocker'

export function useNavigatingAway(canShowDialogPrompt?: any, excludedRoutes?: string[]) {
  // FUNCTION IN MAINTEINANCE
  let test = () => 'test'

  if (!canShowDialogPrompt && !excludedRoutes) {
    test = () => 'test'
  }

  return [false, test, test]

  // const navigate = useNavigate()
  // const currentLocation = useLocation()
  // const [showDialogPrompt, setShowDialogPrompt] = useState(false)
  // const [wantToNavigateTo, setWantToNavigateTo] = useState(null as any)
  // const [isNavigationConfirmed, setIsNavigationConfirmed] = useState(false)
  // const handleNavigationBlocking = useCallback(
  //   (locationToNavigateTo) => {
  //     if (!isNavigationConfirmed && locationToNavigateTo.location.pathname !== currentLocation.pathname) {
  //       setShowDialogPrompt(true)
  //       setWantToNavigateTo(locationToNavigateTo)
  //       return false
  //     }
  //     return true
  //   },
  //   [currentLocation.pathname, isNavigationConfirmed]
  // )
  // const cancelNavigation = useCallback(() => {
  //   setIsNavigationConfirmed(false)
  //   setShowDialogPrompt(false)
  // }, [])
  // const confirmNavigation = useCallback(() => {
  //   setIsNavigationConfirmed(true)
  //   setShowDialogPrompt(false)
  // }, [])
  // useEffect(() => {
  //   if (isNavigationConfirmed && wantToNavigateTo) {
  //     navigate(wantToNavigateTo.location.pathname)
  //     return
  //   }
  //   const freeRoutes = excludedRoutes.some((item) => wantToNavigateTo?.location.pathname.includes(item))
  //   if (wantToNavigateTo && freeRoutes && currentLocation.pathname !== wantToNavigateTo.location.pathname) {
  //     setShowDialogPrompt(false)
  //     navigate(wantToNavigateTo.location.pathname)
  //   }
  // }, [isNavigationConfirmed, navigate, wantToNavigateTo, excludedRoutes, currentLocation])
  // NavigationBlocker(handleNavigationBlocking, canShowDialogPrompt)
  // return [showDialogPrompt, confirmNavigation, cancelNavigation]
}
