import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  /* border-radius: 0.25rem;
  max-width: 500px;
  width: 100%;
  padding: 2rem 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; */

  svg {
    margin-bottom: 6px;
  }

  h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 4px;
    color: #171717;
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 20px;
    text-align: center;
  }

  div {
    display: flex;
    justify-content: space-between;

    button {
      padding: 12px 24px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 900;
      font-size: 14px;
      line-height: 20px;

      letter-spacing: 0.01em;
      text-transform: uppercase;

      color: #000000;
      border-radius: 0;

      &:first-child {
        background: var(--white);
        color: #000;
        margin-right: 8px;
        border: 2px solid #000000;
      }

      &:last-child {
        border: 2px solid #8ffe81;
        background: #8ffe81;
        color: #000;
      }
    }
  }
`
