import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 5rem;

  > section {
    width: 35rem;
    > h1 {
      font-size: 0.85rem;
      margin: 0;
      margin-top: 2rem;
    }
    h4 {
      font-size: 0.75rem;
      color: var(--gray-60);
      top: 24;
    }
    > hr {
      background: var(--gray-20);
      height: 0.125rem;
      margin-top: 2.25rem;
      width: 35rem;
    }
  }
`
export const AddFilesPanel = styled.div``

export const Label = styled.label`
  color: var(--gray-60);
  display: block;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.25rem;
  text-transform: uppercase;
  margin: 1.5rem 0 0.25rem;
`
export const RemoveMediaButton = styled.button`
  align-items: center;
  background: transparent;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  transition: opacity 0.2s;

  /* svg {
    opacity: 0;
  }

  &:hover svg {
    opacity: 30;
  } */
`
export const EditMediaButton = styled.button`
  align-items: center;
  background: transparent;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  transition: opacity 0.2s;

  /* svg {
    opacity: 0;
  } */

  /* &:hover svg {
    opacity: 30;
  } */
`
