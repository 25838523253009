import React from 'react'
import Lottie from 'lottie-react'

import LoadingAnimation from './loading.json'

interface LoadingProps {
  height?: number
  width?: number
}

export function Loading({ height = 80, width = 80 }: LoadingProps) {
  return (
    <div style={{ height, width }}>
      <Lottie animationData={LoadingAnimation} loop autoPlay />
    </div>
  )
}
