import * as Sentry from '@sentry/browser'

export const loadVideo = (file: File): Promise<HTMLVideoElement> =>
  new Promise((resolve, reject) => {
    try {
      const video = document.createElement('video')

      video.src = window.URL.createObjectURL(file)
      video.preload = 'metadata'

      video.onerror = (e) => {
        Sentry.captureException(e)
        reject(new Error('Invalid video. Please select a video file.'))
      }

      video.onloadedmetadata = () => {
        resolve(video)
      }
    } catch (e) {
      Sentry.captureException(e)
      reject(e)
    }
  })

export const getVideoDuration = async (file: File): Promise<number> => {
  const video = await loadVideo(file)
  return video.duration
}
