import React from 'react'

export default function Pen(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1493 1.82332C12.2464 1.72621 12.4046 1.72621 12.5017 1.82332L14.1777 3.49932C14.2748 3.59641 14.2748 3.7545 14.1777 3.85161L12.8399 5.18921L10.8117 3.161L12.1493 1.82332ZM9.25338 2.59795C9.2419 2.60811 9.23066 2.61869 9.21967 2.62967C9.20868 2.64066 9.19811 2.65191 9.18794 2.66339L0.764086 11.0872L0.763301 11.088C0.433496 11.4165 0.25 11.8619 0.25 12.325V15C0.25 15.4142 0.585786 15.75 1 15.75H3.675C4.13818 15.75 4.58359 15.5665 4.91206 15.2366L13.3558 6.7945C13.3607 6.78986 13.3655 6.78513 13.3703 6.78033C13.3751 6.77557 13.3798 6.77075 13.3844 6.76589L15.2383 4.91237C15.9212 4.22948 15.9212 3.12155 15.2383 2.43866L13.5623 0.762658C12.8794 0.079765 11.7716 0.079765 11.0887 0.762658L9.25338 2.59795ZM11.7791 6.24976L3.85072 14.1766L3.84926 14.1781C3.8038 14.2238 3.7415 14.25 3.675 14.25H1.75V12.325C1.75 12.2585 1.77621 12.1962 1.82191 12.1507L1.82333 12.1493L9.75099 4.22166L11.7791 6.24976Z"
        fill="white"
      />
    </svg>
  )
}
