import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { FileDownloadComponent } from './FileDownloadComponent'

export interface FileDownloadOptions {
  fileName: string
  url: string
  fileSize: number
  componentSize: 'small' | 'large'
  mimeType: string
  HTMLAttributes: Record<string, any>
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    'file-download': {
      /**
       * Add a file download component
       */
      setFileDownload: (options: FileDownloadOptions) => ReturnType
    }
  }
}

export const FileDownload = Node.create<FileDownloadOptions>({
  name: 'file-download',
  group: 'block',
  atom: true,
  defaultOptions: {
    fileName: '',
    mimeType: '',
    url: '',
    fileSize: 0,
    componentSize: 'small',
    HTMLAttributes: {}
  },
  addAttributes() {
    return {
      fileName: {
        default: ''
      },
      mimeType: {
        default: ''
      },
      url: {
        default: null
      },
      fileSize: {
        default: 0
      },
      componentSize: {
        default: 'small',
        rendered: false
      }
    }
  },
  parseHTML() {
    return [
      {
        tag: 'div'
      }
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },
  addCommands() {
    return {
      setFileDownload:
        (options) =>
        ({ commands }) =>
          commands.insertContent({
            type: this.name,
            attrs: options
          })
    }
  },
  addNodeView() {
    return ReactNodeViewRenderer(FileDownloadComponent)
  }
})
