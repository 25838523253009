import React from 'react'
import { ConfirmationModal as ExitWithoutSavingConfirmationModal } from '../ConfirmationModal'

export const DialogLeavingPage = ({ showDialog, cancelNavigation, confirmNavigation }: any) => {
  return (
    <ExitWithoutSavingConfirmationModal
      confirmButtonText="Sair mesmo assim"
      cancelButtonText="Voltar"
      messageType="YellowWarning"
      messageTitle="Deseja Sair?"
      messageDescription="As alterações na página não foram salvas."
      onRequestClose={cancelNavigation}
      isOpen={showDialog}
      onCancel={cancelNavigation}
      onConfirm={confirmNavigation}
    />
  )
}
