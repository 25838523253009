import { JourneyCastingData } from '../@types/casting'

export enum FeelingLuckyStatus {
  TO_BE_DONE = 'toBeDone',
  LOADING = 'loading',
  SUCCEED = 'succeed',
  DONE = 'done'
}

export interface State {
  isLoading: boolean
  teraId: string
  journeyCastingData: JourneyCastingData[]
  castingDataToUpdate: JourneyCastingData[]
  selectedRows: string[]
  error?: string
  feelingLuckyStatus?: FeelingLuckyStatus
}

type Action =
  | { type: 'ASYNC_CALL' }
  | { type: 'ASYNC_CALL_SUCCEED' }
  | { type: 'ASYNC_CALL_FAILED'; payload: { error: string } }
  | { type: 'FETCH_JOURNEY_CASTING_PAGE_DATA'; payload: { teraId: string; journeyCastingData: JourneyCastingData[] } }
  | { type: 'UPDATE_SELECTED_ROWS'; payload: { selectedRows: JourneyCastingData[] } }
  | { type: 'UPDATE_JOURNEY_CASTING_DATA'; payload: { journeyCastingData: JourneyCastingData[] } }
  | { type: 'UPDATE_JOURNEY_CASTING_DATA_ROW'; payload: { rowIndex: number; rowToUpdate: JourneyCastingData } }
  | { type: 'UPDATE_CASTING_DATA_TO_UPDATE'; payload: { rowIndex: number; rowToUpdate: JourneyCastingData } }
  | { type: 'ADD_CASTING_DATA_TO_UPDATE'; payload: { rowToUpdate: JourneyCastingData } }
  | { type: 'UPDATE_FEELING_LUCKY_STATUS'; payload: { status: FeelingLuckyStatus; error?: string } }

export const journeyCastingReducer = (draft: State, action: Action): State => {
  switch (action.type) {
    case 'ASYNC_CALL':
      draft.isLoading = true
      break
    case 'ASYNC_CALL_SUCCEED':
      draft.isLoading = false
      break
    case 'ASYNC_CALL_FAILED':
      draft.isLoading = false
      draft.error = action.payload.error
      break
    case 'FETCH_JOURNEY_CASTING_PAGE_DATA':
      draft.teraId = action.payload.teraId
      draft.journeyCastingData = action.payload.journeyCastingData
      break
    case 'UPDATE_SELECTED_ROWS':
      draft.selectedRows = action.payload.selectedRows.map((row) => row.journeyBlockId)
      break
    case 'UPDATE_JOURNEY_CASTING_DATA':
      draft.journeyCastingData = action.payload.journeyCastingData
      break
    case 'UPDATE_JOURNEY_CASTING_DATA_ROW':
      draft.journeyCastingData[action.payload.rowIndex] = action.payload.rowToUpdate
      break
    case 'UPDATE_CASTING_DATA_TO_UPDATE':
      draft.castingDataToUpdate[action.payload.rowIndex] = action.payload.rowToUpdate
      break
    case 'ADD_CASTING_DATA_TO_UPDATE':
      draft.castingDataToUpdate.push(action.payload.rowToUpdate)
      break
    case 'UPDATE_FEELING_LUCKY_STATUS':
      draft.feelingLuckyStatus = action.payload.status
      draft.error = action.payload.error
      break
  }
  return draft
}
