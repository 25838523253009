import styled from 'styled-components'

interface DropdownMenuContainerProps {
  width: string
}

export const Container = styled.div<DropdownMenuContainerProps>`
  position: relative;
  width: ${(props) => (props.width ? props.width : '4rem')};
`

export const OptionContainer = styled.div`
  p {
    color: var(--gray-100);
    font-family: Rubik;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  span {
    color: var(--gray-60);
    font-family: Rubik;
    font-size: 0.75rem;
    line-height: 1rem;
  }
`

interface OpenSelectButtonProps {
  width: string
}

export const OpenSelectButton = styled.button<OpenSelectButtonProps>`
  align-items: center;
  background: var(--white);
  border-color: transparent;
  border-radius: 0;
  color: var(--gray-100);
  display: flex;
  font-size: 0.75rem;
  justify-content: center;
  height: 100%;
  min-height: 1rem;
  position: absolute;
  width: ${(props) => (props.width ? props.width : '4rem')};
  z-index: 2;

  svg {
    margin-left: 0.25rem;
  }

  &:hover {
    border: white;
    cursor: pointer;
  }

  &:active {
    background: var(--gray-20);
  }

  &:disabled {
    opacity: 1;
    cursor: not-allowed;
    color: var(--gray-40);
  }
`
