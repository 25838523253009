import React, { useCallback, useEffect, useState } from 'react'
import { Button, Content, DatabaseContainer, FilterContainer, HeaderContainer } from './styles'
import { FilterTable } from 'modules/courses/components/DataTableBase/FilterTable'
import { PlusCircle } from '@somostera/tera-icons'
import { FormationsService } from 'modules/formations/services/formations'
import { useAuth } from 'core/hooks/useAuth'
import { DataTableBase } from 'modules/courses/components/DataTableBase'
import { TableColumn } from 'react-data-table-component'
import { ContainerCell } from 'modules/buildingblock/components/ContainerCell'
import { Tooltip } from 'modules/courses/components/Tooltip'
import { TagCareer } from 'modules/courses/views/Courses/styles'
import { Tag } from 'modules/courses/components/Tag'

export interface Formation {
  id: string
  name: string
  description: string
  career: string[]
  status: string
  visibility: string
  updatedAt: string
}

export function ViewFormation() {
  const { userFirebase } = useAuth()
  const [filterText, setFilterText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [formations, setFormations] = useState<Formation[]>([])
  const [formationsFiltered, setFormationsFiltered] = useState<Formation[]>([])
  const [filtersSelected, setFiltersSelected] = useState<string[]>([])
  const [filterToShow, setFilterToShow] = useState<string[]>([])
  const [popoverChange, setPopoverChange] = useState(false)

  async function getAllFormations() {
    setIsLoading(true)
    const token = await userFirebase.getIdToken()
    const response = await FormationsService.getAll(token)

    setFormations(response.data)
    setIsLoading(false)
  }

  useEffect(() => {
    getAllFormations()
  }, [])

  function visibilityNamed(name: string) {
    switch (name) {
      case 'published':
        return 'publicado'
      case 'draft':
        return 'rascunho'
      case 'archived':
        return 'arquivado'
      case 'deleted':
        return 'deletado'
    }
  }

  const formatDateToShow = useCallback((date: string) => {
    const data_hora = new Date(date)

    const formato = new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    })

    const data_formatada = formato.format(data_hora)
    return data_formatada.replace(',', ' às')
  }, [])

  const handleClear = () => {
    setFilterText('')
    setFiltersSelected([])
    setFilterToShow([])
    setFormationsFiltered([])
  }

  useEffect(() => {
    const coursesFilterdByText = formations.map((formation) => {
      const isMatch = formation.name.toLowerCase().includes(filterText)
      if (isMatch) return formation
      return undefined
    })

    const newFormations = coursesFilterdByText.filter((formation) => formation !== undefined)
    setFormationsFiltered(newFormations)
  }, [filterText])

  const columns: TableColumn<Formation>[] = [
    {
      name: 'Formação',
      selector: ({ name }) => name ?? '',
      sortable: true,
      cell: (row: Formation) => (
        <ContainerCell url={`/formations/${row.id}`}>
          <Tooltip title={row.description}>
            <p>{row.name}</p>
          </Tooltip>
        </ContainerCell>
      ),
      width: '244px'
    },
    {
      name: 'Carreiras',
      selector: ({ career }) => JSON.stringify(career),
      sortable: true,
      cell: (row: Formation) => (
        <ContainerCell url={`#`}>
          {row.career && row.career.length > 2 && (
            <>
              <TagCareer>
                <p>{typeof row.career[0] === 'string' ? row.career[0] : row.career[0].label}</p>
              </TagCareer>
              <TagCareer>
                <p>{`+ ${row.career.length - 1}`}</p>
              </TagCareer>
            </>
          )}
          {row.career &&
            row.career.length <= 2 &&
            row.career.map((career, index: number) => {
              const careerLabel = typeof career === 'string' ? career : career.label
              return (
                <TagCareer key={index}>
                  <p>{careerLabel}</p>
                </TagCareer>
              )
            })}
        </ContainerCell>
      ),
      width: '352px'
    },
    {
      name: 'Duração',
      selector: ({ carrer }) => JSON.stringify(carrer),
      sortable: true,
      cell: (row: Formation) => (
        <ContainerCell url={`#`}>
          {row.carrer && row.carrer.map((career, index: number) => <p key={index}>{career}</p>)}
        </ContainerCell>
      ),
      width: '160px'
    },
    {
      name: 'Última Atualização',
      selector: ({ updatedAt }) => JSON.stringify(updatedAt),
      sortable: true,
      cell: (row: Formation) => (
        <ContainerCell url={`#`}>{row.updatedAt ? formatDateToShow(row.updatedAt) : 'Não definido'}</ContainerCell>
      ),
      width: '204px'
    },

    {
      name: 'Status',
      selector: ({ status }) => status,
      sortable: true,
      cell: (row: Formation) => (
        <ContainerCell url={`#`}>
          <Tag type={`${visibilityNamed(row.status)}`} />
        </ContainerCell>
      ),
      width: '160px'
    }
  ]

  return (
    <>
      <HeaderContainer>
        <h2>Formações</h2>
      </HeaderContainer>
      <Content>
        <FilterContainer>
          <FilterTable
            placeholder="Buscar conteúdo"
            onFilter={(event) => setFilterText(event.target.value)}
            onClear={handleClear}
            showResetButton={false}
            filterText={filterText}
            onPopoverChange={popoverChange}
            numberOfSelectedFilters={filtersSelected.length}
          />

          <Button to={`/formations/new`}>
            <PlusCircle /> nova formação
          </Button>
        </FilterContainer>
        <DatabaseContainer>
          <DataTableBase
            columns={columns}
            data={formationsFiltered.length ? formationsFiltered : formations}
            pagination
            noDataComponent={`${isLoading ? 'Carregando...' : 'Nenhum dado encontrado'}`}
            selectableRowsNoSelectAll={true}
          />
        </DatabaseContainer>
      </Content>
    </>
  )
}
