import React, { ChangeEvent } from 'react'

import { JourneyCastingData } from '../../@types/casting'

import { Select } from 'core/components/Select'

import { Container } from './styles'

const expertStatus = [
  { value: 'aceito', name: 'Convite aceito' },
  { value: 'recusado', name: 'Convite recusado' },
  { value: 'enviar-convite', name: 'Enviar convite' },
  { value: 'selecionado', name: 'Expert selecionado' }
]

interface ExpertStatusCellProps {
  row: JourneyCastingData
  onUpdateCell: (event: ChangeEvent<HTMLSelectElement>) => void
}

export const ExpertStatusCell = ({ row, onUpdateCell }: ExpertStatusCellProps) => (
  <Container>
    <Select
      name="expertStatus"
      selectStyle={{
        background: 'transparent',
        border: 0,
        fontSize: '0.875rem',
        height: '1rem',
        minHeight: '1rem',
        paddingLeft: 0,
        width: '100%'
      }}
      items={expertStatus}
      onChange={onUpdateCell}
      selectedValue={row.expertStatus || 'default'}
    />
  </Container>
)
