import styled from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize'

export const Container = styled.div`
  padding: 2rem 3rem;

  > hr {
    margin-top: 5rem;
    border: 1px solid var(--gray-20);
  }
`

export const Question = styled(TextareaAutosize)`
  border: 0;
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.2rem;
  margin: 0;
  outline: 0;
  padding-left: 0;
  resize: none;
  text-transform: uppercase;
  width: 100%;

  &::placeholder {
    color: var(--gray-80);
  }
`

export const Description = styled(TextareaAutosize)`
  border: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.2rem;
  margin: 0;
  outline: 0;
  padding-left: 0;
  resize: none;
  width: 100%;
  margin-top: 0.3rem;

  &::placeholder {
    color: var(--gray-80);
  }
`

export const QuizInputContainer = styled.div`
  margin-top: 2.5rem;

  hr {
    margin: 2rem 0;
    border: 1px solid var(--gray-20);
  }

  div + div {
    margin-top: 2.5rem;
  }
`
export const ButtonContainer = styled.div`
  margin-top: 2.5rem;
`
export const NextButtonDisabledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
`
