/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { TableColumn } from 'react-data-table-component'
import { ExclamationMarkTriangleFilled, PlusCircle, Trash } from '@somostera/tera-icons'
import { PortfolioClassFormValues } from 'modules/network/@types/portfolio'

import { usePortfolio } from 'modules/network/hooks/usePortfolio'
import { useNetwork } from 'modules/network/hooks/useNetwork'
import { useForm } from 'core/hooks/useForm'
import { useNavigatingAway } from 'core/hooks/useNavigatingAway'
import { DownloadableItem } from '@somostera/tera-database'
import { Loading } from 'core/components/Animation/Loading'
import { LoadingContainer } from './styles'
import { FormatBlock } from '@somostera/tera-database'

import {
  ConfirmationModal as RemoveMediaConfirmationModal,
  ConfirmationModal as RemovePortfolioClassConfirmationModal
} from 'core/components/ConfirmationModal'

import {
  InformationModal as SuccessRemovePortfolioClassModal,
  InformationModal as SuccessSavedPortfolioClassModal
} from 'core/components/InformationModal'

import { Input } from 'core/components/Input'
import { Select } from 'core/components/Select'
import { AutosizeInput } from 'core/components/AutosizeInput'
import { AddMediaModal } from '../../components/AddFileModal'

import {
  Container,
  Header,
  Form,
  AddFilesPanel,
  RemoveClassButton,
  Label,
  RemoveMediaButton,
  NoMaterialsPanel
} from './styles'
import { DataTableBase } from 'core/components/DataTableBase'
import { DialogLeavingPage } from 'core/components/DialogLeavingPage'

type MediaInfo = {
  url: string
  fileSize?: number
  file: File
}

interface MediaData {
  id: string
  url: string
  name: string
  size: string
}

interface Dictionary {
  [key: string]: string
}

export const PortfolioClass = () => {
  const { classId } = useParams()
  const { personId } = useParams()
  const {
    currentPortfolioClass,
    personPortfolioTableData,
    setCurrentPortfolioClass,
    updateItemToPortfolio,
    deleteItemToPortfolio,
    isLoading
  } = usePortfolio()

  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false)
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] = useNavigatingAway(canShowDialogLeavingPage)

  const handleInputChange = (inputName: any) => (e: any) => {
    setValue(inputName, e.target.value)
    // value is asynchronic, so it's updated in the next render
    if (e.target.value !== undefined) setCanShowDialogLeavingPage(true)
    else {
      setCanShowDialogLeavingPage(false)
      handleChange(inputName)
    }
  }

  const navigate = useNavigate()
  const { networkPersonSelected, getPersonById } = useNetwork()
  const [isChange, setIsChange] = useState(false)

  const [fileToBeRemoved, setFileToBeRemoved] = useState('')
  const [files, setFiles] = useState<MediaData[]>([])
  const [isAddMediaModalOpen, setIsAddMediaModalOpen] = useState(false)
  const [isConfirmRemoveMediaModalOpen, setIsConfirmRemoveMediaModalOpen] = useState(false)
  const [isSuccessSavedPortfolioClassModalOpen, setIsSuccessSavedPortfolioClassModalOpen] = useState(false)
  const [isSuccessRemovePortfolioClassModalOpen, setIsSuccessRemovePortfolioClassModalOpen] = useState(false)
  const [isConfirmRemovePortfolioClassModalOpen, setIsConfirmRemovePortfolioClassModalOpen] = useState(false)
  const [fileDownload] = useState<DownloadableItem>()
  const [fileMediaData] = useState<MediaData>()
  const arrayFileDownload = [] as DownloadableItem[]
  // const arrayMediaData = [] as MediaData[]

  const statusDictionary: Dictionary = {
    'em-validacao': 'Em Validação',
    indisponivel: 'Indisponível',
    invalidada: 'Invalidada',
    validada: 'Validada'
  }

  const handleSaveForm = async () => {
    files.map((file) => {
      const fileD = { ...fileDownload } as DownloadableItem
      fileD.name = file.name
      fileD.sizeInMb = file.size
      fileD.type = 'file'
      fileD.url = file.url
      return arrayFileDownload.push(fileD)
    })

    data.downloads = arrayFileDownload

    const dataForm = {
      ...currentPortfolioClass,
      status: data.status,
      details: data.details,
      downloads: data.downloads,
      activityGuidance: data.activityGuidance
    }

    await updateItemToPortfolio(dataForm)
    setIsSuccessSavedPortfolioClassModalOpen(true)
    setCanShowDialogLeavingPage(false)

    setTimeout(() => navigate(`/network/person/${personId}/portfolio`), 2000)
  }

  useEffect(() => {
    if (personId && classId) {
      ;(async () => {
        await getPersonById(personId)
        await setCurrentPortfolioClass(personId, classId)
        setIsChange(false)
      })()
    }
  }, [personId, classId])

  const { data, handleChange, handleSubmit, setValue, setFullData } = useForm<PortfolioClassFormValues>({
    initialValues: {
      name: currentPortfolioClass.name || '',
      courseAcronym: currentPortfolioClass.courseAcronym || '',
      format: currentPortfolioClass.format || '',
      status: currentPortfolioClass.status || undefined,
      downloads: currentPortfolioClass.downloads || [],
      activityGuidance: currentPortfolioClass.activityGuidance || '',
      details: currentPortfolioClass.details || ''
    },
    onSubmit: handleSaveForm
  })

  useEffect(() => {
    setIsChange(true)
  }, [
    data.status || currentPortfolioClass.status,
    data.details || currentPortfolioClass.details,
    data.activityGuidance || currentPortfolioClass.activityGuidance
  ])

  useEffect(() => {
    if (currentPortfolioClass.id === classId) {
      files.length = 0
      if (currentPortfolioClass.downloads !== undefined) {
        currentPortfolioClass.downloads.map((file, index) => {
          const fileD = { ...fileMediaData } as MediaData
          fileD.id = index.toString()
          fileD.name = file.name
          fileD.size = file.sizeInMb
          fileD.url = file.url
          return files.push(fileD)
        })
      }

      setFullData({
        status: currentPortfolioClass.status,
        downloads: files,
        activityGuidance: currentPortfolioClass.activityGuidance,
        details: currentPortfolioClass.details
      })
    }
  }, [currentPortfolioClass])

  const columns: TableColumn<MediaData>[] = [
    {
      name: 'Nome',
      selector: ({ name }) => name,
      sortable: true,
      maxWidth: '21rem'
    },
    {
      name: 'Tamanho',
      selector: ({ size }) => `${size} MB`,
      sortable: true,
      width: '8rem'
    },
    {
      cell: (row: MediaData) => (
        <RemoveMediaButton type="button" onClick={() => handleOpenConfirmRemoveMediaModal(row.id)}>
          <Trash size={16} color="var(--red)" />
        </RemoveMediaButton>
      ),
      center: true,
      width: '1rem',
      maxWidth: '1rem'
    }
  ]

  const handleAddMedia = useCallback(
    ({ url, file, fileSize }: MediaInfo) => {
      const newFile: MediaData = {
        id: String(files.length),
        name: file.name,
        url,
        size: String(fileSize || 0)
      }

      setFiles([...files, newFile])
      setCanShowDialogLeavingPage(true)
      setIsChange(true)
    },
    [files]
  )

  const handleOpenConfirmRemoveMediaModal = useCallback((id?: string) => {
    if (id) {
      setIsConfirmRemoveMediaModalOpen(true)
      setFileToBeRemoved(id)
    }
  }, [])

  const handleCloseConfirmRemoveArticleModal = useCallback(async () => {
    setIsConfirmRemoveMediaModalOpen(false)
  }, [])

  const handleRemoveMedia = useCallback(() => {
    const newFiles = files.filter((file) => file.id !== fileToBeRemoved)

    setFiles(newFiles)
    setFileToBeRemoved('')
    setIsConfirmRemoveMediaModalOpen(false)
    setIsChange(true)
  }, [fileToBeRemoved, files])

  const handleOpenConfirmRemovePortfolioClassModal = () => {
    setIsConfirmRemovePortfolioClassModalOpen(true)
  }

  const handleRemovePortfolioClass = async () => {
    setIsConfirmRemovePortfolioClassModalOpen(false)

    if (classId && personId !== undefined) {
      await deleteItemToPortfolio(personId, classId)
      setIsSuccessRemovePortfolioClassModalOpen(true)
    }

    setTimeout(() => {
      handleCheckDataTableBase()
    }, 1000)
    setCanShowDialogLeavingPage(false)
  }

  const handleCheckDataTableBase = () => {
    if (personPortfolioTableData.length === 0) {
      navigate(`/network/person/${personId}`)
    } else {
      navigate(`/network/person/${personId}/portfolio`)
    }
  }

  const handleCloseConfirmRemovePortfolioClassModal = useCallback(() => {
    setIsConfirmRemovePortfolioClassModalOpen(false)
  }, [])

  return (
    <>
      {isLoading && (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}
      {classId !== currentPortfolioClass.id ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <Container>
          <AddMediaModal
            isOpen={isAddMediaModalOpen}
            onRequestClose={() => setIsAddMediaModalOpen(false)}
            onAddMedia={handleAddMedia}
          />

          <RemoveMediaConfirmationModal
            messageType="RedWarning"
            confirmButtonText="Sim, remover"
            confirmButtonColor="var(--red)"
            cancelButtonText="Cancelar"
            messageTitle="Remover arquivo?"
            messageDescription="Essa ação não poderá ser revertida"
            onRequestClose={handleCloseConfirmRemoveArticleModal}
            isOpen={isConfirmRemoveMediaModalOpen}
            onCancel={handleCloseConfirmRemoveArticleModal}
            onConfirm={handleRemoveMedia}
          />

          <SuccessSavedPortfolioClassModal
            messageType="BlueWarning"
            messageTitle="Tudo Pronto!"
            messageDescription="As alterações foram salvas."
            isOpen={isSuccessSavedPortfolioClassModalOpen}
            onRequestClose={() => setIsSuccessSavedPortfolioClassModalOpen(false)}
          />

          <RemovePortfolioClassConfirmationModal
            confirmButtonText="Sim, Deletar"
            confirmButtonColor="var(--red)"
            cancelButtonText="Cancelar"
            messageType="RedWarning"
            messageTitle="Deletar Aula?"
            messageDescription="Esta ação não poderá ser revertida."
            onRequestClose={handleCloseConfirmRemovePortfolioClassModal}
            isOpen={isConfirmRemovePortfolioClassModalOpen}
            onCancel={handleCloseConfirmRemovePortfolioClassModal}
            onConfirm={handleRemovePortfolioClass}
          />

          <SuccessRemovePortfolioClassModal
            messageType="BlueWarning"
            messageTitle="Aula Deletada"
            isOpen={isSuccessRemovePortfolioClassModalOpen}
            onRequestClose={() => setIsSuccessRemovePortfolioClassModalOpen(false)}
          />

          <Form onSubmit={handleSubmit}>
            <Header>
              <div>
                <div>
                  <span>{networkPersonSelected.name}</span>
                  <h1>{currentPortfolioClass.name}</h1>
                </div>
                <button type="submit" disabled={!isChange}>
                  Salvar
                </button>
              </div>
              <h3>edite as informações dessa aula.</h3>
              <p>Informações como ”building block”, ”curso” e ”formato” não são editáveis.</p>
            </Header>

            <Input
              name="name"
              type="text"
              label="Building Block"
              value={currentPortfolioClass.name}
              placeholder={currentPortfolioClass.name}
              containerStyle={{ width: '35rem' }}
              readOnly={true}
              disabled
            />
            <Input
              name="courseAcronym"
              type="text"
              label="Curso"
              value={!currentPortfolioClass.courseAcronym ? '' : currentPortfolioClass.courseAcronym.toUpperCase()}
              placeholder={currentPortfolioClass.courseAcronym}
              containerStyle={{ width: '35rem' }}
              readOnly={true}
              disabled
            />
            <Input
              name="format"
              type="text"
              label="Formato"
              value={
                !currentPortfolioClass.format
                  ? ''
                  : Object.values(FormatBlock)[Object.keys(FormatBlock).indexOf(currentPortfolioClass.format)]
              }
              placeholder={currentPortfolioClass.format}
              containerStyle={{ width: '35rem' }}
              readOnly={true}
              disabled
            />

            <hr />

            <Select
              name="status"
              label="Status"
              items={Object.entries(statusDictionary).map(([value, name]) => ({
                value: value,
                name: name[0].toUpperCase() + name.substring(1)
              }))}
              placeholder="Escolha uma das opções"
              selectedValue={data.status === undefined ? 'default' : data.status}
              onChange={handleInputChange('status')}
              selectStyle={{ height: '3rem', width: '35rem' }}
            />

            <Label>materiais de aula</Label>
            <AddFilesPanel>
              <DataTableBase
                pagination={false}
                columns={columns}
                data={files || []}
                noDataComponent={
                  <NoMaterialsPanel>
                    <ExclamationMarkTriangleFilled color="var(--yellow)" size={20} />
                    Não há materiais cadastrados.
                  </NoMaterialsPanel>
                }
              />
              <button type="button" onClick={() => setIsAddMediaModalOpen(true)}>
                <PlusCircle size={16} color="var(--white)" />
                Material
              </button>
            </AddFilesPanel>

            <AutosizeInput
              name="activityGuidance"
              label="direcionamento para as dinâmicas"
              placeholder={`Ex. "Usar breakout-rooms para estudantes compartilharem ideias."`}
              value={data.activityGuidance}
              onChange={handleInputChange('activityGuidance')}
              onBlur={() => setIsChange(true)}
              containerStyle={{ width: '35rem' }}
            />

            <AutosizeInput
              name="details"
              label="Observações"
              placeholder={`Ex. "Essa aula funciona melhor com pre-work."`}
              value={data.details}
              onChange={handleInputChange('details')}
              onBlur={() => setIsChange(true)}
              containerStyle={{ width: '35rem' }}
            />

            <hr />

            <div>
              <h3>excluir aula</h3>
              <p>Esta ação irá deletar esta aula no portfólio da expert permanentemente.</p>
              <RemoveClassButton type="button" onClick={() => handleOpenConfirmRemovePortfolioClassModal()}>
                excluir aula
              </RemoveClassButton>
            </div>
          </Form>

          <DialogLeavingPage
            showDialog={showDialogLeavingPage}
            setShowDialog={setCanShowDialogLeavingPage}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />
        </Container>
      )}
    </>
  )
}
