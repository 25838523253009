import styled from 'styled-components'

export const ToggleSwitchWrapper = styled.label`
  background: #000000;
  width: 26px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  position: relative;

  input {
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    left: 2px;

    cursor: pointer;

    transition: 0.25s;
  }

  input:checked + span {
    left: 46%;
  }
`
