import React, { useState } from 'react'
import { ChevronDown, ChevronUp } from '@somostera/tera-icons'

import { MenuItem } from '../data'

import { SidebarLink, SidebarLabel, SubItemLabel, DropdownLink } from './styles'

interface SubMenuProps {
  item: MenuItem
  selectedMenuItem: string
  onMenuItemSelected: (itemTitle: string) => void
}

export const SubMenu = ({ item, selectedMenuItem, onMenuItemSelected }: SubMenuProps) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(true)

  const handleSidebarLinkClick = () => {
    if (item.subItems) {
      // // if item has subitems, it must toggle subMenu
      // setIsSubMenuOpen(!isSubMenuOpen)
    } else {
      // if item has no subitems, it must be selected
      onMenuItemSelected(item.title)
    }
  }

  return (
    <>
      <SidebarLink to={item.path || '#'} onClick={handleSidebarLinkClick}>
        <div>
          <span>{item.icon}</span>
          <SidebarLabel isSelected={item.title === selectedMenuItem}>{item.title}</SidebarLabel>
        </div>
        {/* <div>{item.subItems && (isSubMenuOpen ? <ChevronUp /> : <ChevronDown />)}</div> */}
      </SidebarLink>
      {isSubMenuOpen && item.subItems && (
        <>
          {item.subItems.map((subItem, index) => (
            <DropdownLink key={index} to={subItem.path} onClick={() => onMenuItemSelected(subItem.title)}>
              <SubItemLabel
                isSelected={subItem.title === selectedMenuItem || window.location.href.includes(subItem.path)}
              >
                {subItem.icon}
                <span>{subItem.title}</span>
              </SubItemLabel>
            </DropdownLink>
          ))}
        </>
      )}
    </>
  )
}
