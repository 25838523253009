import React, { useState, useEffect, useRef } from 'react'
import Close from 'core/components/Icon/Close'
import { useContent } from 'modules/contents/hooks/useContent'
import { getFormatedToLocaleDate } from 'core/utils/date'
import { getReadingTime } from 'core/utils/countHelper'
import { Container } from './styles'

interface AsideProps {
  handleShowAside: (article: object) => void
  articleShowAside: object
}

export function Aside({ handleShowAside, articleShowAside }: AsideProps) {
  const [facilitators, setFacilitators] = useState([])
  const [experts, setExperts] = useState([])
  const asideRef = useRef(null)

  const { getExpertsNameByParticipantsIds, getFacilitatorNameByFacilitatorIds } = useContent()

  useEffect(() => {
    const facilitators = getFacilitatorNameByFacilitatorIds(articleShowAside.facilitatorsIds)
    const experts = getExpertsNameByParticipantsIds(articleShowAside.participantsIds)
    setFacilitators(facilitators)
    setExperts(experts)
  }, [])

  function getTypeOfContent(name: string) {
    const nameToLowerCase = name?.toLowerCase()

    switch (nameToLowerCase) {
      case 'article':
        return 'Artigo'
      case 'class':
        return 'Aula'
      case 'challenge':
        return 'Desafio'
      case 'replay':
        return 'Replay'
      case 'branded-challenge':
        return 'Desafio de Mercado'
      case 'tutorial':
        return 'Projeto/Tutorial'
      case 'case':
        return 'Estudo de Caso'
      case 'podcast':
        return 'Podcast'
      case 'narrative':
        return 'Narrativa da Jornada'
      case 'questions-answers':
        return 'Q&A'
      case 'internal':
        return 'Interno'
      case 'debate':
        return 'Debate (ou talk)'
      default:
        return 'Sem tipo definido'
    }
  }

  function handleClickOutside(event: MouseEvent) {
    if (asideRef.current && !asideRef.current.contains(event.target)) {
      handleShowAside(null)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <Container ref={asideRef}>
      <section>
        <Close onClick={() => handleShowAside(null)} />
        <div>
          <h4>{getTypeOfContent(articleShowAside.type)}</h4>
        </div>
        <h3>{articleShowAside.name}</h3>
        <p>{articleShowAside.description}</p>
        <section>
          <div>
            <p>Expert</p>
            <p>{experts.length ? experts.map((expert) => expert.label) : '-'}</p>
          </div>
          <div>
            <p>Facilitador</p>
            <p>{facilitators.length ? facilitators.map((facilitator) => facilitator.label) : '-'}</p>
          </div>
          <div>
            <p>Atualizado em</p>
            <p>{getFormatedToLocaleDate(new Date(articleShowAside.updatedAt))}</p>
          </div>
          <div>
            <p>Tempo de dedicação</p>
            <p>{`${getReadingTime(articleShowAside.chapters)}m`}</p>
          </div>
          <div>
            <p>Objetivos de aprendizagem</p>
            <ul>
              {articleShowAside?.editorial?.outcomes.map((outcome) => (
                <li key={outcome}>{outcome}</li>
              ))}
            </ul>
          </div>
          <div>
            <p>Tópicos</p>
            <p>{articleShowAside?.editorial?.keywords.map((keyword) => keyword)}</p>
          </div>
        </section>
      </section>
    </Container>
  )
}
