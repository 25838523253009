import React, { useCallback, useEffect } from 'react'

import { Input } from 'core/components/Input'
import { AutosizeInput } from 'core/components/AutosizeInput'
import { TagsInput } from 'core/components/TagsInput'
import { useForm } from 'core/hooks/useForm'

import { Container } from './styles'
import { BuildingBlockFormValues } from 'modules/buildingblock/@types/buildings'
import { useBuildingBlock } from 'modules/buildingblock/hooks/useBuildingBlock'

export const DetailsBuildingBlock = () => {
  const {
    detailsName,
    detailsTag,
    outcomesTag,
    detailsDescription,
    setDetailsName,
    setDetailsTag,
    setOutcomesTag,
    setDetailsDescription,
    setCanShowDialogLeavingPage
  } = useBuildingBlock()

  const { handleChange, errors, setValue, reset } = useForm<BuildingBlockFormValues>({
    initialValues: {
      name: '',
      description: '',
      fileUrl: '',
      tags: [],
      outcomes: []
    },

    validations: {
      name: { required: { value: true, message: 'Preencha o campo obrigatório.' } },
      description: { required: { value: true, message: 'Preencha o campo obrigatório.' } },
      tags: {
        custom: {
          isValid: (values) => values?.length > 0,
          message: 'Preencha o campo obrigatório.'
        }
      }
    }
  })
  const resetFormState = useCallback(() => {
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    resetFormState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFormChange = (inputName: any) => (e: any) => {
    setValue(inputName, e.target.value)
    if (e.target.value !== '') setCanShowDialogLeavingPage(true)
    else {
      setCanShowDialogLeavingPage(false)
      handleChange(inputName)
    }

    if (inputName === 'name') {
      setDetailsName(e.target.value)
    }
    if (inputName === 'description') {
      setDetailsDescription(e.target.value)
    }
  }
  const handleTagFormChange = (tags: string[]) => {
    setValue('tags', tags)

    setDetailsTag(tags)
  }

  const handleOutcomesFormChange = (tags: string[]) => {
    setValue('outcomes', tags)

    setOutcomesTag(tags)
  }

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <section>
        <Input
          type="text"
          name="name"
          label="TÍTULO*"
          placeholder="Escolha o título para esse building block."
          value={detailsName}
          onChange={handleFormChange('name')}
          errors={errors.name}
        />
        <AutosizeInput
          type="text"
          name="description"
          label="SOBRE*"
          placeholder="Escreva uma breve introdução."
          characterCount={true}
          maxLength={384}
          value={detailsDescription}
          onChange={handleFormChange('description')}
          minHeight={'10rem'}
          errors={errors.description}
        />
        <TagsInput
          name="tags"
          label="Habilidades"
          placeholder="Digite até 3 habilidades"
          value={detailsTag}
          onTagsChange={(tags: string[]) => handleTagFormChange(tags)}
          errors={errors.tags}
          maxLength={3}
        />

        <TagsInput
          name="tags"
          label="Objetivos"
          placeholder="Digite os objetivos de apredizagem"
          description="Digite quais são os objetivos de aprendizagem dessa aula. Para separá-los basta apertar ”enter” e começar a
          escrever o próximo objetivo."
          value={outcomesTag}
          onTagsChange={(tags: string[]) => {
            handleOutcomesFormChange(tags)
          }}
          errors={errors.outcomes}
        />
      </section>
    </Container>
  )
}
