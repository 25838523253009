import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const colors = {
  expert: 'var(--green-pastel)',
  mentor: 'var(--blue-pastel)',
  facilitator: 'var(--yellow-pastel)',
  manager: 'var(--orange-pastel)',
  tutor: 'var(--purple-pastel)'
} as { [key: string]: string }

interface ChipProps {
  role: string
}

export const Chip = styled.span<ChipProps>`
  align-items: center;
  background: ${({ role }) => colors[role]};
  border-radius: 1rem;
  display: flex;
  font-size: 0.8rem;
  height: 1.5rem;
  justify-content: center;
  line-height: 120%;
  padding: 0.25rem 1rem;

  & + span {
    margin-left: 0.25rem;
  }
`
