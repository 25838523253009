import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  background: var(--yellow-pastel);
  color: var(--black);
  display: flex;
  font-size: 1rem;
  height: 3.5rem;
  line-height: 1.5rem;
  padding: 1rem;
  width: 35rem;

  svg {
    margin-right: 0.625rem;
  }
`
