import styled from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 0.9rem;
      color: var(--gray-100);
    }

    button {
      background: none;
    }
  }
`
export const HintContainer = styled.div`
  position: relative;
  display: flex;
`

export const HintBox = styled.div`
  position: absolute;
  margin: -0.5rem 0 0 5rem;
  background: var(--blue-10);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 0.3rem;
  width: 15rem;

  p {
    font-size: 0.85rem;
    color: var(--gray-100);
    padding: 0.2rem 0.3rem 0;
    width: 80%;
  }

  @media (max-width: 1300px) {
    width: 11.5rem;
  }
`

export const TextAreaContainer = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  button {
    border: 0;
    background: none;
    margin: 0.5rem -1.2rem 0 0;
  }
`
export const TextAreaInput = styled(TextareaAutosize)`
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
  min-height: 5.5rem;
  border-radius: 0.3rem;
  border: 2px solid var(--gray-40);
  resize: none;
  font-size: 1rem;
  padding: 0.5rem;
  font-weight: normal;
  line-height: 1.2rem;
  outline: 0;
  resize: none;
  transition: border-color 0.3s;

  &:hover {
    border-color: var(--black);
  }

  &:focus {
    border-color: var(--blue-primary);
  }

  &::placeholder {
    color: var(--gray-80);
  }
`
