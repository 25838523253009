import { PortfolioItemStatus } from '@somostera/tera-database'
import React, { ReactNode } from 'react'
import { ArrowRefreshCircleFilled, CheckCircleFilled, MinusCircleFilled, XCircleFilled } from '@somostera/tera-icons'

import { PortfolioData } from 'modules/network/@types/portfolio'

import { StatusContainer } from './styles'

interface StatusCellProps {
  row: PortfolioData
}

type StatusComponents = {
  [key in PortfolioItemStatus]: ReactNode
}

export const StatusCell = ({ row }: StatusCellProps) => {
  console.log(row.status)
  const statusComponents: StatusComponents = {
    validada: (
      <StatusContainer color="var(--green-80)">
        <CheckCircleFilled size={15} color="var(--green-80)" />
        <p>Validada</p>
      </StatusContainer>
    ),
    'em-validacao': (
      <StatusContainer color="var(--yellow)">
        <ArrowRefreshCircleFilled size={15} color="var(--yellow)" />
        <p>Em Validação</p>
      </StatusContainer>
    ),
    indisponivel: (
      <StatusContainer color="var(--gray-60)">
        <MinusCircleFilled size={15} color="var(--gray-60)" />
        <p>Indisponível</p>
      </StatusContainer>
    ),
    invalidada: (
      <StatusContainer color="var(--orange)">
        <XCircleFilled size={15} color="var(--orange)" />
        <p>Inválida</p>
      </StatusContainer>
    )
  }

  return <>{row.status === undefined ? '' : statusComponents[row.status]}</>
}
