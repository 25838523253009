import styled from 'styled-components'

export const ContentContainer = styled.div`
  padding: 1.5rem;

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h3 {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;
    }

    > button {
      background: transparent;
      border: 0;
    }
  }

  > main {
    padding: 0.5rem 0;
    min-width: 30rem;

    > section {
      display: flex;
      flex: 1;
      align-items: flex-end;

      > button {
        background: transparent;
        border: 0;
        margin: 0 0 0.6rem 0.6rem;
      }
    }
  }

  > footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-top: 1rem;

    > button {
      padding: 0.5rem 1.5rem;
      font-weight: bold;
      text-transform: uppercase;
      border: 2px solid transparent;

      &:first-child {
        background: transparent;
        color: var(--black);
        border-color: var(--black);

        &:disabled {
          border-color: var(--gray-40);
          color: var(--gray-40);
          cursor: not-allowed;
        }
      }

      &:last-child {
        background: var(--black);
        color: var(--white);

        &:disabled {
          background: var(--gray-40);
          cursor: not-allowed;
        }
      }
    }
  }
`
