import React from 'react'

import { Container, ListItem } from './styles'

export type ViewDataType = 'default' | 'tag'

interface ViewDataProps {
  title: string
  data: string[]
  type?: ViewDataType
}

export function ViewData({ title, data, type = 'default' }: ViewDataProps) {
  return (
    <Container>
      <h3>{title}</h3>

      <div>
        {data.map((item, index) => (
          <ListItem key={index} type={type} tagKey={index + 1}>
            {item}
          </ListItem>
        ))}
      </div>
    </Container>
  )
}
