import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const HeaderContainer = styled.header`
  padding: 2.5rem;
  background-color: #fcfcfc;

  > h2 {
    font-weight: 900;
    font-family: 'Inter';
    font-size: 2rem;
    line-height: 2rem;
    letter-spacing: -0.02;
    text-transform: uppercase;
  }
`

export const Content = styled.section`
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 24px;
  background-color: #fcfcfc;
`

export const FilterContainer = styled.div`
  display: flex;

  > button {
    background: #8ffe81;
    width: 171px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`

export const Button = styled(Link)`
  background: #8ffe81;
  text-transform: uppercase;

  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Rubik';

  text-decoration: none;
  color: #000;

  min-width: 202px;
  padding: 8px 24px 8px 24px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`
export const DatabaseContainer = styled.div`
  margin-top: 24px;
`
export const LoadingContainer = styled.div`
  background: #fcfcfc; ;
`
