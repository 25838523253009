import React, { forwardRef, InputHTMLAttributes } from 'react'

import { HiddenRadio, Label } from './styles'

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  labelText?: string
}

export const RadioButton = forwardRef(({ labelText, checked, id, ...rest }: RadioButtonProps) => {
  return (
    <Label htmlFor={id} checked={checked}>
      <HiddenRadio type="radio" checked={checked} id={id} {...rest} />
      {labelText && <span>{labelText}</span>}
    </Label>
  )
})
