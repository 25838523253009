import React from 'react'

export default function CheckCircleFilled(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM14.7474 8.16437C15.1143 7.75159 15.0772 7.11952 14.6644 6.7526C14.2516 6.38568 13.6195 6.42286 13.2526 6.83565L8.66668 11.9948L6.74742 9.83565C6.3805 9.42286 5.74843 9.38568 5.33565 9.7526C4.92286 10.1195 4.88568 10.7516 5.2526 11.1644L7.91927 14.1644C8.10904 14.3779 8.38104 14.5 8.66668 14.5C8.95232 14.5 9.22432 14.3779 9.41409 14.1644L14.7474 8.16437Z"
        fill="#45A557"
      />
    </svg>
  )
}
