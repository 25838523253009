import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'core/hooks/useAuth'
import { LivesService } from 'modules/lives/services/lives'

import { Input } from 'modules/lives/components/Input'
import { InputSelect } from 'modules/lives/components/Select'
import { AutosizeInput } from 'modules/lives/components/AutosizeInput'
import ToggleSwitch from 'modules/lives/components/ToggleSwitch'
import { MultiSelect } from 'modules/lives/components/MultiSelect'
import { TagsInput } from 'modules/lives/components/TagsInput'
import { ListInput } from 'modules/lives/components/ListInput'
import {
  ConfirmationModal as PublishConfirmationModal,
  ConfirmationModal as CancelModal
} from 'modules/courses/components/ConfirmationModal'
import { Container, FormContainer, Footer, ToggleSwitchContainer } from './styles'

export function AddLive() {
  const navigate = useNavigate()
  const { userFirebase } = useAuth()

  const [title, setTitle] = useState<string | undefined>('')
  const [type, setType] = useState<string | undefined>('')
  const [editorias, setEditorias] = useState<string | undefined>('')
  const [about, setAbout] = useState('')
  const [outcomes, setOutcomes] = useState<string[] | undefined>([])
  const [isChecked, setIsChecked] = useState(false)
  const [isVisible, setIsVisible] = useState('')
  const [keywords, setKeywords] = useState<string[] | undefined>([])

  const [isConfirmPublishModalOpen, setIsConfirmPublishModalOpen] = useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)

  const LiveType = {
    'welcome-session': 'Onboarding',
    tutorial: 'Tutoriais',
    trend: 'Trend',
    masterclass: 'Masterclass'
  }

  const EditoriaType = {
    'product-management': 'Gestão de Produtos',
    'leadership-business': 'Liderança & Negócios',
    'data-ai': 'Dados & IA',
    'design-innovation': 'Design & Inovação',
    programming: 'Desenvolvimento de Software',
    marketing: 'Marketing Digital'
  }

  const liveOptions = Object.keys(LiveType).map((key) => ({
    value: key,
    label: LiveType[key]
  }))

  const editoriaOptions = Object.keys(EditoriaType).map((key) => ({
    value: key,
    label: EditoriaType[key]
  }))

  useEffect(() => {
    isChecked ? setIsVisible('Público') : setIsVisible('Privado')
  }, [isChecked])

  const handleCheck = () => {
    setIsChecked(!isChecked)
  }

  async function handleSave() {
    const token = await userFirebase.getIdToken()
    const data = {
      name: title,
      type: type.value,
      areas: editorias.map((ed) => ed.value),
      description: about,
      keywords: [...keywords],
      outcomes: [...outcomes],
      visibility: isChecked ? 'public' : 'private',
      status: 'draft'
    }

    LivesService.saveLive(token, data)
    setIsConfirmPublishModalOpen(true)
  }

  function handlePrevOrNext() {
    handleSave()
  }

  const handleCloseConfirmPublishModal = useCallback(() => {
    setIsConfirmPublishModalOpen(false)
  }, [])

  async function handleCancel() {
    try {
      navigate(`/lives`)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCloseCancelModal = useCallback(() => {
    setIsCancelModalOpen(false)
  }, [])

  return (
    <>
      <PublishConfirmationModal
        confirmButtonText="ok, fechar"
        messageType="Confirmation"
        messageTitle="Live criada com sucesso"
        messageDescription="O calendário de lives foi atualizado. "
        onRequestClose={handleCloseConfirmPublishModal}
        isOpen={isConfirmPublishModalOpen}
        onConfirm={handleCancel}
      />

      <CancelModal
        confirmButtonText="Voltar para edição"
        cancelButtonText="Sair sem salvar"
        messageType="RedWarning"
        messageTitle="Deseja sair sem salvar?"
        messageDescription="As alterações feitas serão perdidas."
        onRequestClose={handleCloseConfirmPublishModal}
        isOpen={isCancelModalOpen}
        onCancel={() => handleCancel()}
        onConfirm={() => {
          handleCloseCancelModal()
        }}
      />
      <Container>
        <section>
          <h2>Nova Live</h2>

          <FormContainer>
            <h3>Especificações</h3>
            <Input
              type="text"
              name="title"
              label="Título da live"
              placeholder="Escreva o título da live"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              required
            />

            <AutosizeInput
              type="text"
              name="about"
              label="Sobre"
              placeholder="Descrição breve do que é este conteúdo"
              value={about}
              minHeight={'108px'}
              onChange={(event: string) => setAbout(event.target.value)}
              required
            />

            <InputSelect
              label="Tipo de Live"
              placeholder="Selecione qual o tipo de live"
              options={liveOptions}
              onChange={(event) => {
                setType(event)
              }}
              value={{
                value: type?.value || null,
                label: type?.value ? LiveType[type?.value] : 'Selecione qual o tipo de live'
              }}
            />
            <MultiSelect
              name="areas"
              options={editoriaOptions}
              onChange={setEditorias}
              value={editorias}
              label="Editorias"
              required
            />

            <TagsInput
              name="keywords"
              label="Tópicos"
              placeholder="Descrição breve do que é este conteúdo"
              value={keywords}
              onTagsChange={(tags: string[]) => setKeywords([...tags])}
              type="tag"
              required
            />

            <ListInput
              name="outcomes"
              label="Objetivos de Aprendizagem"
              placeholder="Escreva em tópicos os objetivos de aprendizagem do conteúdo"
              value={outcomes}
              onTagsChange={(tags: string[]) => setOutcomes([...tags])}
              type="tag"
              required
            />

            <ToggleSwitchContainer>
              <h3>Visibilidade</h3>
              <div>
                <ToggleSwitch onClick={handleCheck} />
                <span>{isVisible}</span>
              </div>
            </ToggleSwitchContainer>
          </FormContainer>
        </section>
      </Container>
      <Footer>
        <button onClick={() => setIsCancelModalOpen(true)}>Cancelar</button>
        <button onClick={handlePrevOrNext}>Salvar</button>
      </Footer>
    </>
  )
}
