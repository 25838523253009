import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  cursor: default;
  flex: 1;
  justify-content: flex-start;

  > div {
    width: 3.75rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      width: 100%;
      height: 100%;
      background: var(--gray-20);
    }

    > img,
    video {
      width: 100%;
      height: 100%;
    }

    > span {
      display: block;
      position: absolute;
      margin-top: 1rem;
      > img {
        height: 2.5rem;
      }
    }
  }

  > section {
    margin-left: 0.5rem;

    > h3 {
      color: var(--black);
      font-weight: normal;
      text-decoration: underline;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 20rem;
      white-space: nowrap;
    }

    > span {
      display: block;
      color: var(--gray-60);
      font-size: 0.875rem;
      margin-top: 0.2rem;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 20rem;
      white-space: nowrap;
    }
  }
`
