import styled from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;

  > button {
    align-self: flex-end;
    background: var(--blue-primary);
    border-radius: 0.5rem;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
    color: var(--white);
    font-size: 1rem;
    font-weight: bold;
    height: 3rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    width: 10rem;
    margin: 2rem 8px;

    &:disabled {
      background: var(--gray-20);
      cursor: not-allowed;
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10rem;
  margin-bottom: 2.5rem;
  margin-left: 3.5rem;
  margin-top: 5rem;
  width: 85%;

  h3 {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin: 2rem 0 0.5rem;
    text-transform: uppercase;
  }

  span {
    color: var(--gray-80);
    font-size: 0.875rem;
    line-height: 130%;
  }
`
export const AddClassHour = styled.button`
  align-items: center;
  align-self: end;
  background: var(--blue-primary);
  border-radius: 0.25rem;
  color: var(--white);
  display: flex;
  font-size: 0.875rem;
  font-weight: bold;
  height: 2.25rem;
  justify-content: center;
  line-height: 120%;
  margin: 2rem 8px;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  width: 10rem;
`
export const InfoPanel = styled.div`
  align-items: center;
  display: flex;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;

  a {
    color: var(--blue-primary);
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-left: 0.5rem;
    text-decoration-line: underline;
  }
`

export const EditClassHour = styled.button`
  align-items: center;
  align-self: end;
  background: var(--black);
  border-radius: 0.25rem;
  color: var(--white);
  display: flex;
  font-size: 0.875rem;
  font-weight: bold;
  height: 2rem;
  justify-content: center;
  line-height: 120%;
  margin: 0rem 8px;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  width: 7rem;
`

export const HorizontalPanel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;

  > div {
    margin-top: 0.25rem;
  }

  > div + div,
  > label + label {
    margin-left: 1rem;
  }
`
