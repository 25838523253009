import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`

interface InputContainerProps {
  isFocused: boolean
  isInvalid: boolean
  disabled: boolean
}

export const InputContainer = styled.div<InputContainerProps>`
  background: ${(props) => (props.disabled ? 'var(--gray-20)' : '#FCFCFC')};
  border: 1px solid ${(props) => (props.disabled ? 'transparent' : '#DBDBDB')};
  border-radius: 2px;
  color: ${(props) => (props.disabled ? 'var(--gray-60)' : 'var(--black)')};
  height: 48px;
  position: relative;

  ${(props) =>
    props.isFocused &&
    css`
      border-color: #3af221;
    `}

  ${(props) =>
    props.isInvalid &&
    css`
      border-color: var(--orange);
    `}

  input {
    background: transparent;
    border: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    height: 100%;
    min-height: 48px;
    outline: 0;
    padding: 0.75rem 1rem;
    width: 100%;
    font-weight: 400;
    font-family: 'Rubik';

    &::placeholder {
      color: #707070;
      font-family: 'Inter';
      font-size: 16px;
      letter-spacing: -0.01em;
    }
  }

  svg {
    position: absolute;
    right: 0.625rem;
    top: calc(50% - 12px);

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const InputInformationContainer = styled.div`
  align-items: baseline;
  display: flex;
  gap: 0.25rem;

  label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 8px;
  }

  p {
    color: var(--gray-80);
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  span {
    color: #cc3314;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.75rem;
  }
`

export const Error = styled.p`
  color: var(--orange);
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
`
