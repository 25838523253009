import React, { ReactElement } from 'react'
import { House, BookOpen, StickerSmileSquare, Camera } from '@somostera/tera-icons'
import Book from '../Icon/Book'
import Pilar from '../Icon/Pilar'
import Cam from '../Icon/Cam'
import Home from '../Icon/Home'
import MenuItem from '../Icon/MenuItem'
import Grid from '../Icon/Grid'
import Play from '../Icon/Play'

export type MenuItem = {
  title: string
  path?: string
  icon?: ReactElement
  subItems?: { title: string; path: string; icon?: ReactElement }[]
}

export const sidebarData: MenuItem[] = [
  {
    title: 'Home',
    path: '/home',
    icon: <Home />
  },
  // {
  //   title: 'Experiência',

  //   subItems: [
  //     {
  //       title: 'Casting',
  //       path: '/casting'
  //     },
  //     {
  //       title: 'Rede',
  //       path: '/network'
  //     }
  //   ]
  // },
  {
    title: 'Conteúdo',
    subItems: [
      {
        title: 'Aulas',
        path: '/contents',
        icon: <Book />
      },
      {
        title: 'Cursos',
        path: '/courses',
        icon: <Pilar />
      },
      {
        title: 'Lives',
        path: '/lives',
        icon: <Play />
      },
      {
        title: 'Building Blocks',
        path: '/buildingblock',
        icon: <Grid />
      }
      // {
      //   title: 'Formações',
      //   path: '/formations',
      //   icon: <MenuItem />
      // }
      // {
      //   title: 'Mídias',
      //   path: '/media',
      //   icon: <Camera />
      // }
    ]
  },
  {
    title: 'Pessoas',

    subItems: [
      {
        title: 'Rede',
        path: '/network',
        icon: <Cam />
      }
    ]
  }
  // {
  //   title: 'D.I.',

  //   subItems: [
  //     {
  //       title: 'Building Blocks',
  //       path: '/buildingblock',
  //       icon: <StickerSmileSquare />
  //     }
  //   ]
  // }
]
