import { NetworkData } from 'modules/network/@types/network'
import { PeopleNetworkService } from 'modules/network/services/people'
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { useImmerReducer } from 'use-immer'
import { articlesReducer, State } from '../reducers/articles'
import { deleteArticleDraftService } from '../services/article'

interface ContentContextData {
  currentArticleName: string
  allPeopleData: NetworkData[]
  allValidExperts: { value: string; name: string }[]
  allValidFacilitators: { value: string; label: string }[]
  removeArticle: (articleToBeRemoved: string) => void
  updateCurrentArticleDraftName: (articleName: string) => void
  getExpertsNameByParticipantsIds: (participantsIds?: string[]) => { id: string; name: string }[]
  getFacilitatorNameByFacilitatorIds: (facilitatorIds?: string[]) => { id: string; label: string }[]
}

interface ContentProviderProps {
  children: ReactNode
}
const ContentContext = createContext<ContentContextData>({} as ContentContextData)

export const ContentProvider = ({ children }: ContentProviderProps) => {
  const [allPeopleData, setAllPeopleData] = useState<NetworkData[]>([] as NetworkData[])
  const [allValidExperts, setAllValidExperts] = useState<{ value: string; name: string }[]>([])
  const [allValidFacilitators, setAllValidFacilitators] = useState<{ value: string; label: string }[]>([])

  const [{ currentArticleName }, setState] = useImmerReducer(articlesReducer, {
    currentArticleName: ''
  } as State)

  const updateCurrentArticleDraftName = (articleName: string) => {
    setState({ type: 'UPDATE_CURRENT_ARTICLE_DRAFT_NAME', payload: { newArticleName: articleName } })
  }

  const removeArticle = useCallback(async (articleToBeRemoved: string) => {
    setState({ type: 'ASYNC_CALL' })
    await deleteArticleDraftService(articleToBeRemoved)
    setState({ type: 'ASYNC_CALL_SUCCEED' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getExpertsNameByParticipantsIds = useCallback(
    (participantsIds?: string[]) => {
      const validExperts = participantsIds !== undefined ? participantsIds : []

      const expertsNames = validExperts.map((expertId) => {
        const expert = allPeopleData.find((person) => person.id === expertId)
        return expert?.id ? { id: expert.id, name: expert.name } : { id: '1', name: '' }
      })

      return expertsNames
    },
    [allPeopleData]
  )

  const getFacilitatorNameByFacilitatorIds = useCallback(
    (facilitatorIds?: string[]) => {
      const validFacilitators = facilitatorIds !== undefined ? facilitatorIds : []

      const facilitatorsNames = validFacilitators.map((facilitatorId) => {
        const facilitator = allPeopleData.find((person) => person.id === facilitatorId)

        return facilitator?.roles.includes('facilitator')
          ? { id: facilitator.id, label: facilitator.name }
          : { id: '1', label: 'Selecione o facilitador' }
      })

      return facilitatorsNames
    },
    [allPeopleData]
  )

  useEffect(() => {
    const getAllPeopleData = async () => {
      const allPeople = await PeopleNetworkService.findAll()

      setAllPeopleData(allPeople)

      const validExperts = allPeople.filter((person) => person.name && person.roles.includes('expert'))
      const validFacilitators = allPeople.filter((person) => person.name && person.roles.includes('facilitator'))

      const experts = validExperts.map((person) => {
        return {
          name: person.name,
          value: person.id
        }
      })

      const facilitators = validFacilitators.map((person) => {
        return {
          label: person.name,
          value: person.id
        }
      })

      const NoRepeatExperts = experts.filter((expert, index, self) => {
        return index === self.findIndex((repeat) => repeat.value === expert.value)
      })

      const NoRepeatFacilitator = facilitators.filter((facilitator, index, self) => {
        return index === self.findIndex((repeat) => repeat.value === facilitator.value)
      })

      setAllValidExperts(NoRepeatExperts)
      setAllValidFacilitators(NoRepeatFacilitator)
    }

    getAllPeopleData()
  }, [])

  return (
    <ContentContext.Provider
      value={{
        currentArticleName,
        allValidExperts,
        allValidFacilitators,
        allPeopleData,
        removeArticle,
        updateCurrentArticleDraftName,
        getExpertsNameByParticipantsIds,
        getFacilitatorNameByFacilitatorIds
      }}
    >
      {children}
    </ContentContext.Provider>
  )
}

export const useContent = (): ContentContextData => {
  return useContext(ContentContext)
}
