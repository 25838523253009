import React, { InputHTMLAttributes, useCallback, useState } from 'react'
import { MinusCircleFilled, PlusCircleFilled } from '@somostera/tera-icons'

import { Container, InputInformationContainer, Error, InputContainer } from './styles'

interface InputNumberProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  errors?: string
  containerStyle?: object
  inputStyle?: object
  increaseValue: () => void
  decreaseValue: () => void
}

export const InputNumber = ({
  name,
  label,
  containerStyle = {},
  inputStyle = {},
  errors,
  value,
  onBlur,
  increaseValue,
  decreaseValue,
  ...rest
}: InputNumberProps) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(event)
      }
      setIsFocused(false)
    },
    [onBlur]
  )

  return (
    <>
      <InputInformationContainer>{!!label && <label htmlFor={name}>{label}</label>}</InputInformationContainer>
      <Container>
        <button type="button" onClick={decreaseValue}>
          <MinusCircleFilled color="var(--black)" size={18} />
        </button>
        <InputContainer style={containerStyle} isFocused={isFocused} isInvalid={!!errors}>
          <input
            {...rest}
            type="number"
            value={value || 0}
            id={name}
            name={name}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            style={inputStyle}
          />
        </InputContainer>
        <button type="button" onClick={increaseValue}>
          <PlusCircleFilled color="var(--black)" size={24} />
        </button>
        {!!errors && <Error>{errors}</Error>}
      </Container>
    </>
  )
}
