/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from 'react'
import { useAuth } from 'core/hooks/useAuth'
import { FilterTable } from 'modules/courses/components/DataTableBase/FilterTable'
import { TableColumn } from 'react-data-table-component'
import { Checkbox } from 'core/components/Checkbox'
import { CoursesService } from 'modules/courses/services/courses'
import { ContainerCell } from 'modules/buildingblock/components/ContainerCell'
import { PlusCircle } from '@somostera/tera-icons'
import { Tooltip } from 'modules/courses/components/Tooltip'
import { Tag } from 'modules/courses/components/Tag'
import { DataTableBase } from 'modules/courses/components/DataTableBase'
import Doc from 'core/components/Icon/Doc'
import CloseButtonBlack from 'core/components/Icon/CloseButtonBlack'
import CloseButtonWhite from 'core/components/Icon/CloseButtonWhite'
import {
  HeaderContainer,
  Content,
  ButtonFilter,
  ResetButton,
  ResetFilterContainer,
  DatabaseContainer,
  FilterContiner,
  Button,
  MoreFiltersContainer,
  CheckboxContainer,
  ButtonFilterContainer
} from './styles'
import LeftArrow from 'core/components/Icon/LeftArrow'
import DownArrow from 'core/components/Icon/DownArrow'

export interface Course {
  id?: string
  name?: string
  description?: string
  career?: string[]
  status?: string
  visibility?: string
  updatedAt?: string
  readingTime?: number
}

interface FilterOption {
  [key: string]: boolean
}

interface Filters {
  [key: string]: FilterOption
}

interface Dictionary {
  [key: string]: string
}

export function Courses() {
  const [indexToShow, setIndexToShow] = useState<number[]>([])
  const [popoverChange, setPopoverChange] = useState(false)
  const { userFirebase } = useAuth()
  const [courses, setCourses] = useState<Course[]>([])
  const [coursesFiltered, setCoursesFiltered] = useState<Course[]>([])
  const [filterText, setFilterText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showTagsFilter, setShowTagsFilter] = useState(false)
  const [filterToShow, setFilterToShow] = useState<string[]>([])
  const [filtersSelected, setFiltersSelected] = useState<string[]>([])

  const moreFiltersOptionsInitialValue = {
    Status: {
      publicado: false,
      rascunho: false
    }
  }

  const [moreFiltersOptions, setMoreFiltersOptions] = useState<Filters>(moreFiltersOptionsInitialValue)

  const typeDictionary: Dictionary = {
    publicado: 'Publicado',
    rascunho: 'Rascunho'
  }

  function getSelectedOptions() {
    const selectedOptions = []
    for (const key in moreFiltersOptions) {
      if (Object.prototype.hasOwnProperty.call(moreFiltersOptions, key)) {
        const subObj = moreFiltersOptions[key]
        for (const subKey in subObj) {
          if (Object.prototype.hasOwnProperty.call(subObj, subKey)) {
            if (subObj[subKey]) {
              selectedOptions.push(subKey)
            }
          }
        }
      }
    }
    return selectedOptions
  }

  const formatDateToShow = useCallback((dateString: string) => {
    const date = new Date(dateString)

    const formatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }

    const formatter = new Intl.DateTimeFormat('en-US', formatOptions)
    const formattedDate = formatter.format(date)

    return formattedDate.replace(',', ' às')
  }, [])

  function visibilityNamed(name: string) {
    switch (name) {
      case 'published':
        return 'publicado'
      case 'draft':
        return 'rascunho'
      case 'archived':
        return 'arquivado'
      case 'deleted':
        return 'deletado'
    }
  }

  function handleFilterCourses(filtersSelected: string[]) {
    let mapCourses: Course[] = []
    const carrerSelected = filtersSelected.filter((filter) => filter !== 'published' && filter !== 'draft')
    if (carrerSelected.length) {
      courses.forEach((course: Course) => {
        course.career?.some((filter) => {
          if (carrerSelected.includes(typeof filter === 'string' ? filter.toLowerCase() : filter?.label?.toLowerCase())) {
            mapCourses.push(course)
            return true
          }
          return false
        })
      })
    } else {
      mapCourses = [...courses]
    }

    const statusSelected = filtersSelected.filter((filter) => filter === 'published' || filter === 'draft')

    if (statusSelected.length) {
      const newCourses = mapCourses.filter((course: Course) => statusSelected.includes(course?.status))

      if (!newCourses.length) {
        alert('Não foi encontrado cursos para o filtro selecionado')
        handleClear()
        return
      }
      setCoursesFiltered(newCourses)
    } else {
      setCoursesFiltered(mapCourses)
    }
  }

  function addOrRemoveIndexToShow(index: number) {
    if (indexToShow.includes(index)) {
      const newIndexToShow = indexToShow.filter((indexInArray) => index !== indexInArray)
      setIndexToShow(newIndexToShow)
    } else {
      setIndexToShow((prevState) => [...prevState, index])
    }
  }

  function convertMinutesToHoursMinutesSeconds(minutes) {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60
    const seconds = (remainingMinutes % 1) * 60

    return `${hours}:${remainingMinutes}:${seconds}`
  }

  const columns: TableColumn<Course>[] = [
    {
      name: 'Cursos',
      selector: ({ name }) => name ?? '',
      sortable: true,
      cell: (row: Course) => (
        <ContainerCell url={`/courses/${row?.id}`}>
          <Tooltip title={row.description}>
            <p>{row.name}</p>
          </Tooltip>
        </ContainerCell>
      ),
      width: '508px'
    },
    {
      name: 'Duração',
      selector: ({ readingTime }) => JSON.stringify(readingTime),
      sortable: true,
      cell: (row: Course) => (
        <ContainerCell url={`#`}>
          {row.readingTime ? convertMinutesToHoursMinutesSeconds(row.readingTime) : 'Não definido'}
        </ContainerCell>
      ),
      width: '160px'
    },
    {
      name: 'Última Atualização',
      selector: ({ updatedAt }) => JSON.stringify(updatedAt),
      sortable: true,
      cell: (row: Course) => (
        <ContainerCell url={`#`}>{row.updatedAt ? formatDateToShow(row.updatedAt) : 'Não definido'}</ContainerCell>
      ),
      width: '292px'
    },
    {
      name: 'Status',
      selector: ({ status }) => status,
      sortable: true,
      cell: (row: Course) => (
        <ContainerCell url={`#`}>
          <Tag type={`${visibilityNamed(row.status)}`} />
        </ContainerCell>
      ),
      width: '160px'
    }
  ]

  const handleClear = () => {
    setFilterText('')
    setMoreFiltersOptions(moreFiltersOptionsInitialValue)
    setFiltersSelected([])
    setFilterToShow([])
    setCoursesFiltered([])
  }

  const handleDeleteFilter = (item: string) => {
    const newFilterToshow = filterToShow.filter((filter) => filter !== item)
    if (!newFilterToshow.length) {
      handleClear()
      return
    }

    let label = ''
    const wordArray = item.split(' ')
    wordArray.forEach((palavra) => {
      if (palavra) {
        const temp = palavra[0].toUpperCase() + palavra.substring(1)
        label = label + ' ' + temp
      }
    })

    const isNotStatus = item !== 'Rascunho' && item !== 'Publicado'

    handleCheckFilter(false, isNotStatus ? 'Carreiras' : 'Status', isNotStatus ? label.trim() : item.toLowerCase())

    handleFilterCourses(newFilterToshow)
    setFilterToShow(newFilterToshow)
    setFiltersSelected(newFilterToshow)
  }

  const handleCheckFilter = (newValue: boolean, filter: string, itemLabel: string) => {
    const newFilterBlock = moreFiltersOptions[filter]
    newFilterBlock[itemLabel] = newValue

    let formatedItemLabel: string

    if (itemLabel === 'publicado') {
      formatedItemLabel = 'published'
    } else if (itemLabel === 'rascunho') {
      formatedItemLabel = 'draft'
    } else {
      formatedItemLabel = itemLabel
    }

    if (newValue) {
      setFiltersSelected([...filtersSelected, formatedItemLabel.toLowerCase()])
    } else {
      const newFilters = filtersSelected.filter((filterSelected) => filterSelected.toLowerCase() !== formatedItemLabel)
      setFiltersSelected(newFilters)
    }

    setMoreFiltersOptions({
      ...moreFiltersOptions,
      [filter]: newFilterBlock
    })
  }

  const handleApplyFilter = () => {
    setFilterToShow(getSelectedOptions())
    setShowTagsFilter(true)
    handleFilterCourses(filtersSelected)
    setPopoverChange(!popoverChange)
  }

  async function getAllCourses() {
    setIsLoading(true)
    const token = await userFirebase.getIdToken()
    const response = await CoursesService.getAll(token) as any

    const test = response.data.filter((course) => course?.status)
    console.log({ test })

    setCourses(response.data)
    setIsLoading(false)
  }

  useEffect(() => {
    getAllCourses()
    addOrRemoveIndexToShow(0)
  }, [])

  useEffect(() => {
    if (filtersSelected.length <= 0) {
      setShowTagsFilter(false)
      setFilterToShow([])
      getAllCourses()
    }
  }, [filtersSelected])

  useEffect(() => {
    const coursesFilterdByText = courses.map((course) => {
      const isMatch = course.name.toLowerCase().includes(filterText)
      if (isMatch) return course
      return undefined
    })

    const newCourses = coursesFilterdByText.filter((course) => course !== undefined)
    setCoursesFiltered(newCourses)
  }, [filterText])

  return (
    <>
      <HeaderContainer>
        <h2>Cursos</h2>
      </HeaderContainer>
      <Content>
        <FilterContiner>
          <FilterTable
            placeholder="Buscar conteúdo"
            onFilter={(event) => setFilterText(event.target.value)}
            onClear={handleClear}
            showResetButton={false}
            filterText={filterText}
            hasMoreFilters
            onPopoverChange={popoverChange}
            numberOfSelectedFilters={filtersSelected.length}
            MoreFiltersComponent={
              <MoreFiltersContainer>
                <section>
                  <Doc />
                  Filtrar por
                </section>
                {Object.entries(moreFiltersOptions).map(([title, items], index) => (
                  <div key={title}>
                    <h3 key={title} onClick={() => addOrRemoveIndexToShow(index)}>
                      {' '}
                      {indexToShow.includes(index) ? <DownArrow /> : <LeftArrow />}
                      {title}
                    </h3>
                    <CheckboxContainer showFilterActive={indexToShow.includes(index)}>
                      {Object.entries(items).map(([itemLabel, checked]) => (
                        <>
                          {indexToShow.includes(index) && (
                            <Checkbox
                              key={itemLabel}
                              checked={checked}
                              labelText={typeDictionary[itemLabel]}
                              // labelText={title === 'Status' ? typeDictionary[itemLabel] : dataDictionary[itemLabel]}
                              onChange={(event) => handleCheckFilter(event.target.checked, title, itemLabel)}
                            />
                          )}
                        </>
                      ))}
                    </CheckboxContainer>
                  </div>
                ))}
                <ButtonFilterContainer>
                  <ButtonFilter onClick={handleApplyFilter}>Aplicar filtros</ButtonFilter>
                </ButtonFilterContainer>
              </MoreFiltersContainer>
            }
          />

          <Button to={`/courses/new`}>
            <PlusCircle /> novo curso
          </Button>
        </FilterContiner>

        {showTagsFilter && filterToShow.length && (
          <ResetFilterContainer>
            {filterToShow.map((item, index) => (
              <p key={index}>
                {item}
                <CloseButtonBlack onClick={() => handleDeleteFilter(item)} />
              </p>
            ))}
            <ResetButton>
              Limpar todos os filtros <CloseButtonWhite onClick={handleClear} />
            </ResetButton>
          </ResetFilterContainer>
        )}

        <DatabaseContainer>
          <DataTableBase
            columns={columns}
            data={coursesFiltered.length ? coursesFiltered : courses}
            pagination
            noDataComponent={`${isLoading ? 'Carregando...' : 'Nenhum dado encontrado'}`}
            selectableRowsNoSelectAll={true}
          />
        </DatabaseContainer>
      </Content>
    </>
  )
}
