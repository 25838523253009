import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex: 1;
  min-width: 25%;
  height: 100%;
`

export const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem;

  h5 {
    color: var(--blue-primary);
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0.75rem 0;
    text-transform: uppercase;
    margin-left: 2rem;
  }

  > p {
    color: var(--gray-100);
    font-family: Rubik;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  span {
    color: var(--gray-60);
    font-family: Rubik;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  > hr {
    margin: 0 0.8rem 0 2rem;
    border: 0.1px solid var(--gray-40);
  }
`

export const AddChapterButtonContainer = styled.div`
  align-items: center;
  display: flex;
  height: 3.5rem;
  margin-left: 2rem;
  position: relative;
`

export const ChapterContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin: 0.25rem 0 0.25rem 0.6rem;
  min-height: 2rem;
  align-items: center;
  justify-content: flex-start;

  &:hover div {
    visibility: visible;
  }

  input {
    border: 1px dashed var(--gray-20);
    color: var(--gray-100);
    font-size: 0.75rem;
    height: 1.5rem;
    margin: 0 0 0 -0.375rem;
    min-height: 1.5rem;
    outline: 0;
    padding: 0 0 0 0.25rem;
    width: 80%;

    &::placeholder {
      color: var(--gray-40);
    }
  }

  button {
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 0.25rem;
    display: flex;
    height: 1.25rem;
    width: 1.25rem;

    &:active {
      background: var(--gray-20);
    }
  }
`

interface TitleProps {
  isCurrentChapter: boolean
}

export const Title = styled.p<TitleProps>`
  color: ${(props) => (props.isCurrentChapter ? 'var(--black)' : 'var(--gray-100)')};
  display: block;
  font-size: 0.75rem;
  font-weight: ${(props) => (props.isCurrentChapter ? 'bold' : 'normal')};
  line-height: 1rem;
  margin: 0 0.25rem;
  padding: 0.25rem 0;
  width: 80%;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const GripContainer = styled.div`
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 1rem;
`

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: 0.5rem;
  visibility: hidden;
  width: 5rem;
`

export const SaveButtonContainer = styled.div`
  margin-left: 0.5rem;
  visibility: hidden;
  width: 10%;
`
export const AddChapterSubMenuContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  margin-left: 1.3rem;
  width: 90%;
`
