import styled from 'styled-components'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { ChevronDown } from '@somostera/tera-icons'

export const StyledAccordion = styled(AccordionPrimitive.Root)`
  width: 100%;
  background-color: transparent;
`
export const StyledItem = styled(AccordionPrimitive.Item)`
  overflow: hidden;
  margin-top: 1.5rem;

  &:first-child: {
    margin-top: 0rem;
    border-top-left-radius: 4rem;
    border-top-right-radius: 4rem;
  }

  &:last-child: {
    border-bottom-left-radius: 4rem;
    border-bottom-right-radius: 4rem;
  }

  &:focus-within {
    position: relative;
    z-index: 1;
  }
`
export const StyledHeader = styled(AccordionPrimitive.Header)`
  display: flex;
`

export const StyledTrigger = styled(AccordionPrimitive.Trigger)`
  all: 'unset';
  justify-content: space-between;
  background-color: var(--gray-10);
  display: flex;
  height: 3.5rem;
  padding: 1rem;
  align-items: center;
  line-height: 1;
  color: var(--gray-80);
  width: 100%;

  > div {
    display: flex;

    > svg {
      margin-right: 1.5rem;
    }
  }

  svg {
    margin-right: 0.5rem;
  }

  h4 {
    color: var(--gray-80);
    font-size: 1rem;
    line-height: 1.25rem;
    text-transform: uppercase;
  }

  &:hover: {
    background-color: gray;
  }
`

export const StyledContent = styled(AccordionPrimitive.Content)`
  overflow: hidden;
`
export const StyledContentText = styled.div`
  padding: '0.25rem 1rem';
`
export const StyledChevron = styled(ChevronDown)`
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);

  [data-state='open'] & {
    transform: rotate(180deg);
  }
`
