import { ReactNode } from 'react'

import {
  AddressFormValues,
  ContactInformationFormValues,
  NetworkData,
  PersonalDataFormValues,
  PersonData,
  ProfessionalDataFormValues,
  StatusAndAvailabilityFormValues,
  FinancialFormValues
} from '../@types/network'

export interface Sections {
  [key: string]: {
    title: string
    filled: boolean
    idSection: string
    component: ReactNode
  }
}

export interface State {
  isLoading: boolean
  networkTablePeople: NetworkData[]
  networkPersonSelected: NetworkData
  personData: PersonData
  personProfileSections: Sections
  currentPage: 0
  isFirstPage: boolean
  isLastPage: boolean
  error?: string
}

export type Action =
  | { type: 'ASYNC_CALL' }
  | { type: 'ASYNC_CALL_SUCCEED' }
  | { type: 'ASYNC_CALL_FAILED'; payload: { error: string } }
  | { type: 'SET_NEXT_NETWORK_TABLE_PEOPLE'; payload: { isLastPage: boolean; isFirstPage: boolean } }
  | { type: 'SET_PREVIOUS_NETWORK_TABLE_PEOPLE'; payload: { isLastPage: boolean; isFirstPage: boolean } }
  | { type: 'SET_NETWORK_TABLE_PEOPLE'; payload: { people: NetworkData[] } }
  | { type: 'SET_NETWORK_PERSON_SELECTED'; payload: { person?: NetworkData } }
  | { type: 'SET_PERSON_PROFILE_SECTIONS'; payload: { personProfileSections: Sections } }
  | { type: 'UPDATE_PERSON_PROFILE_SECTION_FILLED_STATE'; payload: { section: string; state: boolean } }
  | { type: 'UPDATE_PERSON_PROFILE_SECTION_EXPANDED_STATE'; payload: { section: string; state: boolean } }
  | { type: 'SET_ADDRESS_DATA'; payload: { data: AddressFormValues } }
  | { type: 'SET_CONTACT_INFORMATION_DATA'; payload: { data: ContactInformationFormValues } }
  | { type: 'SET_PERSONAL_DATA'; payload: { data: PersonalDataFormValues } }
  | { type: 'SET_PROFESSIONAL_DATA'; payload: { data: ProfessionalDataFormValues } }
  | { type: 'SET_STATUS_AND_AVAILABILITY_DATA'; payload: { data: StatusAndAvailabilityFormValues } }
  | { type: 'SET_FINANCIAL_DATA'; payload: { data: FinancialFormValues } }

export const networkReducer = (draft: State, action: Action): State => {
  switch (action.type) {
    case 'ASYNC_CALL':
      draft.isLoading = true
      break
    case 'ASYNC_CALL_SUCCEED':
      draft.isLoading = false
      break
    case 'ASYNC_CALL_FAILED':
      draft.isLoading = false
      draft.error = action.payload.error
      break
    case 'SET_NEXT_NETWORK_TABLE_PEOPLE':
      draft.currentPage++
      draft.isLastPage = action.payload.isLastPage
      draft.isFirstPage = action.payload.isFirstPage
      break
    case 'SET_PREVIOUS_NETWORK_TABLE_PEOPLE':
      draft.currentPage > 0 ? draft.currentPage-- : (draft.currentPage = 0)
      draft.isLastPage = action.payload.isLastPage
      draft.isFirstPage = action.payload.isFirstPage
      break
    case 'SET_NETWORK_TABLE_PEOPLE':
      draft.networkTablePeople = action.payload.people
      break
    case 'SET_NETWORK_PERSON_SELECTED':
      if (action.payload.person) {
        draft.networkPersonSelected = action.payload.person
      }
      break
    case 'SET_PERSON_PROFILE_SECTIONS':
      draft.personProfileSections = action.payload.personProfileSections
      break
    case 'UPDATE_PERSON_PROFILE_SECTION_FILLED_STATE':
      draft.personProfileSections[action.payload.section].filled = action.payload.state
      break
    case 'SET_ADDRESS_DATA':
      draft.personData.address = action.payload.data
      break
    case 'SET_CONTACT_INFORMATION_DATA':
      draft.personData.contactInformation = action.payload.data
      break
    case 'SET_PERSONAL_DATA':
      draft.personData.personalData = action.payload.data
      break
    case 'SET_PROFESSIONAL_DATA':
      draft.personData.professionalData = action.payload.data
      break
    case 'SET_STATUS_AND_AVAILABILITY_DATA':
      draft.personData.statusAndAvailability = action.payload.data
      break
    case 'SET_FINANCIAL_DATA':
      draft.personData.financial = action.payload.data
      break
    default:
      break
  }
  return draft
}
