/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Container, IconContainer, IconContent, MainContainer, OptionalTooltip, OptionsContainer, TypeTooltip } from './styles'
import Drag from 'core/components/Icon/Drag'
import Info from 'core/components/Icon/Info'
import Trash from 'core/components/Icon/Trash'
import Options from 'core/components/Icon/Options'

const typeDictionary: Dictionary = {
  class: 'Aula',
  challenge: 'Desafio',
  replay: 'Replay',
  'branded-challenge': 'Desafio de mercado',
  tutorial: 'Projeto/tutorial',
  case: 'Estudo de caso',
  article: 'Artigo',
  podcast: 'Podcast',
  narrative: 'Narrativa da jornada',
  'questions-answers': 'Perguntas e respostas',
  internal: 'Interno',
  debate: 'Debate (ou talk)'
}

interface CardDropProps {
  handleShowAside?: (article: object) => void
  article: object
  index: number
  listIndex: number
  item?: object[]
}

export function CardDrop({ index, listIndex, move, data, deleteCard, items, handleShowAside }: CardDropProps) {
  const ref = useRef()
  const [optional, setOptional] = useState(false)

  const [{ isDragging }, dragRef] = useDrag({
    type: 'CARD',
    item: {
      index,
      listIndex
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  const [, dropRef] = useDrop({
    accept: 'CARD',
    hover(item: any, monitor) {
      const draggedListIndex = item.listIndex
      const targetListIndex = listIndex

      const draggedIndex = item.index
      const targetIndex = index

      if (draggedIndex === targetIndex && draggedListIndex === targetListIndex) return

      const targetSize = ref.current.getBoundingClientRect()
      const targetCenter = (targetSize.bottom - targetSize.top) / 2

      const draggedOffset = monitor.getClientOffset()
      const draggedTop = draggedOffset.y - targetSize.top

      if (draggedIndex < targetIndex && draggedTop < targetCenter) return
      if (draggedIndex > targetIndex && draggedTop > targetCenter) return

      move(draggedListIndex, targetListIndex, draggedIndex, targetIndex)
      item.index = targetIndex
      item.listIndex = targetListIndex
    }
  })

  dragRef(dropRef(ref))

  const changeOptional = () => {
    setOptional(!data?.isOptional)
    data.isOptional = !data?.isOptional
  }

  const setDefaultOptional = () => {
    const item = items?.find((item) => item.itemId === data.id)
    try {
      setOptional(item?.isOptional || false)
      data.isOptional = item?.isOptional || false
    } catch (err) {
      console.log('Erro')
    }
  }
  
  useEffect(() => {
    setDefaultOptional()
  }, [])

  return (
    <Container ref={ref} isDragging={isDragging}>
      {listIndex === 0 && (
        <>
          <IconContent>
            <Drag />
            <Info onClick={() => handleShowAside(data)} />
          </IconContent>
          <div>
            <span>{data.type ? `${typeDictionary[data.type]}` : 'Sem tipo definido'}</span>
            <h2>{data.name}</h2>
            <p>{data.description}</p>
          </div>
        </>
      )}
      {listIndex === 1 && (
        <>
          <IconContainer>
            <div>
              <Drag />
              <Info onClick={() => handleShowAside(data)} />
            </div>
            <Trash onClick={() => deleteCard(index)} />
          </IconContainer>
          <MainContainer>
            <div>
              <TypeTooltip>{data.type ? typeDictionary[data.type] : 'Sem tipo definido'}</TypeTooltip>
              {optional && <OptionalTooltip>Opcional</OptionalTooltip>}
            </div>
            <h2>{data.name}</h2>
            <p>{data.description}</p>
          </MainContainer>
          <OptionsContainer>
            <Options className="options"/>
            <div onClick={changeOptional}>
              <p>{optional ? "Tornar obrigatório" : "Tornar opcional"}</p>
            </div>
          </OptionsContainer>
        </>
      )}
    </Container>
  )
}
