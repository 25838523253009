import { Node, mergeAttributes } from '@tiptap/core'

export interface VideoOptions {
  allowfullscreen?: boolean
  src: string
  controls: boolean
  duration: number
  frameborder?: number
  HTMLAttributes: Record<string, any>
  poster?: string
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    'custom-video': {
      /**
       * Add a video
       */
      setVideo: (options: VideoOptions) => ReturnType
    }
  }
}

export const Video = Node.create<VideoOptions>({
  name: 'custom-video',
  group: 'block',
  atom: true,
  defaultOptions: {
    allowfullscreen: true,
    frameborder: 0,
    controls: true,
    duration: 0,
    src: '',
    poster: '',
    HTMLAttributes: {}
  },
  draggable: true,
  addAttributes() {
    return {
      allowfullscreen: {
        default: true
      },
      frameborder: {
        default: 0
      },
      duration: {
        default: 0
      },
      src: {
        default: null
      },
      poster: {
        default: null
      },
      controls: {
        default: true
      }
    }
  },
  parseHTML() {
    return [
      {
        tag: 'video[src]'
      }
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['video', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },
  addCommands() {
    return {
      setVideo:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options
          })
        }
    }
  }
})
