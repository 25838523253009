import { db } from '../../../core/config/firebase'
import { InitializePeopleService } from '@somostera/tera-database/dist/People/Infrastructure/Adapters/PeopleService'
import { AvailabilityEvent, GenericRole, Person } from '@somostera/tera-database'
import { NetworkData, PersonData } from '../@types/network'
import { Keynote } from '@somostera/tera-database/dist/People/Domain/types/Keynote'
import axios from 'axios'

const peopleService = InitializePeopleService.initialize(db)

export abstract class PeopleNetworkService {
  static async findAll() {
    const people = await peopleService.findAll()
    return people.map((person) => this.transformPersonIntoNetworkData(person))
  }

  static async findById(id: string) {
    const foundPerson = await peopleService.findById(id)
    return this.transformPersonIntoNetworkData(foundPerson)
  }

  static async findPersonDataById(id: string) {
    const foundPerson = await peopleService.findById(id)
    return [this.transformPersonIntoPersonData(foundPerson), foundPerson]
  }

  static async findByEmail(email: string): Promise<NetworkData | undefined> {
    try {
      const foundPerson = await peopleService.findByEmail(email)
      return this.transformPersonIntoNetworkData(foundPerson)
    } catch (error) {
      return undefined
    }
  }

  static async create(email: string, roles: GenericRole, person: Person, token: string) {
    const postURl = process.env.REACT_APP_TERA_CLOUD_URL + 'url-tera-create-people'

    return await axios.post(
      postURl,
      {
        people: { ...roles, ...person, email: email }
      },
      { headers: { __session: token } }
    )
  }

  static async createRoles(personId: string, roles: GenericRole) {
    await peopleService.createRoles(personId, roles)
  }

  static async createAvailability(personId: string, roles: AvailabilityEvent) {
    await peopleService.createAvailabilityForEvents(personId, roles)
  }

  static async createKeynote(personId: string, keynote: Keynote): Promise<Keynote> {
    const newKeynote = await peopleService.createKeynote(personId, keynote)
    return newKeynote
  }

  static async delete(id: string) {
    await peopleService.delete(id)
  }

  static async deleteRole(id: string, role: GenericRole) {
    await peopleService.deleteRole(id, role)
  }

  static async update(person: Person) {
    await peopleService.update(person)
  }

  static async updateRoles(id: string, roles: GenericRole) {
    await peopleService.updateRole(id, roles)
  }

  static async updateAvailability(id: string, availability: AvailabilityEvent) {
    await peopleService.updateAvailability(id, availability)
  }

  static async updateKeynote(personId: string, keynote: Keynote) {
    await peopleService.updateKeynote(personId, keynote)
  }

  private static transformPersonIntoNetworkData = (person: Person): NetworkData => {
    const genericRole = person.peopleRoles
    const availabilityEvent = person.availability

    return {
      id: person.id,
      name: person.name,
      email: genericRole?.email || '',
      jobTitle: person.jobTitle || '',
      company: person.company,
      roles: person.roles || [],
      profilePicture: person.pictureUrl || '',
      availability: availabilityEvent?.availabilityPeriod || []
    }
  }

  private static transformPersonIntoPersonData(person: Person): PersonData {
    const genericRole = person.peopleRoles as GenericRole
    const availabilityEvent = person.availability as AvailabilityEvent
    const keyNote = person.keynote as Keynote

    return {
      address: {
        zipCode: genericRole.zipcode || '',
        street: genericRole.street || '',
        number: genericRole.numberHouse || '',
        complement: genericRole.complement || '',
        city: genericRole.city || '',
        state: genericRole.state || ''
      },
      contactInformation: {
        country: genericRole.country || '',
        phoneNumber: genericRole.phone || '',
        email: genericRole.email || ''
      },
      personalData: {
        name: person.name || '',
        miniBio: person.description || '',
        birthday: genericRole.birthDate !== undefined ? genericRole.birthDate.toISOString().slice(0, 10) : '',
        gender: genericRole.gender || '',
        ethnicity: genericRole.ethnicity || '',
        profilePicture: person.pictureUrl || '',
        hasDisability: genericRole.pcd ? 'yes' : 'no'
      },
      professionalData: {
        linkedin: person.profileUrl || '',
        jobTitle: person.jobTitle || '',
        company: person.company || '',
        segmentOfActivity: genericRole.segment || '',
        hadExperienceAsMentorOrTeacher: genericRole.experience ? 'yes' : 'no'
      },
      statusAndAvailability: {
        status: availabilityEvent !== undefined ? availabilityEvent.status : '',
        roles: person.roles || [],
        daysAvailable: availabilityEvent !== undefined ? (availabilityEvent.availabilityDaysWeek as string[]) : [],
        periodsAvailable: availabilityEvent !== undefined ? availabilityEvent.availabilityPeriod : [],
        numberOfClasses: availabilityEvent !== undefined ? availabilityEvent.classLimit : 0,
        comments: availabilityEvent !== undefined ? availabilityEvent.observation || '' : ''
      },
      financial: {
        type: keyNote !== undefined ? keyNote.id : 'default',
        totalHours: keyNote !== undefined ? keyNote.classCreation.toString() : '',
        classHourValueInValidation: keyNote !== undefined ? keyNote.standardClass.toString() : '',
        classHourValueValidated: keyNote !== undefined ? keyNote.classRecording.toString() : '',
        mentoryHourValue: keyNote !== undefined ? keyNote.mentoring.toString() : '',
        canBeManuallySelected: true
      }
    }
  }
}
