import React from 'react'
import { GenericIcon } from '@somostera/tera-icons'

import { Tooltip } from 'core/components/Tooltip'

import { Button } from './styles'

interface MenuItemProps {
  title: string
  shortcut?: string
  isActive?: boolean
  isDisabled?: boolean
  iconName: string
  onClick: () => void
}

export function MenuItem({ isActive = false, isDisabled = false, title, shortcut, iconName, onClick }: MenuItemProps) {
  return (
    <Tooltip title={title} description={shortcut || ''}>
      <Button disabled={isDisabled} onClick={onClick}>
        {<GenericIcon name={iconName} size={16} color={isActive ? 'var(--blue-primary)' : 'var(--black)'} /> || title}
      </Button>
    </Tooltip>
  )
}
