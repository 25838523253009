import styled from 'styled-components'
import Modal from 'react-modal'

interface ContainerProps {
  tabIndex: number
}

interface ContentContainerProps {
  isVisible: boolean
}

export const Container = styled(Modal)<ContainerProps>`
  width: 47rem;
  max-width: 47rem !important;
`

export const Form = styled.form``

export const UploadContainer = styled.div<ContentContainerProps>`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
`

export const TableContainer = styled.div`
  overflow-y: scroll;
  max-height: 30rem;
  padding: 0 1rem;
  position: relative;

  > h1 {
    margin-top: 1.5rem;
    font-size: 1rem;
    text-transform: uppercase;
  }
`

export const LoadingContainer = styled.div`
  width: 95%;
  height: 100%;
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  z-index: 4;
`

export const SubHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-top: 0.5rem;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid var(--gray-20);

  > span {
    margin-top: 1.5rem;
    font-size: 0.8rem;
    color: var(--gray-60);
  }
`
export const UploadContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 30rem;
  max-height: 55rem;
  min-height: 20.25rem;
  overflow-y: auto;
  padding-bottom: 1rem;

  span {
    color: var(--gray-80);
    display: block;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.25rem;
    text-transform: uppercase;
  }

  div + span {
    margin-top: 1.5rem;
  }

  img {
    height: auto;
    margin: 1.5rem auto;
    width: 17rem;
  }

  video {
    margin: 1.5rem auto;
  }

  hr {
    background: var(--gray-20);
    height: 1px;
    margin: 1rem 0;
  }
`

export const ButtonsContainer = styled.div`
  align-items: center;
  border-top: 1px solid var(--gray-20);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  min-height: 5.25rem;
  padding: 1rem 3rem;
  width: 100%;
`
