import React, { useEffect } from 'react'

import { PersonalDataFormValues } from '../../../@types/network'
import { useNetwork } from '../../../hooks/useNetwork'

import { useForm } from 'core/hooks/useForm'
import { Input } from 'core/components/Input'
import { AutosizeInput } from 'core/components/AutosizeInput'
import { Select } from 'core/components/Select'
import { Radio } from 'core/components/Radio'

import { Form, HorizontalPanel, Label, SaveButton, ContainerAutoSizeInput } from './styles'

export const PersonalDataComponent = () => {
  const { updatePersonPersonalData, name, personPersonalData, isNewExpert, setCanShowDialogLeavingPage } = useNetwork()

  const handleSaveForm = () => {
    updatePersonPersonalData(data)
    setCanShowDialogLeavingPage(false)
  }

  const ethnicityValues = {
    white: 'Branca',
    brown: 'Parda',
    black: 'Preta',
    yellow: 'Amarela',
    other: 'Outra'
  } as { [key: string]: string }

  const genderValues = {
    female: 'Feminino',
    male: 'Masculino',
    nonBinary: 'Não-binário',
    other: 'Outro',
    preferNotToAnswer: 'Prefiro não responder'
  } as { [key: string]: string }

  const { data, errors, handleChange, handleSubmit, setValue, setFullData } = useForm<PersonalDataFormValues>({
    initialValues: {
      name: name || personPersonalData?.name,
      miniBio: personPersonalData?.miniBio || '',
      birthday: personPersonalData?.birthday || '',
      gender: personPersonalData?.gender,
      ethnicity: personPersonalData?.ethnicity,
      hasDisability: personPersonalData?.hasDisability || 'no',
      profilePicture: personPersonalData?.profilePicture || ''
    },
    onSubmit: handleSaveForm,
    validations: {
      name: { required: { value: true, message: 'Preencha o campo obrigatório.' } },
      miniBio: {
        custom: {
          isValid: (value: string) => value !== undefined && value.length < 2600,
          message: 'A mini-bio deve ter até 2600 caracteres.'
        }
      },
      gender: {
        custom: {
          isValid: (value: string) => value !== 'default',
          message: 'Preencha o campo obrigatório.'
        }
      },
      ethnicity: {
        custom: {
          isValid: (value: string) => value !== 'default',
          message: 'Preencha o campo obrigatório.'
        }
      }
    }
  })

  useEffect(() => {
    if (personPersonalData && data.name !== personPersonalData.name) {
      setFullData({
        name: personPersonalData.name,
        miniBio: personPersonalData.miniBio,
        birthday: personPersonalData.birthday,
        gender: personPersonalData.gender,
        ethnicity: personPersonalData.ethnicity,
        hasDisability: personPersonalData.hasDisability || 'no',
        profilePicture: personPersonalData.profilePicture || ''
      })
    }

    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personPersonalData])

  useEffect(() => {
    if (isNewExpert && personPersonalData.name === undefined) {
      setFullData({
        name: name,
        miniBio: '',
        birthday: '',
        gender: 'default',
        ethnicity: 'default',
        hasDisability: 'no',
        profilePicture: ''
      })
    }

    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewExpert])

  const handleFormChange = (inputName: any) => (e: any) => {
    setValue(inputName, e.target.value)
    if (e.target.value !== '') setCanShowDialogLeavingPage(true)
    else {
      setCanShowDialogLeavingPage(false)
      handleChange(inputName)
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Input
          name="name"
          type="text"
          label="Nome e sobrenome *"
          placeholder="Digite o nome"
          onChange={handleFormChange('name')}
          value={data.name}
          errors={errors.name}
          containerStyle={{ width: '35rem' }}
        />

        <HorizontalPanel>
          <Select
            name="gender"
            label="Identidade de gênero *"
            items={Object.entries(genderValues).map(([key, value]) => {
              return {
                value: key,
                name: value
              }
            })}
            placeholder="Escolha uma das opções"
            selectedValue={data.gender || 'other'}
            onChange={handleFormChange('gender')}
            selectStyle={{ height: '3rem', width: '14rem' }}
            errors={errors.gender}
          />
          <Select
            name="ethnicity"
            label="Etnia *"
            items={Object.entries(ethnicityValues).map(([key, value]) => {
              return {
                value: key,
                name: value
              }
            })}
            placeholder="Escolha uma das opções"
            selectedValue={data.ethnicity}
            onChange={handleFormChange('ethnicity')}
            selectStyle={{ height: '3rem', width: '14rem' }}
            errors={errors.ethnicity}
          />
        </HorizontalPanel>
        <ContainerAutoSizeInput>
          <AutosizeInput
            name="miniBio"
            type="text"
            label="Mini-bio (opcional)"
            placeholder="Escreva uma mini-bio ou use a do linkedin ;)"
            onChange={handleFormChange('miniBio')}
            value={data.miniBio}
            errors={errors.miniBio}
            maxLength={2600}
            characterCount
          />
        </ContainerAutoSizeInput>

        <Input
          name="birthday"
          type="date"
          label="Data de nascimento"
          placeholder="dd/mm/aaaa"
          onChange={handleFormChange('birthday')}
          value={data.birthday}
          errors={errors.birthday}
          containerStyle={{ width: '14rem' }}
          inputStyle={{ paddingRight: '1rem' }}
        />

        <Label>é Pessoa com Deficiência (PcD)?</Label>
        <HorizontalPanel>
          <Radio
            name="hasDisability"
            labelText="Sim"
            value="yes"
            checked={data.hasDisability === 'yes'}
            onChange={handleFormChange('hasDisability')}
          />
          <Radio
            name="hasDisability"
            labelText="Não"
            value="no"
            checked={data.hasDisability === 'no'}
            onChange={handleFormChange('hasDisability')}
          />
        </HorizontalPanel>

        <SaveButton width={isNewExpert ? '5.5rem' : '12rem'} type="submit">
          {isNewExpert ? 'Próximo' : 'Salvar e Continuar'}
        </SaveButton>
      </Form>
    </>
  )
}
