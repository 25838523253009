import React, { useState } from 'react'
import { Media } from '@somostera/tera-database'

import { Container } from './styles'

import playIcon from '../modal/icons/playIcon.svg'

interface MediaCellProps {
  media: Media
}

export function MediaCell({ media }: MediaCellProps) {
  const [imageHasError, setImageHasError] = useState(false)

  return (
    <Container>
      <div>
        {media.type !== 'video' ? (
          imageHasError ? (
            <div />
          ) : (
            <img src={media.url} alt={media.name} onError={() => setImageHasError(true)} />
          )
        ) : media?.thumbnail ? (
          <>
            <img src={media.thumbnail} alt={media.name} onError={() => setImageHasError(true)} />

            <span>
              <img src={playIcon} alt="play" />
            </span>
          </>
        ) : (
          <>
            <video>
              <source src={media.url} type="video/mp4" />
            </video>

            <span>
              <img src={playIcon} alt="play" />
            </span>
          </>
        )}
      </div>

      <section>
        <h3>{media.name}</h3>
        <span>{media.fileName}</span>
      </section>
    </Container>
  )
}
