import React from 'react'
import Modal from 'react-modal'
import {
  CheckCircleFilled,
  ExclamationMarkCircleFilledBlueToPurpleGradient,
  ExclamationMarkTriangleFilled,
  X,
  ExclamationMarkCircle
} from '@somostera/tera-icons'

import { Button } from '../Button'

import { Container } from './styles'

interface NoUpdateModalProps {
  isOpen: boolean
  messageType?: string
  messageTitle: string
  messageDescription?: string
  cancelButtonText?: string
  closeButtonColor?: string
  onRequestClose?: () => void
  onCancel?: () => void
}

export function NoUpdateModal({
  isOpen,
  messageTitle,
  messageType,
  messageDescription,
  cancelButtonText,
  closeButtonColor = 'var(--gray-80)',
  onRequestClose,
  onCancel
}: NoUpdateModalProps) {
  const MessageIcon = () => {
    switch (messageType) {
      case 'Confirmation':
        return <CheckCircleFilled size={72} />
      case 'BlueConfirmation':
        return <CheckCircleFilled size={72} color="var(--blue-primary)" />
      case 'Warning':
        return <ExclamationMarkCircleFilledBlueToPurpleGradient size={72} />
      case 'RedWarning':
        return <ExclamationMarkTriangleFilled size={72} color="var(--red)" />
      case 'BlackWarning':
        return <ExclamationMarkCircle size={45} color="var(--Black)" />
      default:
        return <ExclamationMarkCircleFilledBlueToPurpleGradient size={70} />
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose || onCancel}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        {onRequestClose && (
          <button type="button" onClick={onRequestClose} className="react-modal-close">
            <X color={closeButtonColor} size={20} />
          </button>
        )}

        <MessageIcon />

        <h2>{messageTitle}</h2>
        {messageDescription && <p>{messageDescription}</p>}

        <div>
          {onCancel && (
            <Button type="button" onClick={onCancel}>
              {cancelButtonText}
            </Button>
          )}
        </div>
      </Container>
    </Modal>
  )
}
