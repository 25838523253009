import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const SidebarLink = styled(Link)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  padding: 1.25rem;

  &:first-of-type {
    div {
      cursor: pointer;
      span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 24px;

        letter-spacing: 0.01em;
        text-transform: uppercase;

        color: #ffffff;
      }
    }
  }

  > div {
    align-items: center;
    display: flex;
    cursor: default;

    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;

      letter-spacing: 0.01em;
      text-transform: uppercase;

      color: #a1a1a1;
    }
  }

  &:first-child {
    padding: 1.25rem 1.375rem;

    div {
      gap: 0.875rem;
    }
  }
`

interface SidebarLabelProps {
  isSelected: boolean
}

export const SidebarLabel = styled.span<SidebarLabelProps>`
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  text-transform: uppercase;
  color: #fff;

  font-family: 'Inter';
`

interface SubItemLabelProps {
  isSelected: boolean
}

export const SubItemLabel = styled.span<SubItemLabelProps>`
  background: ${({ isSelected }) => (isSelected ? '#8FFE81' : 'none')};
  color: ${({ isSelected }) => (isSelected ? '#000' : '#fff')};
  width: 100%;
  padding: 1.25rem;

  display: flex;
  align-items: center;
  gap: 0.875rem;

  > span {
    font-weight: 800;
    color: ${({ isSelected }) => (isSelected ? '#000' : '#fff')};

    font-family: 'Inter';
    text-transform: uppercase;
    letter-spacing: 0.01;
    font-size: 16px;
  }
`
interface DropdownLinkProps {
  isSelected: boolean
}
export const DropdownLink = styled(Link)<DropdownLinkProps>`
  align-items: center;
  display: flex;
  text-decoration: none;

  &:hover {
    background: #1c1c1c;
    cursor: pointer;
  }
`
