import React, { useEffect } from 'react'

import { useNetwork } from '../../../hooks/useNetwork'
import { ProfessionalDataFormValues } from 'modules/network/@types/network'
import { Segments } from '@somostera/tera-database'

import { useForm } from 'core/hooks/useForm'
import { Input } from 'core/components/Input'
import { Select } from 'core/components/Select'
import { Radio } from 'core/components/Radio'

import { Form, HorizontalPanel, Label, SaveButton } from './styles'

export const ProfessionalDataComponent = () => {
  const { personProfessionalData, updatePersonProfessionalData, isNewExpert, setCanShowDialogLeavingPage } =
    useNetwork()

  const handleSaveForm = () => {
    updatePersonProfessionalData(data)
    setCanShowDialogLeavingPage(false)
  }

  const { data, errors, handleChange, handleSubmit, setValue, setFullData } = useForm<ProfessionalDataFormValues>({
    initialValues: {
      linkedin: personProfessionalData?.linkedin || '',
      jobTitle: personProfessionalData?.jobTitle || '',
      company: personProfessionalData?.company || '',
      segmentOfActivity: personProfessionalData?.segmentOfActivity || 'default',
      hadExperienceAsMentorOrTeacher: personProfessionalData?.hadExperienceAsMentorOrTeacher || 'no'
    },
    onSubmit: handleSaveForm,
    validations: {
      linkedin: { required: { value: true, message: 'Preencha o campo obrigatório.' } },
      jobTitle: { required: { value: true, message: 'Preencha o campo obrigatório.' } },
      company: { required: { value: true, message: 'Preencha o campo obrigatório.' } },
      segmentOfActivity: {
        custom: {
          isValid: (value: string) => value !== 'default',
          message: 'Preencha o campo obrigatório.'
        }
      }
    }
  })

  useEffect(() => {
    if (personProfessionalData && data.linkedin !== personProfessionalData.linkedin) {
      setFullData({
        linkedin: personProfessionalData.linkedin,
        jobTitle: personProfessionalData.jobTitle,
        company: personProfessionalData.company,
        segmentOfActivity: personProfessionalData?.segmentOfActivity || 'default',
        hadExperienceAsMentorOrTeacher: personProfessionalData.hadExperienceAsMentorOrTeacher || 'no'
      })
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personProfessionalData])

  useEffect(() => {
    if (isNewExpert && personProfessionalData.linkedin === undefined) {
      setFullData({
        linkedin: '',
        jobTitle: '',
        company: '',
        segmentOfActivity: 'default',
        hadExperienceAsMentorOrTeacher: 'no'
      })
    }

    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewExpert])

  const handleFormChange = (inputName: any) => (e: any) => {
    setValue(inputName, e.target.value)
    if (e.target.value !== '') setCanShowDialogLeavingPage(true)
    else {
      setCanShowDialogLeavingPage(false)
      handleChange(inputName)
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Input
          name="linkedin"
          type="text"
          label="LinkedIn*"
          placeholder="Preencha com o perfil do LinkedIn"
          onChange={handleFormChange('linkedin')}
          value={data.linkedin}
          errors={errors.linkedin}
          containerStyle={{ width: '35rem' }}
        />

        <HorizontalPanel>
          <Input
            name="jobTitle"
            type="text"
            label="Cargo*"
            placeholder="Digite o cargo"
            onChange={handleFormChange('jobTitle')}
            value={data.jobTitle}
            errors={errors.jobTitle}
            containerStyle={{ width: '17rem' }}
          />
          <Input
            name="company"
            type="text"
            label="Empresa*"
            placeholder="Digite a empresa"
            onChange={handleFormChange('company')}
            value={data.company}
            errors={errors.company}
            containerStyle={{ width: '17rem' }}
          />
        </HorizontalPanel>

        <Select
          name="segmentOfActivity"
          label="Segmento *"
          items={Object.entries(Segments).map(([value, name]) => ({ value, name }))}
          placeholder="Escolha uma das opções"
          selectedValue={data.segmentOfActivity}
          onChange={handleFormChange('segmentOfActivity')}
          selectStyle={{ height: '3rem', width: '17rem' }}
          errors={errors.segmentOfActivity}
        />

        <Label>já teve experiência antes como mentor ou professor?</Label>
        <HorizontalPanel>
          <Radio
            name="hadExperienceAsMentorOrTeacher"
            labelText="Sim"
            value="yes"
            checked={data.hadExperienceAsMentorOrTeacher === 'yes'}
            onChange={handleFormChange('hadExperienceAsMentorOrTeacher')}
          />
          <Radio
            name="hadExperienceAsMentorOrTeacher"
            labelText="Não"
            value="no"
            checked={data.hadExperienceAsMentorOrTeacher === 'no'}
            onChange={handleFormChange('hadExperienceAsMentorOrTeacher')}
          />
        </HorizontalPanel>

        <SaveButton width={isNewExpert ? '5.5rem' : '12rem'} type="submit">
          {isNewExpert ? 'Próximo' : 'Salvar e Continuar'}
        </SaveButton>
      </Form>
    </>
  )
}
