import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Modal from 'react-modal'

import { AppProvider } from 'core/hooks'
import MainRoutes from 'core/routes'

import { GlobalStyle } from 'core/styles/global'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

Modal.setAppElement('#root')

export const App = () => (
  <DndProvider backend={HTML5Backend}>
    <BrowserRouter>
      <AppProvider>
        <MainRoutes />
      </AppProvider>
      <GlobalStyle />
    </BrowserRouter>
  </DndProvider>
)
