import { InitializeCourseService, Course, BlockType } from '@somostera/tera-database'
import { db } from 'core/config/firebase'

const courseService = InitializeCourseService.initialize(db)

export const getAllCourses = async (): Promise<Course[]> => {
  const allCourses = await courseService.findAll()
  return allCourses
}

export const getBlockStructureByAcronymCourseAndBlockType = async (
  acronymCourse: string,
  blockType: BlockType[]
): Promise<any[]> => {
  const getBlockByCourse = await courseService.findBlockStructureByAcronymCourseAndBlockType(acronymCourse, blockType)
  return getBlockByCourse
}
