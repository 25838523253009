/* eslint-disable prettier/prettier */
import React, { createContext, ReactNode, useContext, useEffect } from 'react'
import { useImmerReducer } from 'use-immer'

import { Media } from '@somostera/tera-database'

import { findAllMedias } from '../services/media'

import { mediaReducer, State } from '../reducers/media'

interface MediaContextData {
  isLoading: boolean
  medias: Media[]
  getAllMedias: () => Promise<void>
}

interface MediaProviderProps {
  children: ReactNode
}

const MediaContext = createContext<MediaContextData>({} as MediaContextData)

export const MediaProvider = ({ children }: MediaProviderProps) => {
  const [{ isLoading, medias }, setState] = useImmerReducer(mediaReducer, {
    isLoading: true,
    medias: [] as Media[]
  } as State)

  async function getAllMedias() {
    setState({ type: 'ASYNC_CALL' })
    try {
      const allMedias = await findAllMedias()

      setState({ type: 'SET_MEDIAS', payload: { medias: allMedias } })
      setState({ type: 'ASYNC_CALL_SUCCEED' })
    } catch (error) {
      setState({ type: 'ASYNC_CALL_FAILED', payload: { error } })
      console.log(error)
    }
  }

  useEffect(() => {
    getAllMedias()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MediaContext.Provider
      value={{
        isLoading,
        medias,
        getAllMedias
      }}
    >
      {children}
    </MediaContext.Provider>
  )
}

export const useMedia = (): MediaContextData => {
  return useContext(MediaContext)
}
