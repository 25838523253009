import { Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { PromptBoxComponent } from './PromptBoxComponent'

export interface PromptBoxOptions {
  prompt: string
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    addPromptBox: {
      addPromptBox: (options: PromptBoxOptions) => ReturnType
    }
  }
}

export const PromptBoxModule = Node.create<PromptBoxOptions>({
  name: 'custom-prompt-box',
  group: 'block',
  atom: true,
  addOptions() {
    return {
      prompt: ''
    }
  },
  draggable: true,
  addAttributes() {
    return {
      prompt: {
        default: null
      }
    }
  },
  parseHTML() {
    return [
      {
        tag: 'div'
      }
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['div', HTMLAttributes]
  },
  addCommands() {
    return {
      addPromptBox:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options
          })
        }
    }
  },
  addNodeView() {
    return ReactNodeViewRenderer(PromptBoxComponent)
  }
})
