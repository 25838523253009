import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { AutosizeInput } from 'modules/contents/components/datasheet/AutosizeInput'
import { Input } from 'modules/contents/components/datasheet/Input'
import { MultiSelect } from 'modules/contents/components/datasheet/MultiSelect'
import { InputSelect } from 'modules/contents/components/datasheet/Select'
import { CareersType } from '@somostera/tera-database'
import { CoursesService } from 'modules/courses/services/courses'
import { useAuth } from 'core/hooks/useAuth'
import ToggleSwitch from 'modules/contents/components/datasheet/ToggleSwitch'
import { Container, FormContainer, ToggleSwitchContainer, Footer } from './styles'
import { TagsInput } from '../../../../../../contents/components/datasheet/TagsInput'
import { FileInput } from '../../../../../../../core/components/FileInput'
import { MediaType } from '@somostera/tera-models-ts'
import { UploadContainer } from '../../../../../../buildingblock/components/AddMediaModalBuildingBlock/styles'
interface DatasheetEditProps {
  handleCancelEdit: () => void
  getCourse: () => void
  course: any
}
interface Option {
  value: string
  label: string
}
const areasOptions = [
  { value: 'DESIGN_INNOVATION', label: 'Design e Inovação' },
  { value: 'DATA_AI', label: 'Dados e IA' },
  { value: 'LEADERSHIP_BUSINESS', label: 'Liderança e Negócios' },
  { value: 'MARKETING', label: 'Marketing' },
  { value: 'PRODUCT_MANAGEMENT', label: 'Gestão de Produtos' },
  { value: 'PROGRAMMING', label: 'Programação' }
]

const categoriesOptions = [
  { value: 'BUSINESS', label: 'Negócios' },
  { value: 'DATA', label: 'Dados' },
  { value: 'TECHNOLOGY', label: 'Tecnologia' },
  { value: 'DESIGN', label: 'Design' },
  { value: 'LEADERSHIP', label: 'Liderança' },
  { value: 'MARKETING', label: 'Marketing' },
  { value: 'PRODUCT', label: 'Produto' }
]

const availableForSchoolOptions = [
  { value: 'PRODUCT', label: 'Plataforma' },
  { value: 'SOLAR', label: 'Solar' },
  { value: 'MBA', label: 'MBA' },
  { value: 'ACADEMY', label: 'Academy' }
]

const levelOptions = [
  { value: 'BEGINNER', label: 'Iniciante' },
  { value: 'INTERMEDIATE', label: 'Intermediário' },
  { value: 'ADVANCED', label: 'Avançado' }
]

const careersOptions = Object.entries(CareersType).map(([value, label]) => {
  return {
    value,
    label
  }
})

const statusOptions = [
  { value: 'published', label: 'Publicado' },
  { value: 'draft', label: 'Rascunho' },
  { value: 'deleted', label: 'Excluído' },
  { value: 'archived', label: 'Arquivado' }
]

export function DatasheetEdit({ handleCancelEdit, getCourse, course }: DatasheetEditProps) {
  const { courseId } = useParams()
  const { userFirebase } = useAuth()

  const [title, setTitle] = useState<string | undefined>(course.name)
  const [description, setDescription] = useState(course.description)
  const [carriers, setCarriers] = useState<Option[]>([])
  const [schools, setSchools] = useState<Option[]>([])
  const [levels, setLevels] = useState<Option[]>([])
  const [areas, setAreas] = useState<Option[]>([])
  const [tools, setTools] = useState<string[]>([])
  const [outcomes, setOutcomes] = useState<string[]>([])
  const [categories, setCategories] = useState<Option[]>([])
  const [isVisibilityPublic, setIsVisibilityPublic] = useState(false)
  const [status, setStatus] = useState<Option>(course.status)
  const [fileUrl, setFileUrl] = useState('')
  const [fileSize, setFileSize] = useState(0)
  const [uploadedFile, setUploadedFile] = useState<File>()

  useEffect(() => {
    setIsVisibilityPublic(course.visibility === 'public')
  }, [course.visibility])

  useEffect(() => {
    const currentStatus = statusOptions.find((option) => option.value === course.status) || {
      value: 'draft',
      label: 'Rascunho'
    }
    setStatus(currentStatus)
  }, [course.status])

  useEffect(() => {
    const currentCarrer: any = []
    careersOptions.forEach((option) => {
      course.career?.forEach((career: string) => {
        if (career === option.label) currentCarrer.push(option)
      })
    })
    setCarriers(currentCarrer)
  }, [course.career])

  useEffect(() => {
    const currentSchool: any = []
    availableForSchoolOptions.forEach((option) => {
      course.availableForSchool?.forEach((school: string) => {
        if (school === option.value) currentSchool.push(option)
      })
    })
    setSchools(currentSchool)
  }, [course.availableForSchool])

  useEffect(() => {
    const currentLevels: any = []
    levelOptions.forEach((option) => {
      course.levels?.forEach((level: string) => {
        if (level === option.value) currentLevels.push(option)
      })
    })
    setLevels(currentLevels)
  }, [course.levels])

  useEffect(() => {
    setTools(course?.tools || [])
  }, [course.tools])

  useEffect(() => {
    setOutcomes(course?.outcomes || [])
  }, [course.outcomes])

  useEffect(() => {
    setFileUrl(course?.coverImage || '')
  }, [course?.coverImage])

  useEffect(() => {
    const currentAreas: any = []
    areasOptions.forEach((option) => {
      course.areas?.forEach((area: string) => {
        if (area === option.value) currentAreas.push(option)
      })
    })
    setAreas(currentAreas)
  }, [course.areas])

  useEffect(() => {
    const currentCategories: any = []
    categoriesOptions.forEach((option) => {
      course.categories?.forEach((category: string) => {
        if (category === option.value) currentCategories.push(option)
      })
    })
    setCategories(currentCategories)
  }, [course.categories])

  const visibilityLabel = isVisibilityPublic ? 'Público' : 'Privado'

  const handleCheckVisibility = () => setIsVisibilityPublic(!isVisibilityPublic)

  const handleSave = async () => {
    const careerToSave = carriers.map((career) => career.label)
    const areasToSave = areas.map((area) => area.value)
    const categoriesToSave = categories.map((category) => category.value)

    const data = {
      name: title,
      description,
      career: careerToSave,
      areas: areasToSave,
      categories: categoriesToSave,
      visibility: isVisibilityPublic ? 'public' : 'private',
      availableForSchool: schools.map((school) => school.value),
      levels: levels.map((level) => level.value),
      tools,
      outcomes,
      status: status.value,
      coverImage: fileUrl
    }

    const token = await userFirebase.getIdToken()
    await CoursesService.updateCourse(token, courseId as string, data)

    getCourse()
    handleCancelEdit()
  }

  return (
    <>
      <Container>
        <section>
          <FormContainer>
            <h3>Especificações</h3>

            <Input
              type="text"
              name="title"
              label="TÍTULO DO CONTEÚDO"
              placeholder="Escreva o título do conteúdo"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              required
            />

            <AutosizeInput
              type="text"
              name="description"
              label="Descrição"
              placeholder="Descrição breve do que é este conteúdo"
              minheight={'6.75rem'}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              required
            />

            <MultiSelect
              name="careers"
              options={careersOptions}
              onChange={setCarriers}
              value={carriers}
              label="Carreiras"
              required
            />

            <MultiSelect
              name="schools"
              options={availableForSchoolOptions}
              onChange={setSchools}
              value={schools}
              label="Escolas"
              required
            />

            <MultiSelect name="levels" options={levelOptions} onChange={setLevels} value={levels} label="Níveis" />

            <MultiSelect name="areas" options={areasOptions} onChange={setAreas} value={areas} label="Áreas" required />

            <MultiSelect
              name="categories"
              options={categoriesOptions}
              onChange={setCategories}
              value={categories}
              label="Categorias"
              required
            />

            <TagsInput
              name="tools"
              label="Ferramentas"
              placeholder="Ferramentas relacionadas ao conteúdo (chatGPT, Zapier, etc)"
              value={tools}
              onTagsChange={(tags: string[]) => setTools([...tags])}
              type="tag"
              required
            />

            <TagsInput
              name="outcomes"
              label="Objetivos de aprendizagem"
              placeholder="Objetivos de aprendizagem do curso"
              value={outcomes}
              onTagsChange={(tags: string[]) => setOutcomes([...tags])}
              type="tag"
              required
            />

            <ToggleSwitchContainer>
              <h3>Visibilidade</h3>
              <div>
                <ToggleSwitch onClick={handleCheckVisibility} isChecked={isVisibilityPublic} />
                <span>{visibilityLabel}</span>
              </div>
            </ToggleSwitchContainer>

            <InputSelect
              type="default"
              label="Status"
              options={statusOptions}
              value={status}
              onChange={(event) => {
                setStatus(event)
              }}
            ></InputSelect>

            <h3 style={{ marginTop: '2rem', marginBottom: 0 }}>Imagem de Capa</h3>
            <UploadContainer>
              <FileInput
                name="fileUrl"
                fileType={MediaType.IMAGE}
                acceptedFilesRegex={['image/jpeg', 'image/bmp', 'image/x-ms-bmp', 'image/png'].join(',')}
                onFileUrlChange={(fileUrl: string) => setFileUrl(fileUrl)}
                onFileSizeChange={(size: number) => setFileSize(size)}
                onFileChange={(file: File) => setUploadedFile(file)}
              />
            </UploadContainer>
          </FormContainer>
        </section>
      </Container>
      <Footer>
        <button onClick={handleCancelEdit}>Cancelar</button>
        <button onClick={handleSave}>Salvar</button>
      </Footer>
    </>
  )
}
