import styled from 'styled-components'

export const Button = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  height: 2rem;
  justify-content: center;
  width: 2rem;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`
