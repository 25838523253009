import styled from 'styled-components'

export const Container = styled.div`
  margin: 2rem auto;
  width: 90%;
  position: relative;

  a {
    color: var(--gray-80);
  }

  .rdt_Table {
    background-color: #fcfcfc;

    > div {
      background-color: #fcfcfc;
    }
  }
`
export const LoadingContainer = styled.div`
  width: 100%;
  height: 90%;
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  z-index: 4;
`

export const ContentsHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  background-color: #fcfcfc;

  header {
    background-color: #fcfcfc;
  }

  h1 {
    text-transform: uppercase;
  }
`

export const RemoveButton = styled.button`
  align-items: center;
  background: transparent;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  transition: opacity 0.2s;

  &:active {
    background: var(--gray-20);
  }

  &:hover {
    opacity: 0.7;
  }
`

export const AddNewContentButton = styled.button`
  align-items: center;
  background: var(--blue-primary);
  border-radius: 0.25rem;
  color: var(--white);
  display: flex;
  font-size: 0.875rem;
  font-weight: bold;
  height: 2.25rem;
  justify-content: space-between;
  line-height: 120%;
  margin: 0 8px;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  width: 9rem;

  svg {
    margin-right: 0.5rem;
  }
`

export const MenuTypeContainer = styled.button`
  z-index: 8;
  position: absolute;
  right: 0;
`
