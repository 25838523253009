import React from 'react'
import { Link } from 'react-router-dom'

import { OptionMenu } from './styles'

interface MenuOptions {
  name: string
  link: string
}
interface MenuTypeProps {
  title: string
  options: MenuOptions[]
}

export function OptionMenuButton({ title, options }: MenuTypeProps) {
  return (
    <OptionMenu>
      <div className="title">
        <h2>{title}</h2>
      </div>

      <div className="options">
        {options.map((option) => (
          <p key={option.name}>
            <Link to={option.link}>{option.name}</Link>
          </p>
        ))}
      </div>
    </OptionMenu>
  )
}
