import React, { useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import type { DropResult } from 'react-beautiful-dnd'
import { Chapter, ContentType } from '@somostera/tera-database'

import useEventListener from 'core/hooks/useEventListener'

import { Button } from 'core/components/Button'
import { Grip, PenEditSquare, FloppyDisk, Trash, PlusCircle } from '@somostera/tera-icons'

import { AddChapterSubMenu } from 'modules/contents/components/AddChapterSubMenu'

import {
  Container,
  MenuContent,
  AddChapterButtonContainer,
  ChapterContainer,
  Title,
  GripContainer,
  ButtonsContainer,
  SaveButtonContainer,
  AddChapterSubMenuContainer
} from './styles'
import Copy from '../../../../core/components/Icon/Copy'
import { ArticleType } from '@somostera/tera-database/dist/Articles/Domain/enum/ArticleType'

export interface MenuOption {
  label: string
  description: string
  value: string
  action: () => boolean
}

export interface GroupOption {
  label: string
  description?: string
  options: MenuOption[]
}

export interface ChapterMenuProps {
  label: string
  chapters: Chapter[]
  options?: MenuOption[]
  width?: number | string
  currentChapterIndex: number
  articleType: ArticleType
  onEditChapterTitle: (index: number, newTitle: string) => void
  onSelectChapter: (index: number, contentType: ContentType) => void
  onAddChapter: (templateName: ContentType, chapter?: Chapter) => void
  onRemoveChapter: (index: number, quizId?: string) => void
  onChangeChaptersPositions: (result: DropResult) => void
}

export function ChaptersMenu({
  label,
  chapters = [],
  currentChapterIndex,
  articleType,
  onEditChapterTitle,
  onSelectChapter,
  onAddChapter,
  onRemoveChapter,
  onChangeChaptersPositions
}: ChapterMenuProps) {
  const subMenuRef = useRef<HTMLDivElement>(null)

  const [showAddChapterSubMenu, setShowAddChapterSubMenu] = useState(false)
  const [chapterTitle, setChapterTitle] = useState('')

  const [chapterIsEditing, setChapterIsEditing] = useState({
    index: 0,
    isEditing: false
  })

  const handleCloseMenu = (event: Event) => {
    if (subMenuRef.current && !subMenuRef.current.contains(event.target as Node)) {
      setShowAddChapterSubMenu(false)
    }
  }

  const handleEscapeKeyDown = (event: Event) => {
    if ((event as KeyboardEvent).key === 'Escape') {
      setShowAddChapterSubMenu(false)
    }
  }

  const handleAddNewChapter = () => {
    setShowAddChapterSubMenu(!showAddChapterSubMenu)
  }

  useEventListener('mousedown', handleCloseMenu)
  useEventListener('keydown', handleEscapeKeyDown)

  const handleSaveNewChapterTitle = (
    event: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    if (event.type === 'keydown' && (event.nativeEvent as KeyboardEvent).key === 'Enter') {
      setChapterIsEditing({ index, isEditing: false })
      onEditChapterTitle(index, chapterTitle)
      setChapterTitle('')
    }

    if (event.type === 'click') {
      setChapterIsEditing({ index, isEditing: false })
      onEditChapterTitle(index, chapterTitle)
      setChapterTitle('')
    }
  }

  const handleEditChapterTitle = (index: number) => {
    setChapterIsEditing({
      index,
      isEditing: true
    })
  }

  const handleRemoveChapter = (chapterIndex: number, quizId?: string) => {
    onRemoveChapter(chapterIndex, quizId)
    setShowAddChapterSubMenu(false)
  }

  const handleCopyChapter = (chapter: Chapter) => {
    onAddChapter(chapter.contentType, chapter)
  }

  return (
    <Container>
      <MenuContent>
        <h5>{label}</h5>

        <DragDropContext onDragEnd={onChangeChaptersPositions}>
          <Droppable droppableId="chapters">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {chapters.map((chapter) => (
                  <Draggable key={chapter.index} draggableId={`id-${chapter.index}`} index={chapter.index}>
                    {(provided) => (
                      <ChapterContainer
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={provided.draggableProps.style || {}}
                      >
                        <GripContainer>
                          <Grip width={18} height={18} fill="var(--gray-20)" color="var(--blue-primary)" />
                        </GripContainer>

                        {chapterIsEditing.index !== chapter.index || !chapterIsEditing.isEditing ? (
                          <>
                            <Title
                              isCurrentChapter={currentChapterIndex === chapter.index}
                              onClick={() => onSelectChapter(chapter.index, chapter.contentType)}
                            >
                              {chapter.title}
                            </Title>

                            <ButtonsContainer>
                              <button onClick={() => handleEditChapterTitle(chapter.index)}>
                                <PenEditSquare width={16} height={20} color="var(--blue-primary)" />
                              </button>
                              <button onClick={() => handleCopyChapter(chapter)}>
                                <Copy width={20} height={20} color="var(--blue-primary)" />
                              </button>
                              <button onClick={() => handleRemoveChapter(chapter.index, chapter.quizId)}>
                                <Trash width={20} height={20} color="var(--blue-primary)" />
                              </button>
                            </ButtonsContainer>
                          </>
                        ) : (
                          <>
                            <input
                              type="text"
                              value={chapterTitle}
                              onChange={(event) => setChapterTitle(event.target.value)}
                              placeholder="Digite o nome do capítulo"
                              onKeyDown={(event) => handleSaveNewChapterTitle(event, chapter.index)}
                            />
                            <SaveButtonContainer>
                              <button onClick={(event) => handleSaveNewChapterTitle(event, chapter.index)}>
                                <FloppyDisk width={16} height={16} color="var(--blue-primary)" />
                              </button>
                            </SaveButtonContainer>
                          </>
                        )}
                      </ChapterContainer>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <hr />

        <AddChapterButtonContainer>
          <Button
            type="button"
            buttonStyle={{
              backgroundColor: 'transparent',
              color: 'var(--gray-100)',
              fontSize: '0.75rem',
              fontWeight: 'normal',
              textDecoration: 'underline',
              height: '2rem',
              justifyContent: 'space-between'
            }}
            onClick={() => handleAddNewChapter()}
          >
            <PlusCircle height="1.2rem" color="var(--blue-primary)" />
            <span style={{ marginLeft: '0.3rem', color: 'var(--gray-100)' }}>Adicionar capítulo</span>
          </Button>
          {showAddChapterSubMenu && (
            <AddChapterSubMenuContainer ref={subMenuRef}>
              <AddChapterSubMenu
                setShowAddChapterSubMenu={setShowAddChapterSubMenu}
                onAddChapter={(templateName) => onAddChapter(templateName)}
                articleType={articleType}
              />
            </AddChapterSubMenuContainer>
          )}
        </AddChapterButtonContainer>
      </MenuContent>
    </Container>
  )
}
