import { addDoc, collection, deleteDoc, doc, setDoc } from 'firebase/firestore'
import { GenericConverter, Media } from '@somostera/tera-models-ts'

import { db } from 'core/config/firebase'

const MEDIAS_COLLECTION_NAME = 'medias'

const mediasCollection = collection(db, MEDIAS_COLLECTION_NAME).withConverter(new GenericConverter<Media>())

export const addMedia = async (media: Media): Promise<string> => {
  const { id } = await addDoc(mediasCollection, media)
  return id
}

export const removeMedia = async (mediaId: string) => {
  const mediaRef = doc(db, MEDIAS_COLLECTION_NAME, mediaId).withConverter(new GenericConverter<Media>())
  await deleteDoc(mediaRef)
}

export const updateMedia = async (mediaId: string, media: Media) => {
  const mediaRef = doc(db, MEDIAS_COLLECTION_NAME, mediaId).withConverter(new GenericConverter<Media>())
  await setDoc(mediaRef, { ...media, updatedAt: new Date() })
}
